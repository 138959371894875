import React, { useState, useEffect } from 'react';
import TetherComponent from 'react-tether';
import ResourcesMenu from './ResourcesMenuContainer';
import OutsideClickHandler from "../common/OutsideClickHandler";


const SingleResource = (props) => {
  const {
    number,
    resourceMenuIsOpened,
    setResourceMenuIsOpen,
    setSelectedResource,
    calendar,
  } = props;

  const [isMenuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (!resourceMenuIsOpened) {
      setMenuOpen(false);
    }
  }, [resourceMenuIsOpened]);

  useEffect(() => {
    if (isMenuOpen) {
      setResourceMenuIsOpen();
    }
  }, [isMenuOpen]);

  const toggleMenu = (selectedResource) => {
    setSelectedResource(selectedResource);
    setMenuOpen(!isMenuOpen);
  };

  const menuClassName = isMenuOpen ? 'slot-menu menu open' : 'slot-menu menu';

  return (
    <>
      <TetherComponent
        attachment="top left"
        targetAttachment="middle right"
        constraints={ [
          {
            to: 'scrollParent',
            attachment: 'together',
            pin: true
          },
        ] }
        renderTarget={ ref =>
          <div ref={ ref } className="schedule-index" onClick={ () => toggleMenu(calendar[number]) }>
            { 
              calendar[number]?.pseudoName || 
              // calendar[number].resourceName  
              number + 1
            }
          </div>
        }

        renderElement={ ref => (
          isMenuOpen && (
            <div ref={ ref } className={ menuClassName }>
              <OutsideClickHandler handleClickOutside={ () => setMenuOpen(!isMenuOpen) } closeOnScroll={true}>
                <ResourcesMenu />
              </OutsideClickHandler>
            </div>
          )
        ) }
      />
    </>
  );
};

export default SingleResource;
