import { connect } from 'react-redux';
import { 
  getFilteredUsers
} from "../../redux/users/actions";
import {
  changeToRefreshed
} from '../../redux/reservations/actions';
import { 
  togglePushMessagesModal,
  toggleFilterSettingsModal,
} from "../../redux/pushMessages/actions";
import {
  getDataForDonorDetails,
  toggleDonorDetailsModal,
  getUserByPnr,
  setLastChoosenDonor,
} from '../../redux/users/actions';
import { sendErrorLog } from '../../redux/logging/actions';
import DonorsList from './DonorsList';


const mapStateToProps = (state) => ({
  currentBloodbank: state.authReducer.currentBloodbank,
  users: state.usersReducer.users,
  needRefresh: state.reservationReducer.needRefresh,
  selectedFilters: state.pushMessagesReducer.selectedFilters,
  isUserPending: state.usersReducer.isPending,
  userByPnr: state.usersReducer.userByPnr,
  lastChoosenDonor: state.usersReducer.lastChoosenDonor,
});

const mapDispatchToProps = {
  getFilteredUsers,
  changeToRefreshed,
  togglePushMessagesModal,
  toggleFilterSettingsModal,
  getDataForDonorDetails,
  toggleDonorDetailsModal,
  getUserByPnr,
  sendErrorLog,
  setLastChoosenDonor,
};

export default connect(mapStateToProps, mapDispatchToProps)(DonorsList);