export const DONORS_HEADINGS = {
  NAME: 'name',
  ID_NUMBER: 'ID_number',
  LAST_DRAW: 'last_draw',
  BLOOD_TYPE: 'blood_type',
  TITER: 'titer',
  DONOR_STATUS: 'donor_status',
  DONOR_TYPE: 'donor_type',
  QUARANTINE_DUE_DATE: 'quarantine_due_date',
  APP_USER: 'app_user',
  SMS: 'sms',
  NEXT_APPOINTMENT: 'next_appointment',
};

export const DONORS_SETUP = [
  {
    title: DONORS_HEADINGS.ID_NUMBER,
    isSortable: true,
    isHighlighted: true,
    isTextOwerflowHidden: false,
  },
  {
    title: DONORS_HEADINGS.LAST_DRAW,
    isSortable: true,
    isHighlighted: false,
    isTextOwerflowHidden: false,
  },
  {
    title: DONORS_HEADINGS.BLOOD_TYPE,
    isSortable: true,
    isHighlighted: false,
    isTextOwerflowHidden: false,
  },
  {
    title: DONORS_HEADINGS.TITER,
    isSortable: true,
    isHighlighted: false,
    isTextOwerflowHidden: false,
  },
  {
    title: DONORS_HEADINGS.DONOR_STATUS,
    isSortable: true,
    isHighlighted: false,
    isTextOwerflowHidden: false,
  },
  {
    title: DONORS_HEADINGS.DONOR_TYPE,
    isSortable: true,
    isHighlighted: false,
    isTextOwerflowHidden: false,
  },
  {
    title: DONORS_HEADINGS.QUARANTINE_DUE_DATE,
    isSortable: true,
    isHighlighted: false,
    isTextOwerflowHidden: false,
  },
  {
    title: DONORS_HEADINGS.APP_USER,
    isSortable: true,
    isHighlighted: false,
    isTextOwerflowHidden: false,
  },
  {
    title: DONORS_HEADINGS.SMS,
    isSortable: true,
    isHighlighted: false,
    isTextOwerflowHidden: false,
  },
  {
    title: DONORS_HEADINGS.NEXT_APPOINTMENT,
    isSortable: true,
    isHighlighted: false,
    isTextOwerflowHidden: true,
  }
];

export const DONORS_LIST = [
  {
    [DONORS_HEADINGS.NAME]: 'Mia Skagestad',
    [DONORS_HEADINGS.ID_NUMBER]: 264758693657,
    [DONORS_HEADINGS.LAST_DRAW]: '12 January 2020',
    [DONORS_HEADINGS.BLOOD_TYPE]: '0+',
    [DONORS_HEADINGS.DONOR_STATUS]: 'New',
    [DONORS_HEADINGS.DONOR_TYPE]: 'Full blood',
    [DONORS_HEADINGS.QUARANTINE_DUE_DATE]: '25 January 2020',
    [DONORS_HEADINGS.NEXT_APPOINTMENT]: '30 January 2022'
  },
];
