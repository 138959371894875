import { createAction } from 'redux-actions';
import {
  SET_SYSTEM_MESSAGES,
  SET_ADMIN_MESSAGES,
  SET_SCHEDULED_MESSAGES,
  SET_LAST_ADMIN_MESSAGE_ID,
  CREATE_MESSAGE_SUCCESS,
  REQUEST_FAIL,
  TOGGLE_PUSH_MESSAGES_MODAL,
  TOGGLE_FILTER_SETTINGS_MODAL,
  SET_COUPLING_TABLE_DATA,
  SET_SELECTED_FILTERS,
  REFRESH_FILTERS,
} from './actionTypes';
import { 
  get,
  createPushMessage,
} from '../../services/fetch';
import { getIdToken } from "../auth/actions";

export const setSystemMessages = createAction(SET_SYSTEM_MESSAGES);
export const setAdminMessages = createAction(SET_ADMIN_MESSAGES);
export const setScheduledMessages = createAction(SET_SCHEDULED_MESSAGES);
export const setLastAdminMessageId = createAction(SET_LAST_ADMIN_MESSAGE_ID);
export const createMessageSuccess = createAction(CREATE_MESSAGE_SUCCESS);
export const requestFail = createAction(REQUEST_FAIL);
export const togglePushMessagesModal = createAction(TOGGLE_PUSH_MESSAGES_MODAL);
export const toggleFilterSettingsModal = createAction(TOGGLE_FILTER_SETTINGS_MODAL);
export const setCouplingTableData = createAction(SET_COUPLING_TABLE_DATA);
export const setSelectedFilters = createAction(SET_SELECTED_FILTERS);
export const refreshFilters = createAction(REFRESH_FILTERS);

export const getAdminMessages = (bloodbankId) => (dispatch) => {
  get(`/messages/type/admin/bloodbank/${bloodbankId}`)
  .then((res) => { 
    return dispatch(setAdminMessages(res));
    }
  )
  .catch((err) => dispatch(requestFail(err)));
}

export const getSystemMessages = (bloodbankId) => (dispatch) => {
  get(`/messages/type/system/bloodbank/${bloodbankId}`)
  .then((res) => { 
    return dispatch(setSystemMessages(res));
    }
  )
  .catch((err) => dispatch(requestFail(err)));
}

export const getScheduledMessages = (bloodbankId) => (dispatch) => {
  get(`/messages/type/scheduled/bloodbank/${bloodbankId}`)
  .then((res) => { 
    return dispatch(setScheduledMessages(res));
    }
  )
  .catch((err) => dispatch(requestFail(err)));
}

export const createAdminMessage = (data) => (dispatch) => {
  dispatch(getIdToken())
  .then((token) => createPushMessage(`/message/create`, {...data, token}))
  .then(() => {
    return dispatch(createMessageSuccess());
    }
  )
  .catch((err) => dispatch(requestFail(err)));
}

export const getLastAdminMessage = () => (dispatch) => {
  get('/messages/type/admin/last')
  .then((res) => { 
    return dispatch(setLastAdminMessageId(res));
    }
  )
  .catch((err) => dispatch(requestFail(err)));
}

export const getCouplingTableData = () => (dispatch) => {
  get('/coupling')
  .then((res) => { 
    return dispatch(setCouplingTableData(res));
    }
  )
  .catch((err) => dispatch(requestFail(err)));
}