import { handleActions } from 'redux-actions';
import { setPendingRequest, deletePendingRequest, deletePendingRequests } from './actions';

const initialState = {
    pendingRequests: {},
}

const requestsReducer = handleActions({
    [setPendingRequest]: (state, action) => ({
        pendingRequests: {
            ...state.pendingRequests,
            [action.payload.id]: action.payload.controller
        }
    }),
    [deletePendingRequest]: (state, action) => {
        if (Object.keys(state.pendingRequests).length) {
            const filteredRequests = Object.entries(state.pendingRequests).reduce((acc, el) => {
                if (el[0] !== action.payload.id) {
                    acc[el[0]] = el[1]
                    return acc;
                }
                return acc;
            }, {});

            return { pendingRequests: filteredRequests };
        }
        else {
            return { pendingRequests: {} }
        }
    },
    [deletePendingRequests]: () => ({
        pendingRequests: {}
    }),
}, initialState);

export default requestsReducer;