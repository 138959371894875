import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);
  
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);
  
    return debouncedValue;
  };

const Search = (props) => {
    const {
        id, placeholder, disabled, label, value, setValue, 
        type, shortSearch, autoComplete, isPnrSearch
    } = props;
    const [inputType] = useState(type);
    const [inputValue, setInputValue] = useState(value);
    const [showClearButton, setShowClearButton] = useState(false);
    const debouncedSearchTerm = useDebounce(inputValue, 300);

    const handleChange = (event) => {
        let inputValue = event.target.value.replace(/\s/g, '');
        if (isPnrSearch) {
            if (inputValue.length > 11) {
                inputValue = inputValue.slice(0, 11);
            }
            setInputValue(inputValue);
            setShowClearButton(inputValue !== '');
        } else {
            setInputValue(inputValue);
            setShowClearButton(inputValue !== '');
        }
    };

    const handleClear = () => {
        setInputValue('');
        setShowClearButton(false);
    };

    useEffect(() => {
        setValue(inputValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchTerm]);

    const formatText = (text) => {
        if (text.length > 6) {
            const firstPart = text.slice(0, 6);
            const secondPart = text.slice(6, 11);
            return `${firstPart}\u00A0${secondPart}`;
        }
        return text;
    };

    const handleCopy = (e) => {
        e.clipboardData.setData('text/plain', inputValue);
        e.preventDefault();
    };

    return (
        <div className="form-control">
            {label &&
            <label className="form-label" { ...(id && {htmlFor: id }) }>
                {label}
            </label>
            }
            <div className="search">
                { shortSearch ||
                    <span className="search-icon icon-search"/>
                }
                <input 
                    { ...(id && { id }) }
                    type={inputType}
                    value={ isPnrSearch ? formatText(inputValue) : inputValue }
                    name="input-form"
                    onChange={handleChange}
                    className={shortSearch ? "search-input-short" : "search-input"}
                    placeholder={placeholder}
                    disabled={disabled}
                    autoComplete={autoComplete} 
                    onCopy={handleCopy}
                />
                { showClearButton && (autoComplete === 'off') && (
                    <button className="clear-button" onClick={ handleClear }>
                        <span className="icon-close close-icon" />
                    </button>
                )}
            </div>
            <div className="form-error">Required</div>
        </div>
    );
};

Search.propTypes = {
    id: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    setValue: PropTypes.func,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    shortSearch: PropTypes.bool,
};

Search.defaultProps = {
    id: '',
    placeholder: '',
    label: '',
    value: '',
    setValue: () => {},
    type: 'search',
    disabled: false,
    shortSearch: false,
};


export default Search;

