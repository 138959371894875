import { combineReducers } from 'redux';
import reservationReducer from './reservations/reducer';
import authReducer from './auth/reducer';
import timeslotsReducer from "./timeslots/reducer";
import appointmentTypesReducer from "./appointmentTypes/reducer";
import usersReducer from "./users/reducer";
import pushMessagesReducer from "./pushMessages/reducer";
import loggingReducer from "./logging/reducer";
import requestsReducer from './requests/reducer';

const appReducer = combineReducers({
  reservationReducer,
  authReducer,
  timeslotsReducer,
  appointmentTypesReducer,
  usersReducer,
  pushMessagesReducer,
  requestsReducer,
  loggingReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_REQUEST') state = undefined;
  return appReducer(state, action)
};

export default rootReducer;