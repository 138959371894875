import { connect } from 'react-redux';
import {
    checkIsResourceEmpty,
    deleteResource,
    toggleDeleteResourceModal
} from "../../../redux/reservations/actions";
import ResourceDeleteModal from "./ResourceDeleteModal";

const mapStateToProps = (state) => ({
    resourceDeleteModalIsOpened: state.reservationReducer.resourceDeleteModalIsOpened,
    isResourceEmpty: state.reservationReducer.isResourceEmpty,
    bloodBankId: state.authReducer.currentBloodbank,
    selectedResource : state.reservationReducer.selectedResource,
});

const mapDispatchToProps = {
    checkIsResourceEmpty,
    deleteResource,
    toggleDeleteResourceModal
};

export default connect(mapStateToProps, mapDispatchToProps)(ResourceDeleteModal);
