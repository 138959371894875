import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Auth from '../index';
import LoginForm from './LoginFormContainer';
import routes from '../../../constants/routes'

const Login = () => {
  const { t } = useTranslation();

  return (
    <Auth>
      <div className="modal">
        <LoginForm />
      </div>
      <div className="auth-subinfo">
        <Link to={ routes.FORGOT_PASSWORD_ROUTE } className="link">{t('auth.forgotPassword')}?</Link>
      </div>
    </Auth>
  );
};

export default Login;
