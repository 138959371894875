import React from 'react';
import { useTranslation } from 'react-i18next';

import Header from '../common/Header';

const ChangePhoneSuccess = () => {
  const formClassName = 'form auth-form two-factor';
  const { t } = useTranslation();

  return (
    <div className="page-container">
      <Header />
      <div className="page-content">
        <main className="main auth">
          <div className="auth-content">
            <div className="modal">
              <div className={ formClassName }>
                <span className="auth-success-email icon-phone" />
                <p className="auth-success-msg auth-title-2">{t('auth.phoneWasChanged')}</p>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ChangePhoneSuccess;
