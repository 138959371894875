import { connect } from 'react-redux';
import {
  toggleSettings, 
  toggleDeleteTimeslotModal, 
  toggleDeleteAppointmentModal,
  toggleBookAppointmentModal,
  toggleBookingCancelModal
} from "../../redux/timeslots/actions";
import TimeSlotMenu from "./TimeSlotMenu";

const mapStateToProps = (state) => ({
  selectedTimeslot: state.timeslotsReducer.selectedTimeslot,
  recurrenceRule: state.timeslotsReducer.recurrenceRule,
});

const mapDispatchToProps = {
  toggleSettings,
  toggleDeleteTimeslotModal,
  toggleDeleteAppointmentModal,
  toggleBookAppointmentModal,
  toggleBookingCancelModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeSlotMenu);
