import React, {useEffect, useState} from 'react';
import Radio from '../../common/FormFields/Radio';
import Number from '../../common/FormFields/Number';
import { useTranslation } from 'react-i18next';

const DailyRecurrence = (props) => {
  const { onChangeData, timeslotRule: { interval = 1 } } = props;
  const { t } = useTranslation();

  const [dailyInterval, setDailyInterval] = useState(interval);
  const [inputValue, setInputValue] = useState(interval > 1 ? interval : 2);
  const [selectedEveryDay, setSelectedEveryDay] = useState(interval <= 1);

  useEffect(() => {
    const dailyData = {interval: dailyInterval};
    onChangeData(dailyData);
  }, [dailyInterval]);

  useEffect(() => {
    if (!selectedEveryDay) {
      setDailyInterval(inputValue)
    }
  }, [inputValue, selectedEveryDay]);

  const handleEveryDay = () => {
    setDailyInterval(1);
    setSelectedEveryDay(true);
  };

  const handleByDay = () => {
    setDailyInterval(inputValue);
    setSelectedEveryDay(false);
  };

  return (
    <>
      <div className="form-group inputs-group">
        <div className="inputs-group-item">
          <Radio label={t('configuration.recurrence.everyDay')} name="occur_radio" checked={selectedEveryDay} returnValue={handleEveryDay} />
        </div>
      </div>
      <div className="form-group inputs-group">
        <div className="inputs-group-item">
          <Radio label={t('configuration.recurrence.every')} name="occur_radio" checked={!selectedEveryDay} returnValue={handleByDay} />
        </div>
        <div className="inputs-group-item">
          <Number value={inputValue} minValue={2} maxValue={99} onChange={setInputValue} disabled={selectedEveryDay} pattern={"[2-9][0-9]?"}/>
        </div>
        <div className="inputs-group-item">
          <span className="bold">{t('configuration.recurrence.days')}</span>
        </div>
      </div>
    </>
  );
};

export default DailyRecurrence;
