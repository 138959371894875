import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

const Input = (props) => {
  const {
    id,
    placeholder,
    disabled,
    label,
    value,
    name,
    step,
    minValue,
    maxValue,
    additionalClassName,
    pattern,
    onChange,
  } = props;
  const [inputValue, setInputValue] = useState(value);
  const [isInputValid, setInputValid] = useState(true);

  useEffect(() => {
    onChange(inputValue);
  }, [inputValue]);

  const checkValidity = () => {
    setInputValid(inputValue >= minValue && inputValue <= maxValue);
  };

  const handleInput = (evt) => {
    if (evt.target.value && evt.target.validity.valid) {
      setInputValue(+evt.target.value)
    } else {
      setInputValue(+inputValue);
    }
  };

  const incrementValue = () => {
    return inputValue > maxValue ? setInputValue(maxValue) : setInputValue(+inputValue + step);
  };
  const decrementValue = () => {
    return inputValue < minValue ? setInputValue(minValue) : setInputValue(+inputValue - step);
  };
  const controlClassName = isInputValid ? 'form-control' : 'form-control has-error';
  const fieldClassName = `form-field number ${ additionalClassName }`;

  return (
    <div className={ controlClassName }>
      { label &&
      <label className="form-label" htmlFor={ id }>
        { label }
      </label>
      }
      <div className={ fieldClassName }>
        <input className="number-input"
               id={ id }
               name={ name }
               type="text"
               value={ inputValue }
               placeholder={ placeholder }
               disabled={ disabled }
               onChange={ () => {
               } }
               onInput={ (e) => handleInput(e) }
               pattern={pattern}
               onBlur={ () => checkValidity() } />
        <div className="number-controls">
          <button type="button" className="number-btn" onClick={ incrementValue }
                  disabled={ disabled || (inputValue >= +maxValue) }>+
          </button>
          <button type="button" className="number-btn" onClick={ decrementValue }
                  disabled={ disabled || (inputValue <= +minValue) }>-
          </button>
        </div>
      </div>
      <div className="form-error">Required</div>
    </div>
  );
};

Input.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  additionalClassName: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.number,
  step: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  type: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  pattern: PropTypes.string,
  onChange: PropTypes.func,
};

Input.defaultProps = {
  id: '',
  placeholder: '',
  additionalClassName: 'xxs',
  label: '',
  value: 0,
  step: 1,
  minValue: 0,
  maxValue: 100,
  name: 'input-form',
  type: 'text',
  disabled: false,
  pattern: "[0-9]*",
  onChange: () => {},
};


export default Input;

