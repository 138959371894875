import { handleActions } from 'redux-actions';
import {
  setSystemMessages,
  setAdminMessages,
  setScheduledMessages,
  setLastAdminMessageId,
  createMessageSuccess,
  requestFail,
  togglePushMessagesModal,
  toggleFilterSettingsModal,
  setCouplingTableData,
  setSelectedFilters,
  refreshFilters,
} from './actions';

const initialState = {
  systemMessages: [],
  adminMessages: [],
  scheduledMessages: [],
  lastAdminMessageId: null,
  selectedFilters: {
    donorTypes: [],
    bloodTiters: [],
    donorStatuses: [],
    appUserStatuses: [],
    smsStatuses: [],
  },
  couplingTableData: [],
  isError: false,
  error: null,
  isToastErrorShown : false,
  pushMessagesModalIsOpen: false,
  filterSettingsModalIsOpen: false,
};

const pushMessagesReducer = handleActions({
  [setSystemMessages]: (state, action) => ({
    ...state,
    systemMessages: action.payload.content,
    isError: false,
    error: null,
    isToastErrorShown: false,
  }),
  [setAdminMessages]: (state, action) => ({
    ...state,
    adminMessages: action.payload.content,
    isError: false,
    error: null,
    isToastErrorShown: false,
  }),
  [setScheduledMessages]: (state, action) => ({
    ...state,
    scheduledMessages: action.payload.content,
    isError: false,
    error: null,
    isToastErrorShown: false,
  }),
  [setLastAdminMessageId]: (state, action) => ({
    ...state,
    lastAdminMessageId: +action.payload.content.id + 1,
    isError: false,
    error: null,
    isToastErrorShown: false,
  }),
  [createMessageSuccess]: (state) => ({
    ...state,
    selectedFilters: {
      donorTypes: [],
      bloodTiters: [],
      donorStatuses: [],
      appUserStatuses: [],
      smsStatuses: [],
    },
    isError: false,
    error: null,
    isToastErrorShown: false,
  }),
  [requestFail]: (state, action) => ({
    ...state,
    isError: true,
    error: action.payload,
    isToastErrorShown: true,
  }),
  [togglePushMessagesModal]: (state) => ({
    ...state,
    pushMessagesModalIsOpen: !state.pushMessagesModalIsOpen,
  }),
  [toggleFilterSettingsModal]: (state) => ({
    ...state,
    filterSettingsModalIsOpen: !state.filterSettingsModalIsOpen,
  }),
  [setCouplingTableData]: (state, action) => ({
    ...state,
    couplingTableData: action.payload.content,
    isError: false,
    error: null,
    isToastErrorShown: false,
  }),
  [setSelectedFilters]: (state, action) => ({
    ...state,
    selectedFilters: action.payload,
  }),
  [refreshFilters]: (state) => ({
    ...state,
    selectedFilters: {
      donorTypes: [],
      bloodTiters: [],
      donorStatuses: [],
      appUserStatuses: [],
      smsStatuses: [],
    },
  }),
},
initialState);

export default pushMessagesReducer;