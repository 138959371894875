export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export const CLEAR_VALIDATION_ERRORS = 'CLEAR_VALIDATION_ERRORS';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const GET_PROFILE_DATA = 'GET_PROFILE_DATA';
export const SET_CURRENT_CODE = 'SET_CURRENT_CODE';
export const SET_CURRENT_PHONE = 'SET_CURRENT_PHONE';
export const ADD_RESEND = 'ADD_RESEND';
export const SET_PHONE = 'SET_PHONE';
export const RESET_RESEND_COUNT = 'RESET_RESEND_COUNT';
export const TWO_FACTOR_ERROR = 'TWO_FACTOR_ERROR';
export const SET_CURRENT_BLOODBANK = 'SET_CURRENT_BLOODBANK';
export const SET_TO_ALL_BLOODBANKS = 'SET_TO_ALL_BLOODBANKS';
export const SET_PASSWORDS = 'SET_PASSWORDS';