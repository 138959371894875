import React, {useEffect, useState} from 'react';

import Auth from '../index';
import Password from '../../common/FormFields/Password';
import Button from '../../common/FormFields/Button';
import queryString from 'query-string';
import {withRouter} from "react-router-dom";
import { useTranslation } from 'react-i18next';

const RestorePassword = (props) => {
  const { resetPasswordRequest, forgotPasswordError, location, clearAuthValidationErrors } = props;
  const [newPassword, setNewPassword] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    clearAuthValidationErrors();
  }, [location])


  const handleSubmit = (e) => {
    e.preventDefault();
    const { oobCode } = queryString.parse(location.search);
    resetPasswordRequest({ verificationCode: oobCode, newPassword });
  };

  return (
    <Auth>
      <div className="modal">
        <form action="/" className="form auth-form">
          <h1 className="auth-title-2">{t('auth.createNewPassword')}</h1>
          <div className="auth-body">
            <Password
              placeholder={t('auth.enterNewPassword')}
              label={t('auth.newPassword')}
              onChangeValue={setNewPassword}
              errorMessage={forgotPasswordError}
            />
          </div>
          <div className="auth-footer">
            <Button
              btnClassName="btn-primary"
              text={t('auth.confirm')}
              onClick={handleSubmit}
              disabled={!newPassword}
            />
          </div>
        </form>
      </div>
    </Auth>
  );
};

export default withRouter(RestorePassword);
