import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

const CheckboxGroup = (props) => {
  const { data, handleCheck } = props;
  const [isChecked, setIsChecked] = useState(data.checked);

  useEffect(() => {
    handleCheck({ ...data, checked: isChecked });
  }, [isChecked])

  const changeHandler = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className="checkbox-group-item" key={ `${ data.label }_${ data.id }` }>
      <label className="check">
        <input
          type="checkbox"
          className="check-input visually-hidden"
          checked={ isChecked }
          onChange={ changeHandler }
          disabled={data.disabled}
        />
        <div className="check-mark">{ data.label }</div>
      </label>
    </div>
  );
};


CheckboxGroup.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
  }),
  handleCheck: PropTypes.func,
};

CheckboxGroup.defaultProps = {
  data: {},
  handleCheck: () => {},
};

export default CheckboxGroup;

