import { connect } from 'react-redux';
import { 
    togglePushMessagesModal,
    createAdminMessage,
    getLastAdminMessage,
    setSelectedFilters,
} from "../../redux/pushMessages/actions";
import { 
    getFilteredUsers
} from "../../redux/users/actions";
import { sendErrorLog } from '../../redux/logging/actions';
import { PushMessageForm } from './PushMessageForm';

const mapStateToProps = (state) => ({
    pushMessagesModalIsOpen: state.pushMessagesReducer.pushMessagesModalIsOpen,
    currentBloodbank: state.authReducer.currentBloodbank,
    bloodbankName: state.authReducer.bloodbankName,
    adminId: state.authReducer.userId,
    usersAmount: state.usersReducer.usersAmount,
    lastAdminMessageId: state.pushMessagesReducer.lastAdminMessageId,
    selectedFilters: state.pushMessagesReducer.selectedFilters,
    userRoleName: state.authReducer.roleName,
});

const mapDispatchToProps = {
    getFilteredUsers,
    togglePushMessagesModal,
    createAdminMessage,
    getLastAdminMessage,
    setSelectedFilters,
    sendErrorLog,
};

export default connect(mapStateToProps, mapDispatchToProps)(PushMessageForm);