import { createAction } from 'redux-actions';
import {
    SET_PENDING_REQUEST,
    DELETE_ALL_PENDING_REQUESTS,
    DELETE_PENDING_REQUEST
} from './actionTypes';

// set a pending request
export const setPendingRequest = createAction(SET_PENDING_REQUEST)
export const deletePendingRequest = createAction(DELETE_PENDING_REQUEST);
export const deletePendingRequests = createAction(DELETE_ALL_PENDING_REQUESTS);

// Abort a specific pending request
export const abortPendingRequest = (reqId) => (dispatch, getState) => {
    const state = getState();
    const pendingRequests = state.requestsReducer.pendingRequests;

    const requestToAbort = pendingRequests[reqId];
    if (requestToAbort) {
        requestToAbort.abort();
        dispatch(deletePendingRequest({ id: reqId }));
    }
};

// Add pending request
export const addPendingRequest = ({ id, controller }) => (dispatch, getState) => {
    const state = getState();
    const pendingRequests = state.requestsReducer.pendingRequests;
    const request = pendingRequests[id];

    if (request) {
        dispatch(abortPendingRequest(id))
        return;
    }
    else {
        dispatch(setPendingRequest({ id, controller }))
    }
}

// Abort all pending requests
export const abortPendingRequests = () => (dispatch, getState) => {
    const state = getState();
    const pendingRequests = state.requestsReducer.pendingRequests;

    for (const key in pendingRequests) {
        if (pendingRequests[key]) {
            pendingRequests[key].abort()
        }
    }
    dispatch(deletePendingRequests());
};