import React, {useState, useEffect} from 'react';

import Modal from '../../common/Modal';
import Button from '../../common/FormFields/Button';
import { useTranslation } from 'react-i18next';
import {
  getStartOfWorkDay,
  checkRecurrence,
} from '../../../services/helpers';

const DeleteAppointment = (props) => {
  const {
    selectedTimeslot,
    recurrenceRule,
    currentDate,
    deleteAppointmentModalIsOpen,
    setTimeslotRequest,
    toggleDeleteAppointmentModal,
  } = props;
  const { t } = useTranslation();

  const [date, setDate] = useState(getStartOfWorkDay(currentDate));
  const [isLastAppointment, setIsLastAppointment] = useState(!checkRecurrence(selectedTimeslot));

  useEffect(() => {
    setDate(getStartOfWorkDay(currentDate));
  }, [currentDate]);

  useEffect(() => {
    setIsLastAppointment(!checkRecurrence(selectedTimeslot));
  }, [selectedTimeslot]);


  const getTimeslotRequestBody = () => {
    const { 
      resourceId, 
      timeslotId, 
      donorType,
      bloodTiter,
      appointmentType, 
      startDate, 
      endDate, 
      ruleDescription,
      initialExceptionDates=[],
      initialExceptionRules,
    } = selectedTimeslot;
   
    return {
      resourceId,
      donorType,
      bloodTiter,
      appointmentTypeId: appointmentType.appointmentTypeId,
      startDate,
      endDate,
      timeslotId,
      ...(recurrenceRule && {rule: recurrenceRule}),
      ruleDescription,
      exceptionDates: [...initialExceptionDates, date],
      exceptionRules: initialExceptionRules,
    };
  };


  const handleSubmit = () => {
    const data = getTimeslotRequestBody();
    setTimeslotRequest(data, selectedTimeslot.appointmentType.duration);
    toggleDeleteAppointmentModal();
  };

  return (
    <>
      <Modal isModalOpen={ deleteAppointmentModalIsOpen } handleModalClose={ toggleDeleteAppointmentModal } { ...props }>
        <div className="modal-header">
          <h2 className="modal-title">{t('configuration.settings.deleteAppointmentModal.header')}</h2>
          <button className="modal-close" onClick={ toggleDeleteAppointmentModal }>
            <span className="icon-close" />
          </button>
        </div>
        <div className="modal-body modal-content">
          <div className="modal-msg">
            { isLastAppointment ? t('configuration.settings.deleteAppointmentModal.body2') :
            t('configuration.settings.deleteAppointmentModal.body1') }
          </div>
        </div>
        <div className="modal-footer light">
          <div className="inputs-group">
            <div className="inputs-group-item">
              <Button type="button" text={t('common.cancel')} onClick={ toggleDeleteAppointmentModal } />
            </div>
            <div className="inputs-group-item">
              <Button btnClassName={`btn-primary ${isLastAppointment && 'disabled'}`} type="button" text={t('common.delete')} onClick={handleSubmit} />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteAppointment;
