import React, {useEffect, useState} from 'react';
import Radio from '../../common/FormFields/Radio';
import Number from '../../common/FormFields/Number';
import CustomSelect from '../../common/FormFields/Select';
import { WEEKDAYS_RULE, WEEKDAYS_LIST } from './constants';
import {getNumberOfOccurrenceInMonth} from "../../../services/helpers";
import { useTranslation } from 'react-i18next';

const MonthlyRecurrence = (props) => {
  const { onChangeData, currentDate, timeslotRule: { interval = 1, bymonthday, byweekday, weekNumber } } = props;
  const { t } = useTranslation();
  const currentWeek = WEEKDAYS_RULE[getNumberOfOccurrenceInMonth(currentDate) - 1];
  const currentWeekday = WEEKDAYS_LIST[currentDate.getDay()];
  const weekdaysRule = WEEKDAYS_RULE.map(data => ({ ...data, label: t(`configuration.recurrence.${data.label}`) }));
  const weekdaysList = WEEKDAYS_LIST.map(data => ({ ...data, label: t(`common.day.${data.label}`) }));

  const [onExactDay, setOnExactDay] = useState(!byweekday);
  const [byMonth, setByMonth] = useState(interval);
  const [byDay, setByDay] = useState(bymonthday ? bymonthday[0] : currentDate.getDate());
  const [currentWeekNumber, setCurrentWeekNumber] = useState(
    weekNumber
    ? weekdaysRule.find(week => weekNumber === +week.value)
    : { ...currentWeek, label: t(`configuration.recurrence.${currentWeek.label}`) }
  );
  const [dayNumber, setDayNumber] = useState(byweekday
    ? weekdaysList.find(item => byweekday[0] === +item.value)
    : { ...currentWeekday, label: t(`common.day.${currentWeekday.label}`) }
  );

  useEffect(() => {
    let monthlyRule = {
      interval: byMonth,
      ...(onExactDay
        ? {bymonthday: [byDay]}
        : {byweekday: [parseInt(dayNumber.value)], weekNumber: parseInt(currentWeekNumber.value)}
      )
    };

    onChangeData(monthlyRule);
  }, [onExactDay, byMonth, byDay, currentWeekNumber, dayNumber]);

  return (
    <>
      <div className="form-group inputs-group">
        <div className="inputs-group-item">
          {t('configuration.recurrence.every')}
        </div>
        <div className="inputs-group-item">
          <Number value={byMonth} minValue={1} maxValue={31} pattern={"[1-9]|1[0-2]"} onChange={setByMonth} />
        </div>
        <div className="inputs-group-item">
          {t('configuration.recurrence.months')}
        </div>
        <div className="inputs-group-item">
          <Radio label={t('configuration.recurrence.onDay')} name="occur_radio" checked={onExactDay} returnValue={() => setOnExactDay(true)} />
        </div>
        <div className="inputs-group-item">
          <Number disabled={!onExactDay} value={byDay} minValue={1} maxValue={31} pattern={"[1-9]|[1-2][0-9]|3[0-1]"} onChange={setByDay} />
        </div>
      </div>
      <div className="form-group inputs-group">
        <div className="inputs-group-item">
          <Radio label={t('configuration.recurrence.onThe')} name="occur_radio" checked={!onExactDay} returnValue={() => setOnExactDay(false)} />
        </div>
        <div className="inputs-group-item">
          <CustomSelect
            data={ weekdaysRule }
            value={currentWeekNumber}
            placeholder="Select"
            additionalClassName="md"
            isDisabled={onExactDay}
            onChange={setCurrentWeekNumber}
          />
        </div>
        <div className="inputs-group-item">
          <CustomSelect
            data={ weekdaysList }
            value={dayNumber}
            placeholder="Select"
            additionalClassName="lg"
            isDisabled={onExactDay}
            onChange={setDayNumber}
          />
        </div>
      </div>
    </>
  );
};

export default MonthlyRecurrence;
