import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { confirmCodeMobilePassword } from '../../redux/auth/actions';

import Input from '../common/FormFields/Input';
import Button from '../common/FormFields/Button';

const TwoFatcorPasswordForm = (props) => {
  const [code, setCode] = useState('');
  const {t} = useTranslation();
  const formClassName = 'form auth-form two-factor';
  const { confirmCodeMobilePassword, isTwoFactorError } = props;

  const handleClick = (e) => {
    e.preventDefault();
    confirmCodeMobilePassword(code)
  }

  return (
    <form action="submit" className={formClassName}>
      <h1 className="auth-title-2">{t('auth.enterCodeToProceed')}</h1>
      <div className="auth-body">
        <Input
          type="text"
          placeholder={t('auth.enterYourCode')}
          label={t('auth.code')}
          errorMessage={isTwoFactorError ? t('auth.invalidCode') : ''}
          onChangeValue={setCode}
          autoFocus={true}
        />
      </div>
      <div className="auth-footer">
        <Button
          btnClassName="btn-primary"
          text={t('auth.confirm')}
          id="sign-in-button-change-pass"
          onClick={handleClick}
        />
      </div>
    </form>
  );
};

const mapStateToProps = (state) => ({
  isTwoFactorError: state.authReducer.isTwoFactorError
});

const mapDispatchToProps = {
  confirmCodeMobilePassword
}
export default connect(mapStateToProps, mapDispatchToProps)(TwoFatcorPasswordForm);
