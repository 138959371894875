import { connect } from 'react-redux';
import {
  setTimeslotRequest, 
  toggleDeleteAppointmentModal
} from "../../../redux/timeslots/actions";
import DeleteAppointment from "./DeleteAppointment";

const mapStateToProps = (state) => ({
  selectedTimeslot: state.timeslotsReducer.selectedTimeslot,
  recurrenceRule: state.timeslotsReducer.recurrenceRule,
  currentDate: state.reservationReducer.currentDate,
  deleteAppointmentModalIsOpen: state.timeslotsReducer.deleteAppointmentModalIsOpen,
});

const mapDispatchToProps = {
  setTimeslotRequest,
  toggleDeleteAppointmentModal
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAppointment);
