import React from 'react';
import Dashboard from '../Dashboard';
import Schedule from '../Schedule';

const Day = () => {

  return (
    <Dashboard>
      <Schedule
        timeIntervalSize="30"
        additionalClassName="day"
      />
    </Dashboard>
  );
};

export default Day;
