import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

const Radio = (props) => {
    const {
        label, name, disabled, value, checked, returnValue
    } = props;

    const [isChecked, setIsChecked] = useState(checked);

    useEffect(() => {
        setIsChecked(checked);
    }, [checked])

    const changeHandler = () => {
        setIsChecked(!isChecked);
        returnValue(value);
    };

    const radioClass = disabled ? 'radio disabled' : 'radio';

    return (
        <label className={radioClass}>
            <input
                type="radio"
                className="radio-input visually-hidden"
                name={name}
                value={value}
                disabled={disabled}
                checked={isChecked}
                onChange={changeHandler}
            />
            <div className="radio-mark"/>
            {label && <div className="radio-label">{label}</div>}
        </label>
    );
};

Radio.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
    returnValue: PropTypes.func
};

Radio.defaultProps = {
    label: '',
    name: 'radio',
    value: '',
    disabled: false,
    checked: false,
    returnValue: () => {},
};


export default Radio;
