import React, {useEffect, useState} from 'react';
import TetherComponent from 'react-tether';
import PropTypes from 'prop-types';
import * as dateFns from 'date-fns';
import OutsideClickHandler from "../../OutsideClickHandler/index";
import { useTranslation } from 'react-i18next';

const Datepicker = (props) => {
  const {
    id, label, date, minDate, maxDate, hasError, disabled, onDateChange, changeQuarantineExpire, calendarPosition
  } = props;
  const { t } = useTranslation();
  const currentDate = date ? new Date(date) : new Date();

  const [currentMonth, setCurrentMonth] = useState(currentDate);
  const [isDatepickerOpen, setDatepickerOpen] = useState(false);

  const controlClass = hasError ? 'form-control has-error' : 'form-control';
  const fieldClass = disabled ? 'datepicker-control disabled' : 'datepicker-control';
  const pickerClass = isDatepickerOpen ? 'datepicker-menu menu open' : 'menu';

  const changeSelectedDate = (selectedDate) => {
    onDateChange(selectedDate);
    setCurrentMonth(selectedDate);
  }

  const handleChange = (date) => {
    const preparedDate = new Date(date);
    preparedDate.setMinutes(preparedDate.getMinutes() - preparedDate.getTimezoneOffset());
    changeSelectedDate(preparedDate);
    changeQuarantineExpire(preparedDate);
  };

  const togglePicker = () => {
    setCurrentMonth(currentDate);
    setDatepickerOpen(!isDatepickerOpen);
  };

  const handleClose = () => {
    setDatepickerOpen(false);
  };

  const onDateClick = (date) => {
    handleChange(date);
    handleClose();
  };

  const nextDay = () => {
    const newDay = dateFns.addDays(currentDate, 1);

    changeSelectedDate(newDay);
    handleClose();
  };

  const prevDay = () => {
    const newDay = dateFns.subDays(currentDate, 1);

    changeSelectedDate(newDay);
    handleClose();
  };

  const nextMonth = () => {
    const newMonth = dateFns.addMonths(currentMonth, 1);

    setCurrentMonth(newMonth);
  };

  const prevMonth = () => {
    const newMonth = dateFns.subMonths(currentMonth, 1);

    setCurrentMonth(newMonth);
  };

  const renderHeader = () => {
    const dayFormat = "dd";
    const monthFormat = "MMMM";
    const yearFormat = "yyyy";
    const formattedDay = dateFns.format(new Date(currentDate), dayFormat);
    const formattedMonth = dateFns.format(new Date(currentDate), monthFormat);
    const formattedYear = dateFns.format(new Date(currentDate), yearFormat);

    return (
      <>
        <button className="datepicker-btn" onClick={ prevDay }>
          <i className="icon-arrow-left" />
        </button>
        <div className="datepicker-value" onClick={ togglePicker }>
          <i className="icon-calendar" />
          <div className="datepicker-date">
            {`${formattedDay} ${t(`common.month.${formattedMonth}`)} ${formattedYear}`}
          </div>
        </div>
        <button className="datepicker-btn" onClick={ nextDay }>
          <i className="icon-arrow-right" />
        </button>
      </>
    )
  };

  const renderDays = () => {
    const weekdayFormat = "EEEEEE";
    const days = [];
    const currentMonth = dateFns.getMonth(currentDate);

    let startDate = dateFns.startOfWeek(currentMonth, {weekStartsOn: 1});

    days.push(
      <div className="picker-cell" key='init'>
      </div>
    );

    for (let i = 0; i < 7; i++) {
      const day = dateFns.addDays(startDate, i);
      days.push(
        <div className="picker-cell" key={ i }>
          { t(`common.day.${dateFns.format(day, weekdayFormat)}`) }
        </div>
      );
    }

    return <div className="picker-row picker-weekdays">{ days }</div>;
  };

  const renderCells = () => {
    const monthStart = dateFns.startOfMonth(currentMonth);
    const monthEnd = dateFns.endOfMonth(monthStart);
    const startDate = dateFns.startOfWeek(monthStart, {weekStartsOn: 1});
    const endDate = dateFns.endOfWeek(monthEnd);

    const dateFormat = "d";
    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDate = "";

    const getAdditionalClassName = (day) => {
      if (!dateFns.isSameMonth(day, monthStart)) return "hidden";
      if ((maxDate && dateFns.isAfter(day, maxDate)) || (minDate && dateFns.isBefore(day, minDate))) return "disabled";
      if (dateFns.isSameDay(day, currentDate)) return "selected";
      if (dateFns.isToday(day)) return "today";
      return "";
    };

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = dateFns.format(day, dateFormat);
        const cloneDay = day;

        days.push(
          <div
            className={ `picker-cell ${getAdditionalClassName(day)}` }
            key={ day }
            onClick={ () => onDateClick(new Date(cloneDay)) }
          >
            { formattedDate }
          </div>
        );
        day = dateFns.addDays(day, 1);
      }
      rows.push(
        <div className="picker-row" key={ day }>
          <div className="picker-week" >
            <div className="picker-week-label">
              {dateFns.subDays(day, 7).getWeek()}
            </div>
          </div>
          { days }
        </div>
      );
      days = [];
    }
    return <div className="picker-body">{ rows }</div>;
  };

  const renderCalendar = () => {
    const monthFormat = "MMM";
    const yearFormat = "yyyy";
    const formattedMonth = dateFns.format(new Date(currentMonth), monthFormat);
    const formattedYear = dateFns.format(new Date(currentMonth), yearFormat);

    return (
      <>
        <div className="picker-header">
          <button className="datepicker-btn" onClick={ prevMonth }>
            <i className="icon-arrow-left" />
          </button>
          <div className="picker-value">
            {`${t(`common.month.${formattedMonth}`)} ${formattedYear}`}
          </div>
          <button className="datepicker-btn" onClick={ nextMonth }>
            <i className="icon-arrow-right" />
          </button>
        </div>
        { renderDays() }
        { renderCells() }
      </>
    )
  };

  return (
    <div className={ controlClass }>
      { label &&
      <label className="form-label" htmlFor={ id }>
        { label }
      </label>
      }
      <div className="datepicker">
        <TetherComponent
          attachment={`${calendarPosition} left`}
          targetAttachment="bottom left"
          constraints={ [
            {
              to: 'window',
              attachment: 'together',
            },
          ] }
          renderTarget={ ref =>
            <div ref={ ref } className={ fieldClass }>
              { renderHeader() }
            </div>
          }
          renderElement={ ref => (
            isDatepickerOpen && (
              <OutsideClickHandler handleClickOutside={ handleClose } className="datepicker-toggler">
                <div ref={ ref } className={ pickerClass }>
                  { renderCalendar() }
                </div>
              </OutsideClickHandler>
            )
          ) }
        />
      </div>
      <div className="form-error">Required</div>
    </div>
  );
};


Datepicker.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onDateChange: PropTypes.func,
  changeQuarantineExpire: PropTypes.func,
  calendarPosition: PropTypes.string,
};

Datepicker.defaultProps = {
  id: '',
  label: '',
  value: '',
  disabled: false,
  onDateChange: () => {},
  changeQuarantineExpire: () => {},
  calendarPosition: 'bottom'
};

export default Datepicker;

