import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { createPageMarkers, createSelectOptions } from './helpers';
import CustomSelect  from '../FormFields/Select';
import { useTranslation } from 'react-i18next';

const Pagination = (props) => {
  const {
    pageSize,
    pageSizes,
    allPagesSize,
    startPage,
    totalItems,
    handleChange,
    handlePage = () => {}
  } = props;
  const { t } = useTranslation();

  const [rowsPerPage, setRowsPerPage] = useState({label: String(pageSize), value: Number(pageSize)});
  const [currentPage, setCurrentPage] = useState(startPage);

  const pagesAmount = Math.ceil((totalItems || 1) / rowsPerPage.value);

  const [pageMarkers, setPageMarkers] = useState(
    createPageMarkers(pagesAmount, currentPage)
  );

  const [firstExtremeIndex, setFirstExtremeIndex] = useState(0);
  const [lastExtremeIndex, setLastExtremeIndex] = useState(0);

  const setIndexes = (currentPage, rowsPerPage, totalItems) => {
    setFirstExtremeIndex((currentPage * rowsPerPage) - rowsPerPage);
    setLastExtremeIndex((currentPage * rowsPerPage) < totalItems ? (currentPage * rowsPerPage) : totalItems);
  }

  useEffect(() => {
    if (currentPage > pagesAmount) {
      setCurrentPage(pagesAmount);
    } 
    if (currentPage < 1) {
      setCurrentPage(1);
    }
    setPageMarkers(createPageMarkers(pagesAmount, currentPage));
    setIndexes(currentPage, rowsPerPage.value, (totalItems || 1));
    handleChange(firstExtremeIndex, lastExtremeIndex);
    handlePage(currentPage);
  }, [rowsPerPage, currentPage, pagesAmount, firstExtremeIndex, lastExtremeIndex]);

  useEffect(() => {
    setCurrentPage(startPage);
    setIndexes(currentPage, rowsPerPage.value, (totalItems || 1));
    handleChange(firstExtremeIndex, lastExtremeIndex);
    handlePage(currentPage);
  }, [totalItems, startPage]);

  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === pagesAmount;

  const prevPage = () => isFirstPage || setCurrentPage((prev) => prev - 1);
  const nextPage = () => isLastPage || setCurrentPage((prev) => prev + 1);
  const paginate = (page) => setCurrentPage(page);

  return (
    <div className="pagination">
      <div className="pagination__title"> {t('pagination.rows_amount')} </div>
      <div className="pagination__count-select">
        <CustomSelect
          data={createSelectOptions(pageSizes, allPagesSize, (totalItems || 1))}
          value={rowsPerPage}
          onChange={(option) => {
            setRowsPerPage({label: String(option.label), value: Number(option.value)});
          }}
        />
      </div>
      <div className="pagination__title">
        {firstExtremeIndex + 1}-{lastExtremeIndex} {t('pagination.of')} {(totalItems || 1)}
      </div>
      <div className="pagination__page-select page-select">
        <button
          className={classNames('page-select__button', {
            'page-select__button--diabled': isFirstPage,
          })}          
          onClick={() => {
            prevPage();
          }}
        >
          {t('pagination.previous')}
        </button>
        <div className="page-select__markers-container">
          {pageMarkers.map((marker) => (
            <button
              key={marker.key}
              className={classNames('page-select__marker', {
                'page-select__marker--active': currentPage === marker.value,
                'page-select__marker--disabled': marker.value === 0,
              })}
              onClick={() => {
                if (marker.value !== 0) {
                  paginate(marker.value);
                }
              }}
            >
              {marker.text}
            </button>
          ))}
        </div>
        <button
          className={classNames('page-select__button', {
            'page-select__button--diabled': isLastPage,
          })}
          onClick={() => {
            nextPage();
          }}
        >
          {t('pagination.next')}
        </button>
      </div>
    </div>
  );
};

export default Pagination;
