import { connect } from 'react-redux';
import { 
  setExceptionRules, 
  setExceptionDates, 
  setExceptionHoursOnDates 
} from "../../../redux/timeslots/actions";
import ExceptionsSettings from "./ExceptionsSettings";

const mapStateToProps = (state) => ({
  currentDate: state.reservationReducer.currentDate,
  exceptionRules: state.timeslotsReducer.exceptionRules,
  exceptionDates: state.timeslotsReducer.exceptionDates,
  exceptionHoursOnDates: state.timeslotsReducer.exceptionHoursOnDates,
  recurrenceRule: state.timeslotsReducer.recurrenceRule,
  selectedTimeslot: state.timeslotsReducer.selectedTimeslot,
});

const mapDispatchToProps = {
  setExceptionRules,
  setExceptionDates,
  setExceptionHoursOnDates,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExceptionsSettings);
