export const SET_USERS = 'SET_USERS';
export const GET_USER_BY_PNR = 'GET_USER_BY_PNR';
export const GET_DONOR_DETAILS = 'GET_DONOR_DETAILS';
export const GET_DONOR_SETTINGS = 'GET_DONOR_SETTINGS';
export const REQUEST_FAIL = 'REQUEST_FAIL';
export const REQUEST_PENDING = 'REQUEST_PENDING';
export const DELETE_DONOR_DETAILS = 'DELETE_DONOR_DETAILS';
export const DELETE_DONOR_SETTINGS = 'DELETE_DONOR_SETTINGS';
export const TOGGLE_DONOR_DETAILS_MODAL = 'TOGGLE_DONOR_DETAILS_MODAL';
export const TOGGLE_DONOR_SETTINGS_MODAL = 'TOGGLE_DONOR_SETTINGS_MODAL';
export const SET_LAST_CHOOSEN_DONOR = 'SET_LAST_CHOOSEN_DONOR';