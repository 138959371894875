import React from 'react';

const Tabs = (props) => {
  const { data, updateData, selectedValue } = props;

  return (
    <div className="tabs">
      { data.map((tabData) => {
        const { id, name, value } = tabData;

        return (
          <div className="tab" key={ id }>
            <input
              className="tab-input visually-hidden"
              type="radio"
              name="tab_group"
              id={ `${ id }` }
              checked={ name === selectedValue }
              onChange={ () => updateData(name, tabData) }
            />
            <label htmlFor={ `${ id }` } className="tab-label">
              { value }
            </label>
          </div>
        );
      }) }
    </div>
  );
};

export default Tabs;
