import React from 'react';
import TetherComponent from 'react-tether';

const TimeSlot = (props) => {
  const {
    children,
    scrollableArea, 
    currentAppointment,
    getDataForDonorDetails,
    getDataForDonorSettings,
    toggleDonorDetailsModal,
    toggleDonorSettingsModal,
  } = props;

  const handleOpenDonorDetails = (id, details) => {
    getDataForDonorDetails(id, details);
    toggleDonorDetailsModal();
  };

  const handleOpenDonorSettings = (data) => {
    if (data) {
      getDataForDonorSettings(data.userId, data);
      toggleDonorSettingsModal();
    }
  };

  const handleClick = () => {
    if (currentAppointment.reservation?.user !== null) {
      if(currentAppointment.status === 'need_confirmation' || currentAppointment.status === 'did_attend' || currentAppointment.status === 'did_not_attend'){
        handleOpenDonorDetails(currentAppointment.reservation.user.id, currentAppointment.reservation);

      } else if (currentAppointment.status === 'confirmed') {
        handleOpenDonorSettings(currentAppointment.reservation);
      }
    }
  };

  return (
    <>
      <TetherComponent
        attachment="top left"
        targetAttachment="middle right"
        constraints={ [
          {
            to: scrollableArea,
            attachment: 'together',
            pin: true
          },
        ] }
        renderTarget={ ref =>
          <div
            ref={ref}
            className="timeslot"
            onClick={handleClick}
          >
            {children}
          </div>
        }
      />
    </>
  );
};

export default TimeSlot;
