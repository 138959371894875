import { createAction } from "redux-actions";
import { SET_ERROR_LOG, REQUEST_FAIL } from "./actionTypes";
import { createErrorLog } from "../../services/fetch";

export const setErrorLog = createAction(SET_ERROR_LOG);
export const requestFail = createAction(REQUEST_FAIL);

export const sendErrorLog = (message, statusCode, apiType = 'firebase', url) => (dispatch) => {
  createErrorLog(`/log/create`, {message, statusCode, apiType, ...(url && {url})})
    .then((res) => {
      return dispatch(setErrorLog(res));
    })
    .catch((err) => dispatch(requestFail(err)));
};
