import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import {
  checkRecurrence,
  checkSingleAppointment
} from '../../services/helpers';

const TimeSlotMenu = (props) => {
  const { 
    toggleSettings, 
    toggleDeleteTimeslotModal, 
    toggleDeleteAppointmentModal,
    toggleBookAppointmentModal,
    toggleBookingCancelModal,
    selectedTimeslot,
    recurrenceRule,
  } = props;
  const { t } = useTranslation();

  const [isLastAppointment, setIsLastAppointment] = useState(!recurrenceRule);
  const [isSingleAppointment, setIsSingleAppointment] = useState(checkSingleAppointment(selectedTimeslot));

  useEffect(() => {
    setIsLastAppointment(!recurrenceRule);
    setIsSingleAppointment(checkSingleAppointment(selectedTimeslot));
  }, [selectedTimeslot, recurrenceRule]);

  return (
    <>
      <ul className="menu-list">
        <li className="menu-item">
          <div className="menu-link" onClick={ toggleSettings }>{t('configuration.editTimeslot')}</div>
        </li>
        <li className="menu-item">
          <div 
            className={`menu-link ${ (isLastAppointment && !isSingleAppointment) ? 
              'disabled' : (isLastAppointment && isSingleAppointment) && '' }`} 
            onClick={ (isLastAppointment && isSingleAppointment) ? 
              toggleDeleteTimeslotModal : toggleDeleteAppointmentModal }>{t('configuration.deleteAppointment')}
          </div>
        </li>
        <li className="menu-item">
          <div className={`menu-link ${(isLastAppointment && isSingleAppointment) && 'disabled'}`} onClick={ toggleDeleteTimeslotModal }>{t('configuration.deleteTimeslot')}</div>
        </li>
        <li className="menu-item">
          <div 
            className={`menu-link ${selectedTimeslot?.appointments.find((app) => app.status === 'pending') ? 'red' : 'disabled'}`}
            onClick={ toggleBookAppointmentModal }>{t('configuration.bookAppointment')}
          </div>
        </li>
        <li className="menu-item">
          <div 
            className={`menu-link ${selectedTimeslot?.appointments.find((app) => app.status === 'confirmed') ? 'red' : 'disabled'}`}
            onClick={ toggleBookingCancelModal }>{t('configuration.cancelBooking')}
          </div>
        </li>
      </ul>
    </>
  );
};

export default TimeSlotMenu;
