import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import PhoneNumber from '../common/FormFields/PhoneNumber';
import Button from '../common/FormFields/Button';
import Header from '../common/Header';

import { changePhone } from '../../redux/auth/actions';

const ChangePhone = (props) => {
  const { changePhone } = props;
  const {t} = useTranslation();

  const [phone, setPhone] = useState('')

  const handleClick = (e) => {
    e.preventDefault();
    changePhone(phone);
  }

  return (
    <div className="page-container">
      <Header />
      <div className="page-content">
        <main className="main auth">
          <div className="auth-content">
            <div className="modal">
              <form action="/" className="form auth-form two-factor">
                <h1 className="auth-title-2">{t('auth.changePhoneNumber')}</h1>
                <div className="auth-body">
                  <div className="form-group">
                    <PhoneNumber phoneChange={setPhone}/>
                  </div>
                </div>
                <div className="auth-footer">
                  <Button
                   btnClassName="btn-primary"
                   id="sign-in-button-change"
                   text={t('auth.confirm')}
                   onClick = {handleClick}
                  />
                </div>
              </form>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  changePhone
}

export default connect(null, mapDispatchToProps)(ChangePhone);
