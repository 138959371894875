import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Textarea = (props) => {
    const {
        id, placeholder, disabled, label, value, changeValue, additionalClass, maxLength=50
    } = props;
    const [textareaValue, setTextareaValue] = useState(value);
    const [isTextareaValid, setTextareaValid] = useState(true);

    const handleChange = (event) => {
        setTextareaValue(event.target.value);
        changeValue(event.target.value);
    };

    const checkValidity = () => {
        setTextareaValid(textareaValue.length > 0 && textareaValue.length <= maxLength);
    };

    const adjustTextareaHeight = (event) => {
            event.target.style.height = 'auto';
            event.target.style.height = `${event.target.scrollHeight}px`;
      };

    const controlClass = isTextareaValid ? 'form-control' : 'form-control has-error';
    const textareaClassName = `textarea ${ additionalClass }`;

    return (
        <div className={controlClass}>
            {label &&
            <label className="form-label" htmlFor={id}>
                {label}
            </label>
            }
            <textarea 
                className={ textareaClassName }
                name="teaxtarea-form"
                id={id}
                placeholder={placeholder}
                disabled={disabled}
                value={textareaValue}
                onChange={handleChange}
                onBlur={() => checkValidity()}
                onInput={adjustTextareaHeight}
                maxLength={maxLength}
            />
        </div>
    );
};

Textarea.propTypes = {
    id: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    additionalClass: PropTypes.string,
    maxLength: PropTypes.number,
};

Textarea.defaultProps = {
    id: '',
    placeholder: '',
    label: '',
    value: '',
    disabled: false,
    additionalClass: '',
    maxLength: 50,
};


export default Textarea;

