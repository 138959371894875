import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Header from '../common/Header';
import TwoFatcorForm from './ChangePhoneEnterCode';
import routes from '../../constants/routes';

const ChangePhoneTwoFactor = (props) => {
  const {resendCount} = props;
  const { t } = useTranslation();

  return (
    <div className="page-container">
      <Header />
      <div className="page-content">
        <main className="main auth">
          <div className="auth-content">
            <div className="modal">
              <TwoFatcorForm link={routes.CHANGE_PHONE_SUCCESS_ROUTE}/>
            </div>
            { resendCount <= 1 ? (<div className="auth-subinfo">
              {t('auth.resentCode')}&nbsp;
              <Link to={ routes.CHANGE_PHONE_CODE_RESENT_ROUTE } className="link underlined" id="resend-link-mobile">{t('auth.here')}</Link>
            </div>) : null}
          </div>
        </main>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  resendCount: state.authReducer.resendCount
})


export default connect(mapStateToProps)(ChangePhoneTwoFactor);
