import { connect } from 'react-redux';
import Logo from './Logo';
import {
  resetSchedule
} from '../../../redux/reservations/actions';

const mapStateToProps = (state) => ({
  bloodbankName: state.authReducer.bloodbankName,
  currentBloodbank: state.authReducer.currentBloodbank,
  currentDate: state.reservationReducer.currentDate,
});

const mapDispatchToProps = {
  resetSchedule,
};


export default connect(mapStateToProps, mapDispatchToProps)(Logo);