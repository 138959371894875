import React from 'react';
import Dashboard from '../Dashboard';

const Week = () => {
  return (
    <Dashboard>
      Week
    </Dashboard>
  );
};

export default Week;
