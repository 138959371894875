import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import CustomSelect from '../common/FormFields/Select';
import Button from '../common/FormFields/Button';
import Header from '../common/Header';
import { createSelectOptions } from './helpers';

export const ChangeBloodbank = (props) => {
    const { 
      handleSetCurrentBloodbank, 
      allBloodbanks, 
      currentBloodbankId, 
      currentBloodbankName 
    } = props;
    const {t} = useTranslation();
  
    const [bloodbank, setBloodbank] = useState({label: currentBloodbankName, value: currentBloodbankId});
  
    const handleClick = (e) => {
      e.preventDefault();
      handleSetCurrentBloodbank({bloodbankId: bloodbank.value, bloodbankName: bloodbank.label});
    }
  
    return (
      <div className="page-container">
        <Header />
        <div className="page-content">
          <main className="main auth">
            <div className="auth-content">
              <div className="modal">
                <form action="/" className="form auth-form select-bloodbank">
                  <div className="auth-body">
                    <h1 className="auth-title-2">{t('auth.changeBloodbank')}</h1>
                    <div className="form-group">
                        <CustomSelect
                            value={bloodbank}
                            data={createSelectOptions(allBloodbanks)}
                            additionalClassName="xxl"
                            onChange={setBloodbank}
                        />
                    </div>
                  </div>
                  <div className="auth-footer">
                    <Button
                        btnClassName="btn-primary"
                        text={t('auth.confirm')}
                        onClick = {handleClick}
                    />
                  </div>
                </form>
              </div>
            </div>
          </main>
        </div>
      </div>
    );
};