import authCodes from "../constants/authCodes";
import i18n from '../i18n';

export const getLoginValidationMessage = (validationCode) => {
  switch (validationCode) {
    case (authCodes.INVALID_EMAIL):
    case (authCodes.USER_NOT_FOUND):
      return { email: i18n.t('auth.enterCorrectUsername') };
    case (authCodes.WRONG_PASSWORD):
      return { password: i18n.t('auth.enterCorrectPassword') };
    default:
      return { email: 'Error' };
  }
};

export const getForgotPasswordValidationMessage = (validationCode) => {
  switch (validationCode) {
    case (authCodes.INVALID_EMAIL):
    case (authCodes.USER_NOT_FOUND):
      return i18n.t('auth.emailNotExist');
    default:
      return 'Error';
  }
};

export const getNewPasswordValidationMessage = (validationCode) => {
  switch (validationCode) {
    case (authCodes.WRONG_PASSWORD):
      return { oldPasswordError: i18n.t('auth.incorrectPassword') };
    default:
      return { oldPasswordError: 'Error' };
  }
};

export const passwordValidationError = (password) => {
  const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,}$/;
  return password.match(regex) ? '' : i18n.t('auth.invalidPassword');
};

export const duplicatePasswordsError = (password1, password2) => {
  return password1 === password2 ? i18n.t('auth.identicalPassword') : '';
};

export const timeslotBloodTypeValidationMessage = (data) => {
  const { donorType } = data;
  return (!donorType || donorType.length <= 0) ? i18n.t('configuration.settings.selectBloodType') : '';
};

export const timeslotStartTimeValidationMessage = (data) => {
  const { startDate, endDate } = data;
  return endDate < startDate ? i18n.t('configuration.settings.shouldBeBeforeEnd') : '';
};

export const timeslotEndTimeValidationMessage = (data, duration) => {
  const { startDate, endDate } = data;
  return endDate < startDate
    ? i18n.t('configuration.settings.shouldBeAfterStart')
    : Math.abs(endDate - startDate) < duration ? i18n.t('configuration.settings.shouldBeGraterThanDuration') : '';
};

export const timeslotTimeDifferenceValidationMessage = (data) => {
  const { startDate, endDate } = data;
  return endDate < startDate ? i18n.t('configuration.settings.shouldBeLessThanTheEndTime') : '';
};

export const timeslotTimeDurationValidationMessage = (data, duration) => {
  const { startDate, endDate } = data;
  return endDate >= startDate && Math.abs(endDate - startDate) < duration
    ? i18n.t('configuration.settings.shouldNotBeLessThanDuration')
    : '';
};
