import React, { useRef, createContext } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import 'react-perfect-scrollbar/dist/css/styles.css';

export const ScrollBarContext = createContext();

const CustomScrollbar = (props) => {
  const { children, ...restProps } = props;
  const scrollBarRef = useRef(null);

  const updateScroll = () => {
    if (scrollBarRef.current) {
      setTimeout(() => {
        scrollBarRef.current.updateScroll();
      }, 250);
    }
  };

  return (
    <PerfectScrollbar
      ref={scrollBarRef}
      options={{
        suppressScrollX: true,
      }}
      {...restProps}
    >
      <ScrollBarContext.Provider value={updateScroll}> 
        {children}
      </ScrollBarContext.Provider>      
    </PerfectScrollbar>
  );
};

export default CustomScrollbar;
