import React from 'react';

import Auth from '../index';

const ForgotPasswordSent = () => {
  const formClassName = 'form auth-form forgot-password';

  return (
    <Auth>
      <div className="modal">
        <div className={ formClassName }>
          <span className="auth-success-email icon-email" />
          <p className="auth-success-msg auth-title-2">To proceed please check your&nbsp;email with instructions</p>
        </div>
      </div>
    </Auth>
  );
};

export default ForgotPasswordSent;
