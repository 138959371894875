import { connect } from 'react-redux';
import Dashboard from './Dashboard';
import {
  changeFilter,
  getDailyReservations,
  getPrevReservations,
  getNewUsers,
  setAppTypeFilter,
  setBloodTypeFilter,
  setDonorStatusFilter,
  setTiterFilter,
  setDay,
  setMonth,
  changeCurrentDate
} from '../../redux/reservations/actions';
import { getAppointmentTypes } from '../../redux/appointmentTypes/actions';
import { sendErrorLog } from '../../redux/logging/actions';

const mapStateToProps = (state) => ({
  currentDay: state.reservationReducer.currentDay,
  currentMonth: state.reservationReducer.currentMonth,
  currentDate: state.reservationReducer.currentDate,
  needRefresh: state.reservationReducer.needRefresh,
  appTypeFilter: state.reservationReducer.appTypeFilter,
  bloodTypeFilter: state.reservationReducer.bloodTypeFilter,
  donorStatusFilter: state.reservationReducer.donorStatusFilter,
  titerFilter: state.reservationReducer.titerFilter,
  currentBloodbank: state.authReducer.currentBloodbank,
  isDayViewFetching: state.reservationReducer.isDayViewFetching,
  isMonthViewFetching: state.reservationReducer.isMonthViewFetching,
  isConfigurationFetching: state.reservationReducer.isConfigurationFetching,
  isSidebarFetching: state.reservationReducer.isSidebarFetching,
  dailyReservations: state.reservationReducer.dailyReservations,
  newUsers: state.reservationReducer.newUsers,
  appointmentTypes: state.appointmentTypesReducer.appointmentTypes,
});

const mapDispatchToProps = {
  changeFilter,
  getDailyReservations,
  getPrevReservations,
  getNewUsers,
  setAppTypeFilter,
  setBloodTypeFilter,
  setDonorStatusFilter,
  setTiterFilter,
  getAppointmentTypes,
  sendErrorLog,
  setDay,
  setMonth,
  changeCurrentDate
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
