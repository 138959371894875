import React, { useEffect } from 'react';

import Modal from '../../common/Modal';
import Loader from '../../common/Loader';
import Button from '../../common/FormFields/Button';
import { useTranslation } from 'react-i18next';

const ResourceDeleteModal = (props) => {
  const {
    checkIsResourceEmpty,
    resourceDeleteModalIsOpened,
    toggleDeleteResourceModal,
    isResourceEmpty,
    deleteResource,
    bloodBankId,
    selectedResource,
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    if(resourceDeleteModalIsOpened) {
      checkIsResourceEmpty(bloodBankId, selectedResource?.resourceId);
    }
  }, [resourceDeleteModalIsOpened]);

  const handleDelete = () => {
    deleteResource(bloodBankId, selectedResource?.resourceId);
    toggleDeleteResourceModal();
  }
  return (
    <>
      <Modal isModalOpen={ resourceDeleteModalIsOpened } handleModalClose={ toggleDeleteResourceModal } { ...props }>
        <div className="modal-header">
          <h2 className="modal-title">{t('configuration.resourceDeleteModal.header')}</h2>
          <button className="modal-close" onClick={ toggleDeleteResourceModal }>
            <span className="icon-close" />
          </button>
        </div>
        <div className="modal-body modal-content">
          {
            isResourceEmpty === null ?
            <Loader /> :
              isResourceEmpty === false ? 
              <div className="modal-msg">
                {t('configuration.resourceDeleteModal.body1')}
              </div> :
                <div className="modal-msg">
                  {t('configuration.resourceDeleteModal.body2')}
                </div>
          }
        </div>
        <div className="modal-footer light">
          <div className="inputs-group">
            <div className="inputs-group-item">
              <Button type="button" text={t('common.cancel')} onClick={ toggleDeleteResourceModal } />
            </div>
            <div className="inputs-group-item">
              <Button 
                btnClassName='btn-primary'
                type="button"
                disabled={!isResourceEmpty}
                text={t('configuration.resourceDeleteModal.delete')} 
                onClick={ handleDelete } 
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ResourceDeleteModal;
