import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const TwoFactorResentBody = (props) => {
  const { t } = useTranslation();
  const formClassName = 'form auth-form two-factor';
  const { link } = props;

  return (
    <div className="modal">
      <div className={formClassName}>
        <span className="auth-success icon-code"/>
        <p className="auth-success-msg auth-title-2">{t('auth.codeWasResent')}</p>
        <div className="auth-footer">
          <Link to={ link } className="btn-primary">{t('auth.enterCode')}</Link>
        </div>
      </div>
    </div>
  );
};

export default TwoFactorResentBody;
