import React from 'react';

import Auth from '../index';
import SuccessState from './TwoFactorResentBody';
import routes from '../../../constants/routes'

const TwoFactorResent = () => {
  return (
    <Auth>
      <SuccessState link={routes.TWO_FACTOR_AUTH_ROUTE}/>
    </Auth>
  );
};

export default TwoFactorResent;
