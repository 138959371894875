export const SET_SYSTEM_MESSAGES = 'SET_SYSTEM_MESSAGES';
export const SET_ADMIN_MESSAGES = 'SET_ADMIN_MESSAGES';
export const SET_SCHEDULED_MESSAGES = 'SET_SCHEDULED_MESSAGES';
export const SET_LAST_ADMIN_MESSAGE_ID = 'SET_LAST_ADMIN_MESSAGE_ID';
export const CREATE_MESSAGE_SUCCESS = 'CREATE_MESSAGE_SUCCESS';
export const REQUEST_FAIL = 'REQUEST_FAIL';
export const TOGGLE_PUSH_MESSAGES_MODAL = 'TOGGLE_PUSH_MESSAGES_MODAL';
export const TOGGLE_FILTER_SETTINGS_MODAL = 'TOGGLE_FILTER_SETTINGS_MODAL';
export const SET_COUPLING_TABLE_DATA = 'SET_COUPLING_TABLE_DATA';
export const SET_SELECTED_FILTERS = 'SET_SELECTED_FILTERS';
export const REFRESH_FILTERS = 'REFRESH_FILTERS';