import i18n from "i18next";
import {
  enTranslation,
  noTranslation,
} from './locales';
import { initReactI18next } from 'react-i18next';

const locales = ['en', 'no'];
const language = localStorage.getItem('lang') || navigator.language;

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslation
    },
    no: {
      translation: noTranslation
    },
  },
  lng: (locales.includes(language) && language),
  fallbackLng: 'en',

  interpolation: {
    escapeValue: false
  }
});

export default i18n;
