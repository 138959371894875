import React, {useEffect, useState} from 'react';

import Password from '../common/FormFields/Password';
import Input from '../common/FormFields/Input';
import Button from '../common/FormFields/Button';
import Header from '../common/Header';
import {withRouter} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import firebase from "firebase/compat/app";

const ChangePassword = (props) => {
  const {
    changePassword,
    location,
    clearAuthValidationErrors,
    fetching,
    changePasswordErrors: { newPasswordError, oldPasswordError } = {}
  } = props;
  const { t } = useTranslation();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  useEffect(() => {
    clearAuthValidationErrors();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    changePassword({oldPassword, newPassword});
  };

  const user = firebase.auth().currentUser;

  const validateFields = () => {
    if (!user?.phoneNumber) {
      return !oldPassword || !newPassword || fetching;
    } else {
      return !newPassword || fetching;
    }
  }

  return (
    <div className="page-container">
      <Header />
      <div className="page-content">
        <main className="main auth">
          <div className="auth-content">
            <div className="modal">
              <form action="/" className="form auth-form">
                <h1 className="auth-title-2">{t('auth.changePassword')}</h1>
                <div className="auth-body">
                  {!user?.phoneNumber ? <div className="form-group">
                    <Input
                        placeholder={t('auth.enterCurrentPassword')}
                        label={t('auth.currentPassword')}
                        onChangeValue={setOldPassword}
                        errorMessage={oldPassword && oldPasswordError}
                        autoComplete="old-password"
                    />
                  </div> : null}
                  <div className="form-group">
                    <Password
                        placeholder={t('auth.enterNewPassword')}
                        label={t('auth.newPassword')}
                        onChangeValue={setNewPassword}
                        errorMessage={newPassword && newPasswordError}
                        autoComplete="new-password"
                    />
                  </div>
                </div>
                <div className="auth-footer">
                  <Button
                    btnClassName="btn-primary"
                    id="sign-in-button-change-pass"
                    text={t('auth.confirm')}
                    onClick={handlePasswordSubmit}
                    disabled={validateFields()}
                  />
                </div>
              </form>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default withRouter(ChangePassword);
