import React, {useState} from 'react';
import * as dateFns from 'date-fns';
import PropTypes from 'prop-types';

import {getTimeIntervals} from '../../services/helpers';

const BodyTimeScale = (props) => {
  const {scroll, range, resources, onTimeCellClick, isSettings, startOfDay, endOfDay} = props;
  const interval = range || 0.5;
  const [availableTimeSlots] = useState(getTimeIntervals());

  const timeScale = () => {
    let time = startOfDay;
    let hours = [];

    const differenceInTime = endOfDay - startOfDay;
    const timeSpots = differenceInTime / interval / (1000 * 60 * 60);
    if (dateFns.compareDesc(time, endOfDay) > 0) {
      for (let i = 0; i < timeSpots; i++) {
        hours.push(
          <div className="schedule-scale-row" key={i}>
            {isSettings && resources.map(resource => {
              return <div
                className="schedule-scale-cell"
                key={`${i}_${resource.resourceId}`}
                onClick={() => {
                  onTimeCellClick({
                    resourceId: resource.resourceId,
                    selectedTime: availableTimeSlots[Math.round(i * 12 * interval)].value,
                  })
                }}
              />;
            })}
          </div>
        );
        time = dateFns.addMinutes(time, interval * 60);
      }
    }
    return hours;
  };

  return (
    <div className="schedule-body-scale" style={{top: `-${scroll}px`}}>
      {timeScale()}
    </div>
  );
};

BodyTimeScale.propTypes = {
  resources: PropTypes.array,
  onTimeCellClick: PropTypes.func,
  startOfDay: PropTypes.number.isRequired,
  endOfDay: PropTypes.number.isRequired,
};

BodyTimeScale.defaultProps = {
  resources: [],
  onTimeCellClick: () => {
  },
};

export default BodyTimeScale;
