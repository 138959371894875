import React, { useState, useEffect } from 'react';
import Header from '../common/Header';
import Loader from '../common/Loader';
import Table from '../common/Table';
import CustomScrollbar from '../common/CustomScrollbar';
import { prepareRowData } from './helpers';
import {
    COUPLING_TABLE_SETUP,
} from '../../constants/couplingTable';
// import { 
//     listenAdminMessages,
// } from "../../services/firestoreServices";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';


export const CouplingTable = (props) => {
    const {
        currentBloodbank,
        getScheduledMessages,
    } = props;
    const { t } = useTranslation();

    const [isTableLoading, setIsTableLoading] = useState(false);


    return (
        <div className="page-container">
        <Header />
            <div className="page-content">
            <main className="main">
                <CustomScrollbar>
                    <div className="container-fluid content">

                    {   isTableLoading ? 
                        <Loader /> :
                        <div className='table-container'>
                            <Table
                                data={[]}
                                setup={COUPLING_TABLE_SETUP}
                                handleRowChoose={() => {}}
                                handleSortBtnPress={() => {}}
                                additionalClass={"table-coupling"}
                            />
                        </div>
                    }
                    </div>
                </CustomScrollbar>
            </main>
            </div>
        </div>
    );
}