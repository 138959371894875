import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as dateFns from "date-fns";

import Header from '../common/Header';
import TwoFatcorPasswordForm from './TwoFatcorPasswordForm';
import routes from '../../constants/routes';

const ChangePasswordTwoFactor = (props) => {
  const {resendCount} = props;
  const { t } = useTranslation();

  const dateFormat = "yyyy-MM-dd";
  const formattedDate = dateFns.format(new Date(), dateFormat);

  return (
    <div className="page-container">
      <Header />
      <div className="page-content">
        <main className="main auth">
          <div className="auth-content">
            <div className="modal">
              <TwoFatcorPasswordForm link={`${routes.DASHBOARD_DAY_ROUTE}/${formattedDate}`}/>
            </div>
            { resendCount <= 1 ? (<div className="auth-subinfo">
              {t('auth.resentCode')}&nbsp;
              <Link to={ routes.CHANGE_PASSWORD_CODE_RESENT_ROUTE } className="link underlined" id="resend-link-mobile">{t('auth.here')}</Link>
            </div>) : null}
          </div>
        </main>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  resendCount: state.authReducer.resendCount
})


export default connect(mapStateToProps)(ChangePasswordTwoFactor);
