import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

const Checkbox = (props) => {
    const {
        label, disabled, checked, checkboxHandler, onChangeValue, isEditable
    } = props;
    const [isChecked, setIsChecked] = useState(checked);

    const changeHandler = () => {
        if (isEditable) {
            setIsChecked(!isChecked);
            checkboxHandler();
        }
    };

    useEffect(() => {
        onChangeValue(isChecked);
    }, [isChecked, onChangeValue]);
    
    useEffect(() => {
        setIsChecked(checked)
    }, [checked]);

    const checkboxClass = disabled ? 'checkbox disabled' : 'checkbox';

    return (
        <label className={checkboxClass}>
            <input
                type="checkbox"
                className="checkbox-input visually-hidden"
                disabled={disabled}
                checked={isChecked}
                onChange={changeHandler}
            />
            <div className="checkbox-mark"/>
            {label && <div className="checkbox-label">{label}</div>}
        </label>
    );
};

Checkbox.propTypes = {
    label: PropTypes.string,
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
    onChangeValue: PropTypes.func,
    checkboxHandler: PropTypes.func,
    isEditable: PropTypes.bool,
};

Checkbox.defaultProps = {
    label: '',
    disabled: false,
    checked: false,
    onChangeValue: () => {},
    checkboxHandler: () => {},
    isEditable: true,
};


export default Checkbox;
