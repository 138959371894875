import React from 'react';
import Nav from '../../common/Nav';
import Logo from '../../common/Logo';
import Profile from './ProfileContainer';
import Language from './LanguageSelect';

const Header = () => {

  return (
    <header className="header">
      <Logo additionalClassName="header-logo"/>
      <Nav />
      <Language />
      <Profile />
    </header>
  );
};

export default Header;
