import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import routes from './constants/routes';

const ProtectedRoute = ({ component: Component, idToken, roleName, ...rest }) => (
  <Route {...rest} render={(props) => (
    idToken
      ? ((roleName === 'admin')
        ? <Component {...props} />
        : <Redirect
            to={{
              pathname: routes.INDEX,
            }}
          />)
      : <Redirect
          to={{
            pathname: routes.LOGIN_ROUTE,
          }} 
      />
    )}
  />
);

const mapStateToProps = (state) => ({
  idToken: state.authReducer.idToken,
  roleName: state.authReducer.roleName,
});

export default connect(mapStateToProps)(ProtectedRoute);
