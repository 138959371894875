import * as dateFns from 'date-fns';
import { enUS, nb } from 'date-fns/locale';
import i18n from '../../i18n';
import {
    MESSAGES_HEADINGS
} from '../../constants/pushMessages';

export const prepareRowData = (currentMsg) => {
    const locale = (i18n.language === 'no') ? nb : enUS;
    if (currentMsg) {
        return {
            [MESSAGES_HEADINGS.MESSAGE_ID]: currentMsg.id || '-',
            ...(!currentMsg.admin && {
                [MESSAGES_HEADINGS.RECEIVER_ID]: currentMsg.userId || currentMsg.topic || '-',
            }),
            [MESSAGES_HEADINGS.DATE]: currentMsg.timestamp._seconds ?
                dateFns.format(new Date(currentMsg.timestamp._seconds * 1000), 'd MMMM yyyy HH:mm', {locale}) :
                '-',
            [MESSAGES_HEADINGS.TITLE]: currentMsg.title || '-',
            [MESSAGES_HEADINGS.MESSAGE]: currentMsg.body || '-',
            ...(currentMsg.admin && {
                [MESSAGES_HEADINGS.SIGNATURE]: currentMsg.admin.signatureType || '-',
            }),
            ...(currentMsg.userIds && {
                [MESSAGES_HEADINGS.CANDIDATE]: currentMsg.userIds.length || '-',
            }),
        }
    } 
};