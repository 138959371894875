import { connect } from 'react-redux';
import { handleSetCurrentBloodbank } from '../../redux/auth/actions';
import { ChangeBloodbank } from './ChangeBloodbank';

const mapDispatchToProps = {
  handleSetCurrentBloodbank,
}

const mapStateToProps = (state) => ({
  allBloodbanks: state.authReducer.allBloodbanks,
  currentBloodbankId: state.authReducer.currentBloodbank,
  currentBloodbankName: state.authReducer.bloodbankName,
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeBloodbank);
