import React from 'react';
import Dashboard from '../Dashboard';
import Calendar from '../Dashboard/CalendarContainer';

const Month = () => {

  return (
    <Dashboard>
      <Calendar />
    </Dashboard>
  );
};

export default Month;
