import { createAction } from 'redux-actions';
import * as appointmentTypesActions from './actionTypes';
import { get } from '../../services/fetch';

export const getAppointmentTypesRequest = createAction(appointmentTypesActions.GET_APPOINTMENT_TYPES_REQUEST);
export const getAppointmentTypesSuccess = createAction(appointmentTypesActions.GET_APPOINTMENT_TYPES_SUCCESS);
export const getAppointmentTypesFailure = createAction(appointmentTypesActions.GET_APPOINTMENT_TYPES_FAILURE);

export const getAppointmentTypes = () => dispatch => {
  dispatch(getAppointmentTypesRequest());
  get(`/appointments/types`)
    .then(res => dispatch(getAppointmentTypesSuccess(res.content)))
    .catch(err => dispatch(getAppointmentTypesFailure(err)));
};
