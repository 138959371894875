import React, {useState, useEffect} from 'react';

import Modal from '../../common/Modal';
import Button from '../../common/FormFields/Button';
import Input from '../../common/FormFields/Input';

import { useTranslation } from 'react-i18next';

const ResourceEditModal = (props) => {
  const {
    resourceEditModalIsOpened,
    toggleEditResourceModal,
    editResource,
    bloodBankId,
    selectedResource,
  } = props;
  const { t } = useTranslation();
  const [resourceName, setResourceName] = useState(selectedResource?.pseudoName);

  const handleEdit = () => {
    editResource(bloodBankId, selectedResource?.resourceId, resourceName);
    toggleEditResourceModal();
  }
  return (
    <>
      <Modal isModalOpen={ resourceEditModalIsOpened } handleModalClose={ toggleEditResourceModal } { ...props }>
        <div className="modal-header">
          <h2 className="modal-title">{t('configuration.resourceEditModal.header')}</h2>
          <button className="modal-close" onClick={ toggleEditResourceModal }>
            <span className="icon-close" />
          </button>
        </div>
        <div className="modal-body modal-content">
          <div className="modal-msg form-group">
            {t('configuration.resourceEditModal.body')}
          </div>
          <div className="inputs-group form-group">
            <div className="inputs-group-item">
              <Input
                type="text"
                initValue={ selectedResource?.pseudoName }
                label={ t('configuration.resourceEditModal.input') }
                onChangeValue={ setResourceName }
              />
            </div>
          </div>
        </div>
        <div className="modal-footer light">
          <div className="inputs-group">
            <div className="inputs-group-item">
              <Button type="button" text={t('common.cancel')} onClick={ toggleEditResourceModal } />
            </div>
            <div className="inputs-group-item">
              <Button 
                btnClassName='btn-primary'
                type="button"
                text={ t('configuration.resourceEditModal.edit') } 
                onClick={ handleEdit } 
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ResourceEditModal;
