import { connect } from 'react-redux';
import {
    editResource,
    toggleEditResourceModal,
} from "../../../redux/reservations/actions";
import ResourceEditModal from "./ResourceEditModal";

const mapStateToProps = (state) => ({
    resourceEditModalIsOpened: state.reservationReducer.resourceEditModalIsOpened,
    bloodBankId: state.authReducer.currentBloodbank,
    selectedResource : state.reservationReducer.selectedResource,
});

const mapDispatchToProps = {
    editResource,
    toggleEditResourceModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResourceEditModal);
