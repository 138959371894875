import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import CheckItem from './CheckItem';

const CheckboxGroup = (props) => {
  const {
    id, label, data, onCheck,
  } = props;

  const [checkboxGroupData, setCheckboxGroupData] = useState(data);
  const [checkedItem, setCheckedItem] = useState(false);

  useEffect(() => {
    if (checkedItem) {
      const newData = checkboxGroupData.map(data => data.id === checkedItem.id ? checkedItem : data);
      onCheck(newData);
      setCheckboxGroupData(newData)
    }
  }, [checkedItem])

  return (
    <div className="form-control">
      { label &&
      <span className="form-label" htmlFor={ id }>
        { label }
      </span>
      }
      <div className="checkbox-group">
        { checkboxGroupData.map((item) => (
          <CheckItem data={item} handleCheck={setCheckedItem}/>
        )) }
      </div>
      <div className="form-error">Required</div>
    </div>
  );
};


CheckboxGroup.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      checked: PropTypes.bool,
      disabled: PropTypes.bool,
    })
  ),
  onCheck: PropTypes.func,
};

CheckboxGroup.defaultProps = {
  id: '',
  label: '',
  data: [],
  onCheck: () => {},
};

export default CheckboxGroup;

