import { connect } from 'react-redux';
import DonorSettings from './DonorSettings';
import {
  markDidAttend, 
  markDidNotAttend,
} from '../../../redux/reservations/actions';
import {
  deleteDonorSettings,
  toggleDonorSettingsModal,
} from '../../../redux/users/actions';

const mapDispatchToProps = {
  markDidAttend,
  markDidNotAttend,
  deleteDonorSettings,
  toggleDonorSettingsModal,
}

const mapStateToProps = (state) => ({
  currentBloodbank: state.authReducer.currentBloodbank,
  donorSettingsData: state.usersReducer.donorSettingsData,
  isUserGetError: state.usersReducer.isError,
  isUserPending: state.usersReducer.isPending,
  isModalOpen: state.usersReducer.donorSettingsModalIsOpen,
  userRoleName: state.authReducer.roleName,
})

export default connect(mapStateToProps, mapDispatchToProps)(DonorSettings);
