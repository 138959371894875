import React, { useState } from 'react';
import Header from '../common/Header';
import Loader from '../common/Loader';
import CustomScrollbar from '../common/CustomScrollbar';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';


export const Chart = (props) => {
    const {
        currentBloodbank,
        getAppointmentsAmount,
    } = props;
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);


    return (
        <div className="page-container">
        <Header />
            <div className="page-content">
            <main className="main">
                <CustomScrollbar>
                    <div className="container-fluid content">

                    {   isLoading ? 
                        <Loader /> :
                        <div >
                        </div>
                    }
                    </div>
                </CustomScrollbar>
            </main>
            </div>
        </div>
    );
}