const routes = {
    INDEX: '/',
    LOGIN_ROUTE: '/login',
    FORGOT_PASSWORD_ROUTE: '/forgot-password',
    FORGOT_PASSWORD_EMAIL_SENT_ROUTE: '/forgot-password-email-sent',
    TWO_FACTOR_AUTH_ROUTE: '/two-factor-auth',
    TWO_FACTOR_AUTH_CODE_SENT_ROUTE: '/two-factor-auth-code-sent',
    RESTORE_PASSWORD_ROUTE: '/reset-password',
    CHANGE_PASSWORD_ROUTE: '/change-password',
    CHANGE_PASSWORD_ENTER_CODE: '/change-password-enter-code',
    CHANGE_PASSWORD_CODE_RESENT_ROUTE: '/change-password-code-resent',
    CHANGE_PHONE_ROUTE: '/change-phone',
    CHANGE_PHONE_ENTER_CODE: '/change-phone-enter-code',
    CHANGE_PHONE_SUCCESS_ROUTE: '/change-phone-success',
    CHANGE_PHONE_CODE_RESENT_ROUTE: '/change-phone-code-resent',
    DASHBOARD_DAY_ROUTE: '/day',
    DASHBOARD_WEEK_ROUTE: '/week',
    DASHBOARD_MONTH_ROUTE: '/month',
    CONFIGURATION_ROUTE: '/configuration',
    DONORS_LIST_ROUTE: '/donors-list',
    CHANGE_BLOODBANK_ROUTE: '/change-bloodbank',
    PUSH_MESSAGES_ROUTE: '/push-messages',
    COUPLING_TABLE_ROUTE: '/co-table',
    CHART_ROUTE: '/chart'
};

export default routes;
