import React from 'react';

import Header from '../common/Header';
import SuccessState from '../Authentication/TwoFactorAuth/TwoFactorResentBody';
import routes from "../../constants/routes";

const ChangePasswordResent = () => {

  return (
    <div className="page-container">
      <Header/>
      <div className="page-content">
        <main className="main auth">
          <div className="auth-content">
            <SuccessState link={routes.CHANGE_PASSWORD_ENTER_CODE}/>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ChangePasswordResent;
