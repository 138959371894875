import { connect } from 'react-redux';
import {
    toggleDeleteResourceModal,
    toggleEditResourceModal,
} from "../../redux/reservations/actions";
import ResourcesMenu from "./ResourcesMenu";

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
    toggleDeleteResourceModal,
    toggleEditResourceModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResourcesMenu);
