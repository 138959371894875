import React from 'react';
import {Router, Switch, Route, Redirect} from 'react-router-dom';

import Login from './components/Authentication/Login';
import ForgotPassword from './components/Authentication/ForgotPassword';
import ForgotPasswordEmailSent from './components/Authentication/ForgotPasswordEmailSent';
import RestorePassword from './components/Authentication/RestorePassword';
import TwoFactorAuth from './components/Authentication/TwoFactorAuth';
import TwoFactorResent from './components/Authentication/TwoFactorAuth/TwoFactorResent';
import ChangePhone from './components/ChangePhone';
import ChangePhoneTwoFactor from './components/ChangePhone/ChangePhoneTwoFactor';
import ChangePhoneSuccess from './components/ChangePhone/ChangePhoneSuccess';
import ChangePhoneResent from './components/ChangePhone/ChangePhoneResent';
import DashboardDay from './components/Day';
import DashboardWeek from './components/Week';
import DashboardMonth from './components/Month';
import DonorsList from './components/DonorsList';
import Configuration from './components/Configuration';
import ChangePassword from './components/ChangePassword';
import ChangePasswordTwoFactor from './components/ChangePassword/ChangePasswordTwoFactor';
import ChangePasswordResent from './components/ChangePassword/ChangePasswordResent';
import PushMessages from './components/PushMessages';
import CouplingTable from './components/CouplingTable';
import ChangeBloodbank from './components/ChangeBloodbank';
import Chart from './components/Chart';
import history from './services/history';
import routes from './constants/routes';
import PrivateRoute from './PrivateRoute';
import ProtectedRoute from './ProtectedRoute';

const MainRouter = () => (
    <Router history={history}>
        <Switch>
            <Route path={routes.LOGIN_ROUTE} component={Login}/>
            <Route path={routes.FORGOT_PASSWORD_ROUTE} component={ForgotPassword}/>
            <Route path={routes.FORGOT_PASSWORD_EMAIL_SENT_ROUTE} component={ForgotPasswordEmailSent}/>
            <Route path={routes.RESTORE_PASSWORD_ROUTE} component={RestorePassword}/>
            <Route path={routes.TWO_FACTOR_AUTH_ROUTE} component={TwoFactorAuth}/>
            <Route path={routes.TWO_FACTOR_AUTH_CODE_SENT_ROUTE} component={TwoFactorResent}/>
            <PrivateRoute path={`${routes.PUSH_MESSAGES_ROUTE}/:type`} component={PushMessages}/>
            <ProtectedRoute path={routes.CHART_ROUTE} component={Chart}/>
            <ProtectedRoute path={routes.COUPLING_TABLE_ROUTE} component={CouplingTable}/>
            <PrivateRoute path={`${routes.DASHBOARD_DAY_ROUTE}/:date`} component={DashboardDay}/>
            <PrivateRoute path={routes.DASHBOARD_WEEK_ROUTE} component={DashboardWeek}/>
            <PrivateRoute path={`${routes.DASHBOARD_MONTH_ROUTE}/:date`} component={DashboardMonth}/>
            <PrivateRoute path={routes.DONORS_LIST_ROUTE} component={DonorsList}/>
            <ProtectedRoute path={`${routes.CONFIGURATION_ROUTE}/:date`} component={Configuration}/>
            <PrivateRoute path={routes.CHANGE_BLOODBANK_ROUTE} component={ChangeBloodbank}/>
            <PrivateRoute path={routes.CHANGE_PASSWORD_ROUTE} component={ChangePassword}/>
            <PrivateRoute path={routes.CHANGE_PASSWORD_ENTER_CODE} component={ChangePasswordTwoFactor}/>
            <PrivateRoute path={routes.CHANGE_PASSWORD_CODE_RESENT_ROUTE} component={ChangePasswordResent}/>
            <PrivateRoute path={routes.CHANGE_PHONE_ROUTE} component={ChangePhone}/>
            <PrivateRoute path={routes.CHANGE_PHONE_ENTER_CODE} component={ChangePhoneTwoFactor}/>
            <PrivateRoute path={routes.CHANGE_PHONE_CODE_RESENT_ROUTE} component={ChangePhoneResent}/>
            <PrivateRoute path={routes.CHANGE_PHONE_SUCCESS_ROUTE} component={ChangePhoneSuccess}/>
            <PrivateRoute path={routes.INDEX} component={DashboardDay}/>
        </Switch>
    </Router>
);

export default MainRouter;
