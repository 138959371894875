import { connect } from 'react-redux';
import { toggleBookAppointmentModal } from "../../../redux/timeslots/actions";
import { getUserByPnr } from "../../../redux/users/actions";
import { bookAppointment } from "../../../redux/reservations/actions";
import BookAppointment from "./BookAppointment";

const mapStateToProps = (state) => ({
  selectedTimeslot: state.timeslotsReducer.selectedTimeslot,
  bookAppointmentModalIsOpen: state.timeslotsReducer.bookAppointmentModalIsOpen,
  userByPnr: state.usersReducer.userByPnr,
  bookedReservations: state.timeslotsReducer.bookedReservations,
  bloodBankId: state.authReducer.currentBloodbank,
});

const mapDispatchToProps = {
  toggleBookAppointmentModal,
  getUserByPnr,
  bookAppointment,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookAppointment);
