import React from 'react';
import PropTypes from 'prop-types';

import Accordion from '../common/Accordion';
import CustomScrollbar from '../common/CustomScrollbar';
import OutsideClickHandler from '../common/OutsideClickHandler';
import Loader from '../common/Loader';
import { useTranslation } from 'react-i18next';
import * as dateFns from 'date-fns';
import { enUS, nb } from 'date-fns/locale';
import i18n from '../../i18n';

const Sidebar = (props) => {
  const {
    isOpen,
    toggleSidebar,
    dailyReservations,
    newUsers,
    toggleSidebarClose,
    isSidebarFetching,
    getDataForDonorDetails,
    getDataForDonorSettings,
    toggleDonorDetailsModal,
    toggleDonorSettingsModal,
  } = props;
  const { t } = useTranslation();
  const locale = (i18n.language === 'no') ? nb : enUS;

  const sidebarClassName = isOpen ? 'side is-open' : 'side';

  const handleOpenDonorDetails = (id, details) => {
      getDataForDonorDetails(id, details);
      toggleDonorDetailsModal();
  };

  const handleOpenDonorSettings = (data) => {
    if (data) {
      getDataForDonorSettings(data.userId, data);
      toggleDonorSettingsModal();
    }
  };

  const getUsersLength = () => {
    let usersLength = 0;

    if (dailyReservations !== undefined) {
      dailyReservations.map(() => (
        usersLength++
      ));
    }

    if (newUsers !== undefined) {
      newUsers.map(() => (
        usersLength++
      ));
    }

    return usersLength;
  };

  const sidebarTriggerClassName = getUsersLength() ? 'side-trigger' : 'side-trigger disabled';

  const renderNewRegistrations = newUsers !== undefined ?
  <section className="side-section">
    <Accordion title={`${t('dashboard.signups')} (${newUsers.length})`}
      isAccordionOpen={newUsers.length > 0}
      disabled={newUsers.length < 0}
    >
      <ul className="visitors">
        { 
          newUsers
          .toSorted((a, b) => a.registered._seconds - b.registered._seconds)
          .map(user => {
            return <li className="visitors-item" key={`${user.id}`}>
              <div role="button" className="visitors-btn" 
                onClick={() => {
                  handleOpenDonorDetails(user.id, {});
                }}>
                {user && user.registered ? dateFns.format( new Date(user.registered._seconds*1000), 'dd MMMM yyyy', {locale}) : 'no data'}
              </div>
            </li>
          })
        }
      </ul>
    </Accordion>
  </section> : null;

  const renderAccordion = dailyReservations !== undefined ?
    <section className="side-section">
      <Accordion title={`${t('dashboard.attendance')} (${dailyReservations.length})`}
                 isAccordionOpen={dailyReservations.length > 0}
                 disabled={dailyReservations.length < 0}
      >
        <ul className="visitors">
          {
            dailyReservations
            .toSorted((a, b) => a.startDate - b.startDate)
            .map(reservation => {
              return <li className="visitors-item" key={`${reservation.reservationId}`}>
                <div role="button" className="visitors-btn" 
                  onClick={() => {
                    handleOpenDonorSettings(reservation);
                  }}>
                  {reservation ? dateFns.format( new Date(reservation.startDate), 'dd MMMM yyyy HH:mm', {locale}) : null}
                </div>
              </li>
            })
          }
        </ul>
      </Accordion>
    </section> : null;

  return (
    <aside className={sidebarClassName}>
      <OutsideClickHandler handleClickOutside={toggleSidebarClose}>
      <div className={sidebarTriggerClassName} onClick={toggleSidebar}>
        <i className="btn-icon icon-notification-active"/>
        {t('dashboard.tasks')} {getUsersLength()}
        <i className="btn-icon icon-arrow"/>
      </div>

      
      <div className="side-inner">
      {(!isSidebarFetching) ?
          <CustomScrollbar
            className="side-content perfect-scrollbar flex-grow-1"
          >
            {renderNewRegistrations}
            {renderAccordion}
          </CustomScrollbar>
        
         : <Loader /> }
         </div>
      </OutsideClickHandler>
    </aside>
  );
};

Sidebar.propTypes = {
  dailyReservations: PropTypes.array,
  newUsers: PropTypes.array,
};

export default Sidebar;
