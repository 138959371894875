export const CREATE_TIMESLOT_REQUEST = 'CREATE_TIMESLOT_REQUEST';
export const CREATE_TIMESLOT_SUCCESS = 'CREATE_TIMESLOT_SUCCESS';
export const CREATE_TIMESLOT_FAILURE = 'CREATE_TIMESLOT_FAILURE';
export const TOGGLE_TIMESLOT_SETTINGS = 'TOGGLE_TIMESLOT_SETTINGS';
export const SET_SELECTED_TIMESLOT = 'SET_SELECTED_TIMESLOT';
export const SET_SELECTED_RESOURCE = 'SET_SELECTED_RESOURCE';
export const CLEAR_VALIDATION_ERRORS = 'CLEAR_VALIDATION_ERRORS';
export const HIDE_MENUS = 'HIDE_MENUS';
export const SET_MENU_IS_OPENED = 'SET_MENU_IS_OPENED';
export const SET_RECURRENCE_RULE = 'SET_RECURRENCE_RULE';
export const DELETE_RECURRENCE_RULE = 'DELETE_RECURRENCE_RULE';
export const TOGGLE_DELETE_TIMESLOT_MODAL = 'TOGGLE_DELETE_TIMESLOT_MODAL';
export const DELETE_TIMESLOT_SUCCESS = 'DELETE_TIMESLOT_SUCCESS';
export const DELETE_TIMESLOT_FAILURE = 'DELETE_TIMESLOT_FAILURE';
export const SET_RESERVATIONS_EXISTS = 'SET_RESERVATIONS_EXISTS';
export const FETCH_TIMESLOTS = 'FETCH_TIMESLOTS';
export const AFTER_SELECT = 'AFTER_SELECT';
export const SET_EXCEPTION_RULES = 'SET_EXCEPTION_RULES';
export const SET_REXCEPTION_DATES = 'SET_REXCEPTION_DATES';
export const SET_REXCEPTION_HOURS_ON_DATES = 'SET_REXCEPTION_HOURS_ON_DATES';
export const DELETE_ALL_EXCEPTIONS = 'DELETE_ALL_EXCEPTIONS';
export const TOGGLE_DELETE_APPOINTMENT_MODAL = 'TOGGLE_DELETE_APPOINTMENT_MODAL';
export const TOGGLE_BOOK_APPOINTMENT_MODAL = 'TOGGLE_BOOK_APPOINTMENT_MODAL';
export const TOGGLE_BOOKING_CANCEL_MODAL = 'TOGGLE_BOOKING_CANCEL_MODAL';
export const SET_APPOINTMENTS_AMOUNT = 'SET_APPOINTMENTS_AMOUNT';
