import { connect } from 'react-redux';
import {
  toggleBookingCancelModal
} from "../../../redux/timeslots/actions";
import {
  cancelAppointmentBooking
} from "../../../redux/reservations/actions";
import CancelBooking from "./CancelBooking";

const mapStateToProps = (state) => ({
  bloodbankId: state.authReducer.currentBloodbank,
  bloodbankName: state.authReducer.bloodbankName,
  selectedTimeslot: state.timeslotsReducer.selectedTimeslot,
  bookingCancelModalIsOpen: state.timeslotsReducer.bookingCancelModalIsOpen,
});

const mapDispatchToProps = {
  toggleBookingCancelModal,
  cancelAppointmentBooking
};

export default connect(mapStateToProps, mapDispatchToProps)(CancelBooking);
