import React from 'react';
import * as dateFns from 'date-fns';
import PropTypes from 'prop-types';

const TimeScale = (props) => {
  const { scroll, range, startOfDay, endOfDay } = props;
  const dateFormat = "HH:mm";
  const interval = range || 0.5;

  const timeScale = () => {
    let time = startOfDay;
    let hours = [];
    let formattedDate = "";

    const differenceInTime = endOfDay - startOfDay;
    const timeSpots = differenceInTime / interval / (1000 * 60 * 60);
    if (dateFns.compareDesc(time, endOfDay) > 0) {
      for (let i = 0; i < timeSpots; i++) {
        formattedDate = dateFns.format(time, dateFormat);
        hours.push(
          <div className="schedule-scale-row" key={ i }>
            <div className="schedule-scale-time">
              { formattedDate }
            </div>
          </div>
        );
        time = dateFns.addMinutes(time, interval * 60);
      }
    }
    return hours;
  };

  return (
    <div className="schedule-scale">
      <div className="schedule-scale-inner" style={ { top: `-${scroll}px` } }>
        { timeScale() }
      </div>
    </div>
  );
};

TimeScale.propTypes = {
  startOfDay: PropTypes.number.isRequired,
  endOfDay: PropTypes.number.isRequired,
};

export default TimeScale;
