import React, { useState } from 'react';
import Select from "react-select";
import {components} from 'react-select';
import {LANGUAGE} from "../../../constants/languages";
import CustomScrollbar from '../CustomScrollbar';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';

const Language = (props) => {
  const {
    placeholder,
    onMenuOpen,
    onMenuClose,
  } = props;

  const [language, setLanguage] = useState(
    LANGUAGE.find(lang => lang.value === i18n.language) || LANGUAGE[0]
  );
  const setCurrentLanguage = (data) => {
    setLanguage(data);
    i18n.changeLanguage(data.value);
    localStorage.setItem('lang', data.value);
  };

  const handleCloseMenuOnScroll = (e) => {
    const target = e.target;
    const scrollableElement = target.parentElement;

    return !(scrollableElement && (scrollableElement.classList.contains('react-select__menu')));
  };
  const renderSelectMenu = (data) => (
    <CustomScrollbar className="perfect-scrollbar react-select__menu-list">{data.children}</CustomScrollbar>
  );

  const renderOption = props => {
    const {icon, label} = props.data;
    const iconClassName = `select-option-icon ${icon}`;

    return (
      <components.Option {...props}>
        <span className={iconClassName}/>
        <span className="select-option-label">{label}</span>
      </components.Option>)
  };
  const renderValue = ({children, ...props}) => {
    const {hasValue} = props;
    if (!hasValue) {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    } else {
      const {icon} = props.selectProps.value;
      const iconClassName = `select-option-icon ${icon}`;
      return (
        <components.ValueContainer {...props}>
          <span className={iconClassName}/>
          {children}
        </components.ValueContainer>)
    }
  };


  return (
    <div className="language">
      <div className="form-field flat">
        <Select
          className="select"
          options={LANGUAGE}
          value={language}
          classNamePrefix="react-select"
          placeholder={placeholder || 'Select'}
          isSearchable={false}
          menuPortalTarget={document.body}
          menuPosition="fixed"
          menuPlacement="auto"
          closeMenuOnScroll={handleCloseMenuOnScroll}
          components={{
            MenuList: renderSelectMenu,
            Option: (optionProps) => renderOption(optionProps),
            ValueContainer: (optionProps) => renderValue(optionProps),
          }}
          styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          onChange={setCurrentLanguage}
          {...props}
        />
      </div>
    </div>
  );
};


Language.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      icon: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ),
  isDisabled: PropTypes.bool,
};

Language.defaultProps = {
  label: '',
  placeholder: 'Select value',
  data: [],
  isDisabled: false,
};

export default Language;
