import { connect } from 'react-redux';
import {
    setResourceMenuIsOpen,
    setSelectedResource,
} from "../../redux/reservations/actions";
import SingleResource from "./SingleResource";

const mapStateToProps = (state) => ({
    resourceMenuIsOpened: state.reservationReducer.resourceMenuIsOpened,
    calendar : state.reservationReducer.calendar,
});

const mapDispatchToProps = {
    setResourceMenuIsOpen,
    setSelectedResource,
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleResource);
