import { handleActions } from 'redux-actions';
import { 
  requestFail,
  requestPending,
  setUsers,
  userByPnr,
  getDonorDetails,
  deleteDonorDetails,
  getDonorSettings,
  deleteDonorSettings,
  toggleDonorDetailsModal,
  toggleDonorSettingsModal,
  setLastChoosenDonor
} from './actions';

const initialState = {
  users: [],
  usersAmount: 0,
  userByPnr: null,
  donorDetailsData: null,
  donorSettingsData: null,
  isPending: false,
  isError: false,
  error: null,
  isToastErrorShown : false,
  donorDetailsModalIsOpen: false,
  donorSettingsModalIsOpen: false,
  lastChoosenDonor: null
};

const usersReducer = handleActions({
  [requestFail]: (state, action) => ({
    ...state,
    isPending: false,
    isError: true,
    error: action.payload,
    isToastErrorShown: true,
  }),
  [requestPending]: (state) => ({
    ...state,
    isPending: true,
    isError: false,
    error: null,
    isToastErrorShown: false,
  }),
  [setUsers]: (state, action) => ({
    ...state,
    users: action.payload.content,
    usersAmount: action.payload.content.length,
    isPending: false,
    isError: false,
    error: null,
    isToastErrorShown: false,
  }),
  [userByPnr]: (state, action) => ({
    ...state,
    userByPnr: action.payload.data ? { ...action.payload.data, bloodTiter: action.payload.data.titer } : null, // for changing
    isPending: false,
    isError: false,
    error: null,
    isToastErrorShown: false,
  }),
  [getDonorDetails]: (state, action) => ({
    ...state,
    donorDetailsData: {
      currentUser: {
        ...action.payload.res.data,
        bloodTiter: action.payload.res.data.titer // for changing
      },
      currentDetails: {
        ...action.payload.details, 
        user: {
          ...action.payload.res.data,
          bloodTiter: action.payload.res.data.titer // for changing
        }, 
      },
    },
    isPending: false,
    isError: false,
    error: null,
    isToastErrorShown: false,
  }),
  [deleteDonorDetails]: (state) => ({
    ...state,
    donorDetailsData: null,
    isPending: false,
    isError: false,
    error: null,
    isToastErrorShown: false,
  }),
  [getDonorSettings]: (state, action) => ({
    ...state,
    donorSettingsData: {
      ...action.payload.reservation, 
      user: {
        ...action.payload.res.data,
        bloodTiter: action.payload.res.data.titer // for changing
      }, 
    },
    isPending: false,
    isError: false,
    error: null,
    isToastErrorShown: false,
  }),
  [deleteDonorSettings]: (state) => ({
    ...state,
    donorSettingsData: null,
    isPending: false,
    isError: false,
    error: null,
    isToastErrorShown: false,
  }),
  [toggleDonorDetailsModal]: (state) => ({
    ...state,
    donorDetailsModalIsOpen: !state.donorDetailsModalIsOpen,
  }),
  [toggleDonorSettingsModal]: (state) => ({
    ...state,
    donorSettingsModalIsOpen: !state.donorSettingsModalIsOpen,
  }),
  [setLastChoosenDonor]: (state, action) => ({
    ...state,
    lastChoosenDonor: action.payload,
  }),
},
initialState);

export default usersReducer;
