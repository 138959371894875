import React, { useState } from 'react';
import TetherComponent from 'react-tether';
import classNames from 'classnames';

const Tooltip = ({
  children,
  content,
  disabled,
  attachment = "bottom center",
  position = "top center",
  targetOffset = '-10px 0',
  className,
}) => {
    const [isVisible, setVisible] = useState(false);

    const hide = () => {
        setVisible(false);
    };
    const show = () => {
        setVisible(true);
    };

    const tooltipTargetClasses = classNames('tooltip-target', className);
    const tooltipClassName = classNames('tooltip', className);
  return (
    <>
    {
        disabled ?
        children
        : <TetherComponent
            attachment={attachment}
            targetAttachment={position}
            constraints={[
            {
                to: 'window',
                attachment: 'together',
                pin: true
            },
            ]}
            targetOffset={targetOffset}
            renderTarget={ ref => (
                <div ref={ ref } className={tooltipTargetClasses} onMouseEnter={ show } onMouseLeave={ hide }>
                    {children}
                </div>
            )}
            renderElement={ ref => (
                isVisible && (
                    <div ref={ ref } className={ tooltipClassName } onMouseEnter={ show }  onMouseLeave={ hide }>
                        <div>{content}</div>
                    </div>
                )
            )}
        />
    }
    </>
  );
};
  
export default Tooltip;