import { connect } from 'react-redux';
import {setTimeslotMenuIsOpen} from "../../redux/timeslots/actions";
import TimeSlotGroup from "./TimeSlotGroup";

const mapDispatchToProps = {
  setTimeslotMenuIsOpen,
};

const mapStateToProps = (state) => ({
  timeslotMenuIsOpened: state.timeslotsReducer.timeslotMenuIsOpened,
});

export default connect(mapStateToProps, mapDispatchToProps)(TimeSlotGroup);