import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router-dom';
import * as dateFns from 'date-fns';
import routes from '../../constants/routes';
import PropTypes from 'prop-types';
import redirect from '../../services/redirect';
import Loader from '../common/Loader';
import { useTranslation } from 'react-i18next';

const Calendar = (props) => {
  const { 
    changeDate, 
    getTimetableRequest, 
    timetable, 
    location, 
    match: { params: { date, currentMonthString = date } }, 
    setMonth, 
    currentBloodbank,
    isMonthViewFetching,
    appTypeFilter,
    bloodTypeFilter,
    donorStatusFilter,
    titerFilter,
  } = props;
  const { t } = useTranslation();

  let selectedMonth;
  const dateRegex = /^\d{4}-(0?[1-9]|1[012])$/;
  if (!date.match(dateRegex)) {
    selectedMonth = new Date();
    redirect('/');
  } else {
    selectedMonth = new Date(currentMonthString);
  }

  const [currentMonth, setCurrentMonth] = useState(selectedMonth);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const urlMonthFormat = "yyyy-MM";

  useEffect(() => {
    getTimetableRequest(dateFns.startOfMonth(currentMonth).getTime() - currentMonth.getTimezoneOffset() * 60000, currentBloodbank, appTypeFilter, bloodTypeFilter, donorStatusFilter, titerFilter,);
    setMonth(dateFns.startOfMonth(currentMonth).getTime() - currentMonth.getTimezoneOffset() * 60000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const onDateClick = selectedDay => {
    setSelectedDate(selectedDay);
    changeDate(selectedDay);
  };

  const handleDoubleClick = () => {
    const dateFormat = "yyyy-MM-dd";
    const formattedDate = dateFns.format(selectedDate, dateFormat);
    redirect(`${routes.DASHBOARD_DAY_ROUTE}/${formattedDate}`);
  };

  const refCallback = (item) => {
    if (item) {
      ReactDOM.findDOMNode(item).ondblclick = handleDoubleClick;
    }
  };

  const nextMonth = () => {
    const newMonth = dateFns.addMonths(currentMonth, 1);
    const formattedDate = dateFns.format(newMonth, urlMonthFormat);
    setCurrentMonth(newMonth);
    redirect(`${routes.DASHBOARD_MONTH_ROUTE}/${formattedDate}`);
  };

  const prevMonth = () => {
    const newMonth = dateFns.subMonths(currentMonth, 1);
    const formattedDate = dateFns.format(newMonth, urlMonthFormat);
    setCurrentMonth(newMonth);
    redirect(`${routes.DASHBOARD_MONTH_ROUTE}/${formattedDate}`);
  };

  const renderHeader = () => {
    const monthFormat = "MMMM";
    const yearFormat = "yyyy";

    return (
      <div className="calendar-header">
        <button className="calendar-btn" onClick={ prevMonth }>
          <i className="icon-arrow-left" />
        </button>
        <div className="calendar-title">
          <div className="calendar-day">{ t(`common.month.${dateFns.format(currentMonth, monthFormat)}`) }</div>
          <div className="calendar-year">{ dateFns.format(currentMonth, yearFormat) }</div>
        </div>
        <button className="calendar-btn" onClick={ nextMonth }>
          <i className="icon-arrow-right" />
        </button>
      </div>
    )
  };

  const renderDays = () => {
    const weekdayFormat = "eee";
    const days = [];

    let startDate = dateFns.startOfWeek(currentMonth, {weekStartsOn: 1});

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="calendar-cell" key={ i }>
          { t(`common.day.${dateFns.format(dateFns.addDays(startDate, i), weekdayFormat)}`) }
        </div>
      );
    }

    return (
            <div className="calendar-row">
              <div className="calendar-week" >
              </div>
              { days }
            </div>
          )
  };

  const renderCells = () => {
    const monthStart = dateFns.startOfMonth(currentMonth);
    const monthEnd = dateFns.endOfMonth(monthStart);
    const startDate = dateFns.startOfWeek(currentMonth, {weekStartsOn: 1});
    const endDate = dateFns.endOfWeek(monthEnd);

    const dateFormat = "d";
    const weekNumberFormat = "w";
    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDate = "";
    let weekNumber = 0;

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        const cloneDay = day;
        formattedDate = dateFns.format(day, dateFormat);
        const { reservations, appointments } = timetable.find(item => dateFns.isSameDay(item.day, day)) || { appointments: 0 };

        days.push(
          <div
            className={`calendar-cell ${
              !dateFns.isSameMonth(day, monthStart)
                ? "disabled"
                : dateFns.isSameDay(day, selectedDate) ? "selected" : ""
            }`}
            key={day}
            onClick={() => onDateClick(new Date(cloneDay))}
            ref={refCallback}
          >
            <span className="calendar-number">
              {formattedDate}
            </span>
            <div className="slot-container">
              {appointments > 0 &&
                <div className="slot available">
                  <span className="status">
                    <span className="status-icon"/>
                  </span>
                  <span className="slot-label">{appointments} {t('dashboard.available')}</span>
                </div>
              }
              {reservations > 0 &&
                <div className="slot not-available">
                  <span className="status">
                    <span className="status-icon"/>
                  </span>
                  <span className="slot-label">{reservations} {t('dashboard.reserved')}</span>
                </div>
              }
            </div>

          </div>
        );
        day = dateFns.addDays(day, 1);
      }

      rows.push(
        <div className="calendar-row" key={day}>
          <div className="calendar-week" >
            <div className="week-label">
              {dateFns.subDays(day, 7).getWeek()}
            </div>
          </div>
          {days}
        </div>
      );
      days = []
    }

    return <div className="calendar-body">{ rows }</div>;
  };

  const Calendar = (
    <div className="calendar">
      { renderHeader() }
      {isMonthViewFetching ? <Loader /> :  <>
      <div className="calendar-subheader"> 
          { renderDays() }
      </div>
        { renderCells() } 
        </>
    }
    </div>
  );

  return (Calendar);
};

Calendar.propTypes = {
  changeDate: PropTypes.func,
  getTimetableRequest: PropTypes.func,
  timetable: PropTypes.array,
  location: PropTypes.object,
  match: PropTypes.object,
};

Calendar.defaultProps = {
  timetable: [],
};

export default withRouter(Calendar);
