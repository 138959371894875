import i18n from '../../../i18n';

export const createPageMarkers = (pagesAmount, currentPage) => {
  const pageMarkers = [];

  for (let i = 1; i <= pagesAmount; i += 1) {
    if (currentPage === 1 || currentPage === pagesAmount) {
      if (
        i === 1 ||
        i === pagesAmount ||
        (i <= currentPage + 2 && i >= currentPage - 2)
      ) {
        pageMarkers.push({ text: String(i), value: i, key: String(i) });
      } else if (i === currentPage + 3 || i === currentPage - 3) {
        pageMarkers.push({ text: '...', value: 0, key: `dots${i}` });
      }
    } else if (
      i === 1 ||
      i === pagesAmount ||
      (i <= currentPage + 1 && i >= currentPage - 1)
    ) {
      pageMarkers.push({ text: String(i), value: i, key: String(i) });
    } else if (i === currentPage + 2 || i === currentPage - 2) {
      pageMarkers.push({ text: '...', value: 0, key: `dots${i}` });
    }
  }

  return pageMarkers;
};

export const createSelectOptions = (pageSizes, allPagesSize, totalItems) => {
  const rebuiltPageSizes = pageSizes.map((item) => {
    return {
      label: String(item),
      value: String(item),
      disabled: false
    };
  });
  if (allPagesSize) {
    rebuiltPageSizes.push({
      label: i18n.t('pagination.all'),
      value: String(totalItems),
      disabled: false
    });
  }
  return rebuiltPageSizes;
};