import React from 'react';
import { useTranslation } from 'react-i18next';

const ResourcesMenu = (props) => {
  const {
    toggleDeleteResourceModal,
    toggleEditResourceModal,
  } = props;
  const { t } = useTranslation();

  return (
    <>
      <ul className="menu-list">
        <li className="menu-item">
          <div 
            className="menu-link" 
            onClick={ toggleDeleteResourceModal }
          >
            {t('configuration.deleteResource')}
          </div>
        </li>
        <li className="menu-item">
          <div 
            className="menu-link" 
            onClick={ toggleEditResourceModal }
          >
            {t('configuration.editResource')}
          </div>
        </li>
      </ul>
    </>
  );
};

export default ResourcesMenu;
