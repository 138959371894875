import React, {useEffect, useState} from 'react';
import Number from '../../common/FormFields/Number';
import CheckboxGroup from '../../common/CheckboxGroup';
import { WEEKDAYS } from './constants';
import { useTranslation } from 'react-i18next';

const WeeklyRecurrence = (props) => {
  const { onChangeData, currentDate, timeslotRule: { interval = 1, byweekday } } = props;
  const { t } = useTranslation();
  const weekdays = WEEKDAYS.map(weekday => ({ ...weekday, label: t(`common.day.${weekday.label}`) }));

  const [inputValue, setInputValue] = useState(interval);
  const [allDays, setAllDays] = useState(weekdays.map((weekday, index) => (
    (byweekday && byweekday.includes(parseInt(weekday.value))) || index === (currentDate.getDay() || new Date().getDay())
      ? { ...weekday, checked: true }
      : weekday
  )));
  const [checkedDays, setCheckedDays] = useState(WEEKDAYS.filter(weekday => weekday.checked).map(weekday => parseInt(weekday.value)));

  useEffect(() => {
    let weeklyRule = {
      interval: inputValue,
      byweekday: checkedDays,
    };

    onChangeData(weeklyRule);
  }, [inputValue, checkedDays]);

  useEffect(() => {
    setCheckedDays(allDays.filter(weekday => weekday.checked).map(weekday => parseInt(weekday.value)))
  }, [allDays]);

  return (
    <>
      <div className="form-group inputs-group">
        <div className="inputs-group-item">
          {t('configuration.recurrence.every')}
        </div>
        <div className="inputs-group-item">
          <Number value={inputValue} minValue={1} maxValue={99} onChange={setInputValue} pattern={"[1-9][0-9]?"} />
        </div>
        <div className="inputs-group-item">
          {t('configuration.recurrence.weeks')}
        </div>
      </div>
      <div className="form-group inputs-group">
        <div className="inputs-group-item">
          {t('configuration.recurrence.on')}
        </div>
        <div className="inputs-group-item">
          <CheckboxGroup data={allDays} onCheck={setAllDays} />
        </div>
      </div>
    </>
  );
};

export default WeeklyRecurrence;
