import React from 'react';

import Modal from '../../common/Modal';
import Button from '../../common/FormFields/Button';
import { useTranslation } from 'react-i18next';

const NoResourcesModal = (props) => {
  const {
    isModalOpen, handleClose
  } = props;
  const { t } = useTranslation();

  return (
    <>
      <Modal isModalOpen={ isModalOpen } handleModalClose={ handleClose } { ...props }>
        <div className="modal-header">
          <h2 className="modal-title">{t('configuration.settings.noResourcesModal.header')}</h2>
          <button className="modal-close" onClick={ handleClose }>
            <span className="icon-close" />
          </button>
        </div>
        <div className="modal-body modal-content">
          <div className="modal-msg">
            {t('configuration.settings.noResourcesModal.body')}
          </div>
        </div>
        <div className="modal-footer light">
          <div className="inputs-group">
            <div className="inputs-group-item">
              <Button btnClassName="btn-primary" type="button" text={t('configuration.settings.change')} onClick={ handleClose } />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NoResourcesModal;
