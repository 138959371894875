import { connect } from 'react-redux';
import Schedule from './Schedule';
import { 
  setDay, 
  setMonth, 
  changeDate, 
  calendarGet, 
  changeConfigurationDate, 
  createResource, 
  updateDonor, 
  launchRefreshing, 
  changeConfigurationToRefreshed
} from '../../redux/reservations/actions'; 
import { selectResource, selectTimeslot, hideTimeslotsMenus } from '../../redux/timeslots/actions'
import { sendErrorLog } from '../../redux/logging/actions';

const mapDispatchToProps = {
  setDay,
  setMonth,
  changeDate,
  calendarGet,
  selectResource,
  selectTimeslot,
  changeConfigurationDate,
  createResource,
  hideTimeslotsMenus,
  updateDonor,
  launchRefreshing,
  changeConfigurationToRefreshed,
  sendErrorLog,
};

const mapStateToProps = (state) => ({
  dailySchedule: state.reservationReducer.dailySchedule,
  needRefreshConfiguration: state.reservationReducer.needRefreshConfiguration,
  calendar: state.reservationReducer.calendar,
  calendarIsFetched: state.reservationReducer.calendarIsFetched,
  timeslotMenuIsOpened: state.timeslotsReducer.timeslotMenuIsOpened,
  currentBloodbank: state.authReducer.currentBloodbank,
  isDayViewFetching: state.reservationReducer.isDayViewFetching,
  isMonthViewFetching: state.reservationReducer.isMonthViewFetching,
  isConfigurationFetching: state.reservationReducer.isConfigurationFetching,
  isTimeslotsFetching: state.timeslotsReducer.isTimeslotsFetching,
  isToastErrorShown: state.reservationReducer.isToastErrorShown,
  isToastSuccessShown: state.reservationReducer.isToastSuccessShown,
  isTimeslotErrorToastShown: state.timeslotsReducer.isTimeslotErrorToastShown,
  isTimeslotSuccessToastShown: state.timeslotsReducer.isTimeslotSuccessToastShown,
  isTwoFactorError: state.authReducer.isTwoFactorError,
});

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
