import { connect } from 'react-redux';
import { 
    togglePushMessagesModal,
    getAdminMessages,
    getSystemMessages,
    getScheduledMessages,
    toggleFilterSettingsModal,
} from "../../redux/pushMessages/actions";
import { sendErrorLog } from '../../redux/logging/actions';
import { PushMessages } from './PushMessages';

const mapStateToProps = (state) => ({
  currentBloodbank: state.authReducer.currentBloodbank,
  adminMessages: state.pushMessagesReducer.adminMessages,
  systemMessages: state.pushMessagesReducer.systemMessages,
  scheduledMessages: state.pushMessagesReducer.scheduledMessages,
  selectedFilters: state.pushMessagesReducer.selectedFilters,
  userRoleName: state.authReducer.roleName,
});

const mapDispatchToProps = {
    togglePushMessagesModal,
    getAdminMessages,
    getSystemMessages,
    getScheduledMessages,
    toggleFilterSettingsModal,
    sendErrorLog
};

export default connect(mapStateToProps, mapDispatchToProps)(PushMessages);