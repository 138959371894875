import { connect } from 'react-redux';
import { logoutRequest } from '../../../redux/auth/actions';
import Profile from './Profile';

const mapDispatchToProps = {
  logoutRequest
};

const mapStateToProps = (state) => ({
  roleName: state.authReducer.roleName,
  imageRef: state.authReducer.imageRef,
  isTwoFactorError: state.authReducer.isTwoFactorError
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);