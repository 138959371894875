import React, {useState} from 'react';

import TimeslotSettings from './TimeslotSettingsContainer';
import ExceptionsSettings from './ExceptionsSettingsContainer';
import RecurrenceSettings from './RecurrenceSettingsContainer';
import { getTimeIntervals } from '../../../services/helpers';

const TimeslotSettingsModal = () => {
  const [isRecurrenceSettingsVisible, setRecurrenceSettingsVisible] = useState(false);
  const [isExceptionsSettingsVisible, setExceptionsSettingsVisible] = useState(false);
  const [availableTimeSlots] = useState(getTimeIntervals());

  const [startDate, setStartDate] = useState({});
  const [endDate, setEndDate] = useState({});


  const handleRecurrenceSettingsOpen = () => {
    setRecurrenceSettingsVisible(true);
  };
  const handleRecurrenceSettingsClose = () => {
    setRecurrenceSettingsVisible(false);
  };
  const handleExceptionsSettingsOpen = () => {
    setExceptionsSettingsVisible(true);
  };
  const handleExceptionsSettingsClose = () => {
    setExceptionsSettingsVisible(false);
  };

  return (
    <>
      <TimeslotSettings
        handleExceptionsSettingsOpen={ handleExceptionsSettingsOpen }
        handleRecurrenceSettingsOpen={ handleRecurrenceSettingsOpen }
        availableTimeSlots={availableTimeSlots}
        setStartDate= { setStartDate }
        setEndDate= { setEndDate }
      />
      <ExceptionsSettings 
        isModalOpen={ isExceptionsSettingsVisible } 
        handleClose={ handleExceptionsSettingsClose }
        availableTimeSlots={availableTimeSlots} 
        startDate= { startDate }
        endDate= { endDate }
      />
      <RecurrenceSettings 
        isModalOpen={ isRecurrenceSettingsVisible } 
        handleClose={ handleRecurrenceSettingsClose } 
      />
    </>
  )
};

export default TimeslotSettingsModal;
