import { connect } from 'react-redux';
import { 
    toggleFilterSettingsModal,
    setSelectedFilters,
    refreshFilters,
} from "../../redux/pushMessages/actions";
import { FilterSettingsModal } from './FilterSettingsModal';

const mapStateToProps = (state) => ({
    filterSettingsModalIsOpen: state.pushMessagesReducer.filterSettingsModalIsOpen,
    selectedFilters: state.pushMessagesReducer.selectedFilters,
});

const mapDispatchToProps = {
    toggleFilterSettingsModal,
    setSelectedFilters,
    refreshFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterSettingsModal);