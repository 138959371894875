import { connect } from 'react-redux';
import { 
  getAppointmentsAmount,
} from "../../redux/timeslots/actions";
import { Chart } from './Chart';

const mapStateToProps = (state) => ({
  currentBloodbank: state.authReducer.currentBloodbank,
});

const mapDispatchToProps = {
  getAppointmentsAmount,
};

export default connect(mapStateToProps, mapDispatchToProps)(Chart);