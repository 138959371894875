import React, {useEffect, useState} from 'react';
import Modal from '../../common/Modal';
import Tabs from '../../common/Tabs';
import Button from '../../common/FormFields/Button';
import Radio from '../../common/FormFields/Radio';
import Number from '../../common/FormFields/Number';
import Datepicker from '../../common/FormFields/Datepicker';
import CustomScrollbar from '../../common/CustomScrollbar';
import DailyRecurrence from './DailyRecurrence';
import WeeklyRecurrence from './WeeklyRecurrence';
import MonthlyRecurrence from './MonthlyRecurrence';
import { isSelectedTab } from './helpers';
import { RECURRENCE_TYPE, TABS_DATA, ENDTIME_RADIO } from './constants';
import * as dateFns from 'date-fns';
import { useTranslation } from 'react-i18next';

const RecurrenceSettings = (props) => {
  const {
    isModalOpen,
    handleClose,
    setRecurrenceRule,
    currentDate,
    recurrenceRule,
  } = props;
  const { t } = useTranslation();

  const [tabType, setTabType] = useState(RECURRENCE_TYPE.DAILY);
  const [dailySettings, setDailySettings] = useState({});
  const [weeklySettings, setWeeklySettings] = useState({});
  const [monthlySettings, setMonthlySettings] = useState({});
  const [endTime, setEndTime] = useState(recurrenceRule?.until
    ? new Date(recurrenceRule?.until)
    : dateFns.add(dateFns.startOfDay(currentDate || new Date()), {months: 1}));
  const [repetitionsCount, setRepetitionsCount] = useState(recurrenceRule?.count || 1);
  const [checkedRadio, setCheckedRadio] = useState(ENDTIME_RADIO.DATEPICKER);

  useEffect(() => {
    setEndTime(dateFns.add(currentDate, {months: 1}));
  }, [currentDate]);

  useEffect(() => {
    if (recurrenceRule) {
      const { freq, interval, count, until, byweekday, bymonthday, weekNumber } = recurrenceRule;
      if (count) {
        setRepetitionsCount(count);
        setCheckedRadio(ENDTIME_RADIO.COUNT);
      } else if (until) {
        setEndTime(new Date(until));
        setCheckedRadio(ENDTIME_RADIO.DATEPICKER);
      } else {
        setCheckedRadio(ENDTIME_RADIO.NONE);
      }
      // eslint-disable-next-line
      switch (freq) {
        case RECURRENCE_TYPE.DAILY:
          setTabType(RECURRENCE_TYPE.DAILY);
          setDailySettings({
            interval,
          });
          setWeeklySettings({});
          setMonthlySettings({});
          break;
        case RECURRENCE_TYPE.WEEKLY:
          setTabType(RECURRENCE_TYPE.WEEKLY);
          setWeeklySettings({
            interval,
            byweekday,
          });
          setDailySettings({});
          setMonthlySettings({});
          break;
        case RECURRENCE_TYPE.MONTHLY:
          setTabType(RECURRENCE_TYPE.MONTHLY);
          setMonthlySettings({
            interval,
            ...(bymonthday && {bymonthday}),
            ...(byweekday && {byweekday}),
            ...(weekNumber && {weekNumber}),
          });
          setDailySettings({});
          setWeeklySettings({});
      }
    }
  }, [recurrenceRule]);

  const recurrenceSettings = () => {
    // eslint-disable-next-line
    switch (tabType) {
      case RECURRENCE_TYPE.DAILY:
        return { ...dailySettings, freq: RECURRENCE_TYPE.DAILY };
      case RECURRENCE_TYPE.WEEKLY:
        return { ...weeklySettings, freq: RECURRENCE_TYPE.WEEKLY };
      case RECURRENCE_TYPE.MONTHLY:
        return { ...monthlySettings, freq: RECURRENCE_TYPE.MONTHLY };
    }
  };

  const endCondition = () => {
    // eslint-disable-next-line
    switch (checkedRadio) {
      case ENDTIME_RADIO.DATEPICKER:
        return {until: endTime.getTime()};
      case ENDTIME_RADIO.COUNT:
        return {count: repetitionsCount};
    }
  };

  const disabledSave = tabType === RECURRENCE_TYPE.WEEKLY && (!weeklySettings || !weeklySettings.byweekday || !weeklySettings.byweekday.length);

  const handleSave = () => {
    setRecurrenceRule({ ...recurrenceSettings(), ...endCondition() });
    handleClose();
  };

  const handleCancel = () => {
    if (!recurrenceRule) {
      setTabType(RECURRENCE_TYPE.DAILY);
      setEndTime(dateFns.add(dateFns.startOfDay(currentDate || new Date()), {months: 1}));
      setRepetitionsCount(1);
      setCheckedRadio(ENDTIME_RADIO.DATEPICKER);
    }
    handleClose()
  };

  return (
    <Modal isModalOpen={ isModalOpen } handleModalClose={ handleCancel } { ...props }>
      <div className="modal-header">
        <h2 className="modal-title">{t('configuration.recurrence.header')}</h2>
        <button className="modal-close" onClick={ handleCancel }>
          <span className="icon-close" />
        </button>
      </div>
      <CustomScrollbar className="modal-body">
        <section className="modal-content">
          <div className="form-group">
            <Tabs
              data={ TABS_DATA.map(data => ({ ...data, value: t(`configuration.recurrence.${data.value}`) })) }
              updateData={ setTabType }
              selectedValue={ tabType }
            />
          </div>
          <div className="form-group">
            <h3 className="modal-subtitle">{t('configuration.recurrence.occur')}</h3>
          </div>

          { isSelectedTab(tabType, RECURRENCE_TYPE.DAILY) && (
            <DailyRecurrence onChangeData={setDailySettings} timeslotRule={dailySettings} />
          ) }

          { (tabType === RECURRENCE_TYPE.WEEKLY) && (
            <WeeklyRecurrence onChangeData={setWeeklySettings} timeslotRule={weeklySettings} currentDate={currentDate} />
          ) }

          { isSelectedTab(tabType, RECURRENCE_TYPE.MONTHLY) && (
            <MonthlyRecurrence onChangeData={setMonthlySettings} timeslotRule={monthlySettings} currentDate={currentDate} />
          ) }
        </section>
        <section className="modal-content light">
          <div className="form-group">
            <h3 className="modal-subtitle">{t('configuration.recurrence.ends')}</h3>
          </div>
          <div className="form-group inputs-group">
            <div className="inputs-group-item">
              <Radio
                label={t('configuration.recurrence.by')}
                name="ends_radio"
                checked={checkedRadio === ENDTIME_RADIO.DATEPICKER}
                returnValue={() => setCheckedRadio(ENDTIME_RADIO.DATEPICKER)}
              />
            </div>
            <div className="inputs-group-item">
              <Datepicker date={endTime} onDateChange={setEndTime} disabled={checkedRadio !== ENDTIME_RADIO.DATEPICKER} />
            </div>
          </div>
          <div className="form-group inputs-group">
            <div className="inputs-group-item">
              <Radio
                label={t('configuration.recurrence.after')}
                name="ends_radio"
                checked={checkedRadio === ENDTIME_RADIO.COUNT}
                returnValue={() => setCheckedRadio(ENDTIME_RADIO.COUNT)}
              />
            </div>
            <div className="inputs-group-item">
              <Number
                disabled={checkedRadio !== ENDTIME_RADIO.COUNT}
                value={repetitionsCount}
                minValue={1}
                maxValue={99}
                onChange={setRepetitionsCount}
                pattern={"[1-9][0-9]?"}
              />
            </div>
            <div className="inputs-group-item">
              <span className="bold">{t('configuration.recurrence.occurrences')}</span>
            </div>
          </div>
          <div className="form-group inputs-group">
            <div className="inputs-group-item">
              <Radio
                label={t('configuration.recurrence.noEndDate')}
                name="ends_radio"
                checked={checkedRadio === ENDTIME_RADIO.NONE}
                returnValue={() => setCheckedRadio(ENDTIME_RADIO.NONE)}
              />
            </div>
          </div>
        </section>
      </CustomScrollbar>
      <div className="modal-footer">
        <div className="inputs-group">
          <div className="inputs-group-item">
            <Button type="button" text={t('common.cancel')} onClick={handleCancel} />
          </div>
          <div className="inputs-group-item">
            <Button btnClassName="btn-primary" type="button" text={t('common.save')} onClick={handleSave} disabled={disabledSave} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RecurrenceSettings;
