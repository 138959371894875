import React, { useState, useEffect } from 'react';
import routes from "../../../constants/routes";
import { NavLink } from "react-router-dom";
import OutsideClickHandler from "../OutsideClickHandler/index";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify'

toast.configure();

const Profile = (props) => {
  const { logoutRequest, roleName, imageRef, isTwoFactorError } = props;
  const { t } = useTranslation();
  const [isSideOpen, setSideOpen] = useState(false);

  useEffect(() => {
    if(isTwoFactorError){
      toast.error('Something went wrong!', {position: toast.POSITION.BOTTOM_RIGHT})
    }
  }, [isTwoFactorError])

  const toggleProfile = () => {
    setSideOpen(!isSideOpen);
  };

  const hideProfile = () => {
    setSideOpen(false);
  };

  const profileClassName = isSideOpen ? 'profile is-open' : 'profile';
  const profileMenuClassName = isSideOpen ? 'profile-menu menu open' : 'profile-menu menu';

  return (
    <div className={ profileClassName }>
      <div className="profile-content" onClick={ toggleProfile }>
        <div className="profile-info">
          <div className="profile-name">{roleName === 'admin' ? 'Administrator' : 'Manager'}</div>
          <div className="status available">
            <span className="status-icon" />
            <div className="status-label">{t('header.available')}</div>
          </div>
        </div>
        <div className="profile-photo">
          <div className="avatar">
            <img src={imageRef ? imageRef : "https://firebasestorage.googleapis.com/v0/b/labcraft-playground.appspot.com/o/Drop.png?alt=media&token=baad16df-6e04-4165-a34b-128c60d25272"} alt=""
                 className="avatar-img" />
          </div>
        </div>
        <span className="profile-arrow" />
      </div>
      <OutsideClickHandler handleClickOutside={ hideProfile }>
        <div className={profileMenuClassName}>
          <ul className="menu-list">
            <li className="menu-item">
              <NavLink to={routes.CHANGE_BLOODBANK_ROUTE} className="menu-link" activeClassName="active">{t('header.changeBloodbank')}</NavLink>
            </li>
            <li className="menu-item">
              <NavLink to={routes.CHANGE_PASSWORD_ROUTE} className="menu-link" activeClassName="active">{t('header.changePassword')}</NavLink>
            </li>
            <li className="menu-item">
              <NavLink to={routes.CHANGE_PHONE_ROUTE} className="menu-link" activeClassName="active">{t('header.changePhone')}</NavLink>
            </li>
            <li className="menu-item">
              <NavLink to={routes.LOGIN_ROUTE} className="menu-link" onClick={logoutRequest}>{t('header.logout')}</NavLink>
            </li>
          </ul>
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default Profile;
