import { handleActions } from 'redux-actions';
import {
  setErrorLog,
  requestFail,
} from './actions';

const initialState = {
  errorLog: [],
  isError: false,
  error: null,
};

const loggingReducer = handleActions({
  [setErrorLog]: (state, action) => ({
    ...state,
    errorLog: action.payload,
  }),
  [requestFail]: (state, action) => ({
    ...state,
    isError: true,
    error: action.payload,
  }),
},
initialState);

export default loggingReducer;