import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {resendCode} from '../../../redux/auth/actions';

import Auth from '../index';
import TwoFactorForm from './TwoFactorForm';
import routes from '../../../constants/routes';

const TwofatcorAuth = (props) => {
  const { t } = useTranslation();
  const { resendCode, resendCount } = props;

  const handleClick = (e) => {
    e.preventDefault();
    resendCode();
  }
  return (
    <Auth>
      <div className="modal">
        <TwoFactorForm link={routes.INDEX} id="sign-in-button"/>
      </div>
      { resendCount === 0 ? (<div className="auth-subinfo">
        {t('auth.resentCode')}&nbsp;
         <div className="link underlined" id="resend-link" onClick={handleClick}>{t('auth.here')}</div>
        </div>) : null}
    </Auth>
  );
};


const mapStateToProps = (state) => ({
  resendCount: state.authReducer.resendCount
});

const mapDispatchToProps = {
  resendCode
}

export default connect(mapStateToProps, mapDispatchToProps)(TwofatcorAuth);
