import React, { useState, useEffect } from 'react';
import Header from '../common/Header';
import Button from '../common/FormFields/Button';
import Search from '../common/FormFields/Search';
import Table from '../common/Table';
import Pagination from '../common/Pagination';
import CustomScrollbar from '../common/CustomScrollbar';
import PushMessageForm from '../PushMessages/PushMessageFormContainer';
import FilterSettingsModal from '../PushMessages/FilterSettingsModalContainer';
import DonorDetails from '../Dashboard/Modals/DonorDetailsContainer';
import {
  DONORS_SETUP,
} from '../../constants/donors';
import {
  DONOR_LIST_PAGINATION,
} from '../../constants/pagination';
import { useTranslation } from 'react-i18next';
import { 
  prepareRowData,
  sortTableData,
  prepareRowDataForSorting,
} from './helpers';
import { listenUsersWithStatus } from "../../services/firestoreServices";
import i18n from '../../i18n';


const DonorsList = (props) => {
  const {
    currentBloodbank,
    users,
    getFilteredUsers,
    needRefresh,
    changeToRefreshed,
    togglePushMessagesModal,
    toggleFilterSettingsModal,
    selectedFilters,
    getDataForDonorDetails,
    toggleDonorDetailsModal,
    getUserByPnr,
    userByPnr,
    sendErrorLog,
    lastChoosenDonor,
    setLastChoosenDonor
  } = props;
  const { t } = useTranslation();

  const [allDonors, setAllDonors] = useState([]);
  const [choosenDonors, setChoosenDonors] = useState([]);
  const [indexes, setIndexes] = useState([0, 0]);
  const [clickCount, setClickCount] = useState(DONORS_SETUP.reduce((obj, sortKey) => ({ ...obj, [sortKey.title]: 0}), {}));
  const [filtersAmount, setFiltersAmount] = useState(0);
  const [userPnr, setUserPnr] = useState('');

  useEffect(() => {
    console.log(lastChoosenDonor);
  }, [lastChoosenDonor]);

  useEffect(() => {
      if (selectedFilters) {
          setFiltersAmount(selectedFilters.donorTypes?.length + selectedFilters.bloodTiters?.length + 
            selectedFilters.donorStatuses?.length + selectedFilters.appUserStatuses?.length + selectedFilters.smsStatuses?.length);
      }
  }, [selectedFilters, setFiltersAmount]);

  useEffect(() => {
    if (userPnr !== '') {
      getUserByPnr(userPnr);
    } else {
      const newUsers = users.map((user) => {
        return {
          initial: {...user},
          prepared: prepareRowData(user),
          sortable: prepareRowDataForSorting(user),
        }
      });
      setAllDonors(sortTableData(newUsers, 'name', 0));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, userPnr]);
  
  useEffect(() => {
    if (userByPnr && Object.keys(userByPnr).length !== 0) {
      setAllDonors([{
        initial: userByPnr,
        prepared: prepareRowData(userByPnr),
        sortable: prepareRowDataForSorting(userByPnr),
      }]);
    } else {
      setAllDonors([]);
    }
  }, [userByPnr]);

  useEffect(() => {
    const newUsers = allDonors.map((user) => {
      return {
        initial: {...user.initial},
        prepared: prepareRowData(user.initial),
        sortable: prepareRowDataForSorting(user.initial),
      }
    });
    setAllDonors(newUsers);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    setChoosenDonors(allDonors.slice(indexes[0], indexes[1]));
  }, [allDonors, indexes]);

  useEffect(() => {
    try {
      if(currentBloodbank) {
        return listenUsersWithStatus(currentBloodbank, {
          next: () => {
            getFilteredUsers({
              bloodbank: currentBloodbank, 
              donorTypes: selectedFilters ? selectedFilters.donorTypes : [],
              bloodTiters: selectedFilters ? selectedFilters.bloodTiters : [],
              donorStatuses: selectedFilters ? selectedFilters.donorStatuses : [],
              appUserStatuses: selectedFilters ? selectedFilters.appUserStatuses : [],
              smsStatuses: selectedFilters ? selectedFilters.smsStatuses : [],
            });
          }
        });
      }
    } catch (err) {
      if (err) sendErrorLog(err.message, err.code);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBloodbank]);

  useEffect(() => {
    if (needRefresh) {
      getFilteredUsers({
        bloodbank: currentBloodbank, 
        donorTypes: selectedFilters ? selectedFilters.donorTypes : [],
        bloodTiters: selectedFilters ? selectedFilters.bloodTiters : [],
        donorStatuses: selectedFilters ? selectedFilters.donorStatuses : [],
        appUserStatuses: selectedFilters ? selectedFilters.appUserStatuses : [],
        smsStatuses: selectedFilters ? selectedFilters.smsStatuses : [],
      });
      changeToRefreshed();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needRefresh]);

  
  function handleSortBtnPress(sortKey, isSortable) {
    if (isSortable) {
      clickCount[sortKey] += 1;
      setClickCount({...clickCount});
      setAllDonors(sortTableData(allDonors, sortKey, clickCount?.[sortKey]));
    }
  }
  
  const handleRowChoose = (user) => {
    setLastChoosenDonor(user.id);
    getDataForDonorDetails(user.id, {});
    toggleDonorDetailsModal();
  }

  const handlePagination = (firstIndex, lastIndex) => {
    setIndexes([firstIndex, lastIndex]);
  };

  return (
    <>
      <div className="page-container">
       <Header />
        <div className="page-content">
          <main className="main">
            <CustomScrollbar>
              <div className="container-fluid content">
                <div className="filters">
                  <div className="filters-item">
                    <Button
                        text={`+ ${t('msgs.filter')} (${filtersAmount})`}
                        btnClassName="btn-secondary"
                        onClick={toggleFilterSettingsModal}
                    />
                  </div>
                  <div className="filters-item">
                    <Button
                        text={`+ ${t('msgs.message')}`}
                        btnClassName="btn-primary"
                        onClick={togglePushMessagesModal}
                    />
                  </div>
                  <div className="filters-item">
                    <Search
                      placeholder={t('dashboard.search')}
                      label={t('dashboard.findDonor')}
                      type="text"
                      setValue={setUserPnr}
                      autoComplete={'off'}
                      isPnrSearch={true}
                    />
                  </div>
                </div>
                <div className='table-container'>
                  <Table
                    data={choosenDonors}
                    setup={DONORS_SETUP}
                    handleRowChoose={handleRowChoose}
                    handleSortBtnPress={handleSortBtnPress}
                    searchText={userPnr}
                    additionalClass="table-users"
                    activeRow={lastChoosenDonor}
                  />
                </div>
                <div className='pagination-container'>
                  <Pagination
                    pageSize={DONOR_LIST_PAGINATION.pageSize}
                    pageSizes={DONOR_LIST_PAGINATION.pageSizes}
                    allPagesSize={DONOR_LIST_PAGINATION.allPagesSize}
                    startPage={DONOR_LIST_PAGINATION.startPage}
                    totalItems={users?.length}
                    handleChange={handlePagination}
                  />
                </div>
              </div>
            </CustomScrollbar>
          </main>
        </div>
      </div>
      <DonorDetails />
      <PushMessageForm />
      <FilterSettingsModal />
    </>    
  );
};

export default DonorsList;
