import React, {useEffect, useState} from 'react';

import Auth from '../index';
import Input from '../../common/FormFields/Input';
import Button from '../../common/FormFields/Button';
import { withRouter } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const ForgotPassword = (props) => {
  const { forgotPassword, forgotPasswordError, location, clearAuthValidationErrors } = props;
  const { t } = useTranslation();
  const [email, setEmail] = useState('');

  useEffect(() => {
    clearAuthValidationErrors();
  }, [location]);

  const submitResetForm = (e) => {
    e.preventDefault();
    forgotPassword(email);
  };

  const formClassName = 'form auth-form forgot-password';

  return (
    <Auth>
      <div className="modal">
        <form action="/" className={ formClassName }>
          <h1 className="auth-title-2">{t('auth.enterEmailToRecover')}</h1>
          <div className="auth-body">
            <Input
              type="text"
              placeholder={t('auth.enterEmail')}
              label={t('auth.email')}
              onChangeValue={setEmail}
              errorMessage={email && forgotPasswordError}
            />
          </div>
          <div className="auth-footer">
            <Button
              btnClassName="btn-primary"
              text={t('auth.recoverPassword')}
              onClick={ submitResetForm }
              disabled={!email}
            />
          </div>
        </form>
      </div>
    </Auth>
  );
};

export default withRouter(ForgotPassword);
