import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';

const PhoneNumber = (props) => {
  const {t} = useTranslation();
  const {
    id,
    placeholder,
    disabled,
    label,
    additionalClassName,
    errorMessage: message,
    phoneChange
  } = props;

  const [phone, setPhone] = useState('');
  const [isValid, setValid] = useState(false);

  const handleChange = (value, data, event, formattedValue) => {
    setPhone(value);
    setValid(true);
    phoneChange(value);
  };

  const controlClass = isValid ? 'form-control' : 'form-control has-error';
  const fieldClassName = `form-field ${ additionalClassName }`;

  return (
    <div className={ controlClass }>
      <label className="form-label" htmlFor={ id }>
        { label || t('auth.newPhoneNumber') }
      </label>
      <div className={ fieldClassName }>
        <PhoneInput
          id={ id }
          country={'no'}
          onChange={(value, data, event, formattedValue) => handleChange(value, data, event, formattedValue)}
          inputClass="input"
          dropdownClass=""
          containerClass=""
          enableSearch={true}
          searchClass="form-control"
          defaultErrorMessage={t('auth.invalidPhoneNumber')}
          isValid={isValid}
          defaultCountry={'no'}
          placeholder={placeholder || t('auth.enterPhoneNumber')}
          searchPlaceholder={t('auth.enterYourCountry')}
          disabled={disabled}
        />
      </div>
      <div className="form-error">{ t('auth.invalidPhoneNumber') }</div>
    </div>
  );
};

PhoneNumber.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  additionalClassName: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
};

PhoneNumber.defaultProps = {
  id: '',
  placeholder: '',
  additionalClassName: '',
  label: '',
  value: '',
  disabled: false,
  errorMessage: '',
};


export default PhoneNumber;

