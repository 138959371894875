import React, {useEffect, useState, useCallback, useRef, useContext} from 'react';
import PropTypes from 'prop-types';
import CustomScrollbar from '../../common/CustomScrollbar';
import { ScrollBarContext }from '../../common/CustomScrollbar';

export const useResizeObserver = () => {
  const [dimensions, setDimensions] = useState({});
  const observer = useRef(null);
  const element = useRef(null);

  const handleResize = useCallback((entries) => {
    if (!Array.isArray(entries)) {
      return;
    }

    const entry = entries[0];
    const newDimensions = {
      isOverflowingY: entry.target.clientHeight < entry.target.scrollHeight,
    };

    setDimensions(newDimensions);
  }, []);

  const observeRef = useCallback(
    (target) => {
      if (!target) {
        return;
      }

      if (!observer.current) {
        observer.current = new ResizeObserver((entries) => handleResize(entries));
      }

      if (element.current !== target) {
        if (element.current && observer.current) {
          observer.current.disconnect();
        }        
        observer.current.observe(target);
        element.current = target;
      }
    },
    [handleResize]
  );

  return { observeRef, dimensions };
};

const Tags = (props) => {
  const {
    id, label, data, additionalClass, onDeleteTag, isOpenable
  } = props;
  const { observeRef, dimensions } = useResizeObserver();
  const [tagsData, setTagsData] = useState(data);
  const [isTagsOpen, setIsTagsOpen] = useState(false);
  const [isBtn, setIsBtn] = useState(false);
  const updateScroll = useContext(ScrollBarContext);

  useEffect(() => {
    setTagsData(data)
  }, [data]);

  useEffect(() => {
    updateScroll();
  }, [isTagsOpen]);

  useEffect(() => {
    if (isOpenable && !isTagsOpen) {
      setIsBtn(dimensions.isOverflowingY);
    }
  }, [dimensions]);

  const handleDelete = (tag) => {
    onDeleteTag(data.filter(item => item.label !== tag.label))
  };

  const handleTagsOpen = () => {
    setIsTagsOpen(!isTagsOpen);
  };

  const tagsClassName = `tags ${ isTagsOpen ? 'open' : 'collapsed' } ${ additionalClass }`;
  const tags = tagsData.map((item) => (
    <div className="tags-item" key={ item.label }>
      <div className="tag">
        <div className="tag-label">{ item.label }</div>
        <button className="tag-control" onClick={() => handleDelete(item)} />
      </div>
    </div>
  ));
  return (
    <div className="form-control">
      { label &&
      <label className="form-label" htmlFor={ id }>
        { label }
      </label>
      }
      <div className={ tagsClassName } >
        <CustomScrollbar >         
          <div className="tags-list" ref={observeRef}>
            { tags }
            {(isBtn || isTagsOpen) &&
              <div className="float-btn"> 
                <button 
                  className="btn-flat big-text round-border"
                  onClick={handleTagsOpen}
                >
                  { isTagsOpen ? '-' : '+' }
                </button>
              </div>
            }
          </div>

        </CustomScrollbar>
      </div>
      <div className="form-error">Required</div>
    </div>
  );
};


Tags.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.any,
    })
  ),
  onDeleteTag: PropTypes.func,
  isOpenable: PropTypes.bool,
};

Tags.defaultProps = {
  id: '',
  label: '',
  data: [],
  onDeleteTag: () => {},
  isOpenable: false,
};

export default Tags;

