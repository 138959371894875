import React, {useEffect} from 'react';

import { useTranslation } from 'react-i18next';
import Header from '../common/Header';
import CustomSelect from '../common/FormFields/Select';
import Schedule from '../Schedule';
import { TEMPLATE } from './Modals/constants';
import TimeslotSettingsModal from './Modals/TimeslotSettingsModal';
import DeleteTimeSlot from "./Modals/DeleteTimeSlotContainer";
import DeleteAppointment from "./Modals/DeleteAppointmentContainer";
import BookAppointment from "./Modals/BookAppointmentContainer";
import CancelBooking from "./Modals/CancelBookingContainer";
import ResourceDeleteModal from "./Modals/ResourceDeleteModalContainer";
import ResourceEditModal from "./Modals/ResourceEditModalContainer";

const Configuration = (props) => {
  const {
    children,
    appointmentTypes,
    getAppointmentTypes,
    fetchingConfiguration
  } = props;
  const { t } = useTranslation();

  const templateData = TEMPLATE.map(data => ({ ...data, label: t(`configuration.${data.label}`) }));

  useEffect(() => {
    if (!appointmentTypes.length) {
      fetchingConfiguration()
      getAppointmentTypes();
    }
  }, []);

  return (
    <div className="page-container">
      <Header />
      <div className="page-content">
        <main className="main">
          <div className="container-fluid content timetable">
            <div className="filters">
              <div className="filters-item">
                <CustomSelect
                  label={t('configuration.template')}
                  data={ templateData }
                  additionalClassName="xl"
                  value={ templateData[0] }
                />
              </div>
            </div>
            <Schedule
              isSettings={ true }
              timeIntervalSize="15"
              getDonorData={ () => {
              } }
              additionalClassName="settings"
            />

            <TimeslotSettingsModal/>
            <DeleteTimeSlot/>
            <DeleteAppointment/>
            <BookAppointment/>
            <CancelBooking />
            <ResourceDeleteModal />
            <ResourceEditModal />
            { children }
          </div>
        </main>
      </div>
    </div>
  );
};

export default Configuration;
