import React, {useState, useEffect} from 'react';

import Modal from '../../common/Modal';
import Button from '../../common/FormFields/Button';
import {
  checkRecurrence,
  checkSingleAppointment
} from '../../../services/helpers';
import { useTranslation } from 'react-i18next';

const DeleteTimeSlot = (props) => {
  const {
    selectedTimeslot,
    deleteTimeslotModalIsOpen,
    deleteTimeslotRequest,
    toggleDeleteTimeslotModal,
    bookedReservations,
  } = props;
  const { t } = useTranslation();

  const [isLastAppointment, setIsLastAppointment] = useState(!checkRecurrence(selectedTimeslot));
  const [isSingleAppointment, setIsSingleAppointment] = useState(checkSingleAppointment(selectedTimeslot));

  useEffect(() => {
    setIsLastAppointment(!checkRecurrence(selectedTimeslot));
    setIsSingleAppointment(checkSingleAppointment(selectedTimeslot));
  }, [selectedTimeslot]);

  const handleSubmit = () => {
    deleteTimeslotRequest(selectedTimeslot);
    toggleDeleteTimeslotModal();
  };

  return (
    <>
      <Modal isModalOpen={ deleteTimeslotModalIsOpen } handleModalClose={ toggleDeleteTimeslotModal } { ...props }>
        <div className="modal-header">
          <h2 className="modal-title">
            { (isLastAppointment && isSingleAppointment)
                ? t('configuration.settings.deleteAppointmentModal.header')
                : t('configuration.settings.deleteTimeslotModal.header')
            }
          </h2>
          <button className="modal-close" onClick={ toggleDeleteTimeslotModal }>
            <span className="icon-close" />
          </button>
        </div>
        <div className="modal-body modal-content">
          <div className="modal-msg">
            {
              (isLastAppointment && isSingleAppointment)
                ? t('configuration.settings.deleteAppointmentModal.body1')
                : bookedReservations
                  ? t('configuration.settings.deleteTimeslotModal.body1')
                  : t('configuration.settings.deleteTimeslotModal.body2')
            }
          </div>
        </div>
        <div className="modal-footer light">
          <div className="inputs-group">
            <div className="inputs-group-item">
              <Button type="button" text={t('common.cancel')} onClick={ toggleDeleteTimeslotModal } />
            </div>
            <div className="inputs-group-item">
              <Button btnClassName="btn-primary" type="button" text={t('common.delete')} onClick={handleSubmit} />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteTimeSlot;
