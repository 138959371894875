import { connect } from 'react-redux';
import {deleteTimeslotRequest, toggleDeleteTimeslotModal} from "../../../redux/timeslots/actions";
import DeleteTimeSlot from "./DeleteTimeSlot";

const mapStateToProps = (state) => ({
  selectedTimeslot: state.timeslotsReducer.selectedTimeslot,
  deleteTimeslotModalIsOpen: state.timeslotsReducer.deleteTimeslotModalIsOpen,
  bookedReservations: state.timeslotsReducer.bookedReservations,
});

const mapDispatchToProps = {
  deleteTimeslotRequest,
  toggleDeleteTimeslotModal
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteTimeSlot);
