import { connect } from 'react-redux';
import { 
    getScheduledMessages,
} from "../../redux/pushMessages/actions";
import { CouplingTable } from './CouplingTable';

const mapStateToProps = (state) => ({
  currentBloodbank: state.authReducer.currentBloodbank,
});

const mapDispatchToProps = {
    getScheduledMessages,
};

export default connect(mapStateToProps, mapDispatchToProps)(CouplingTable);