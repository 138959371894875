import { connect } from 'react-redux';
import { changeDate, getTimetableRequest, setMonth } from '../../redux/reservations/actions';
import Calendar from './Calendar';

const mapStateToProps = (state) => ({
  timetable: state.reservationReducer.timetable,
  currentBloodbank: state.authReducer.currentBloodbank,
  isMonthViewFetching: state.reservationReducer.isMonthViewFetching,
  appTypeFilter: state.reservationReducer.appTypeFilter,
  bloodTypeFilter: state.reservationReducer.bloodTypeFilter,
  donorStatusFilter: state.reservationReducer.donorStatusFilter,
  titerFilter: state.reservationReducer.titerFilter,
});

const mapDispatchToProps = {
  changeDate,
  getTimetableRequest,
  setMonth
}

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);