import { connect } from 'react-redux';
import {changePassword, clearAuthValidationErrors} from '../../redux/auth/actions';
import ChangePassword from './ChangePassword';

const mapStateToProps = (state) => ({
  fetching: state.authReducer.fetching,
  changePasswordErrors: state.authReducer.changePasswordErrors,
});

const mapDispatchToProps = {
  changePassword,
  clearAuthValidationErrors,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);