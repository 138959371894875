import { connect } from 'react-redux';
import { setRecurrenceRule} from "../../../redux/timeslots/actions";
import RecurrenceSettings from "./RecurrenceSettings";

const mapStateToProps = (state) => ({
  currentDate: state.reservationReducer.currentDate,
  recurrenceRule: state.timeslotsReducer.recurrenceRule,
});

const mapDispatchToProps = {
  setRecurrenceRule,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecurrenceSettings);
