import React from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import routes from '../../../constants/routes';
import * as dateFns from 'date-fns';
import {connect} from "react-redux";
import { useTranslation } from 'react-i18next';
import Tooltip from '../Tooltip';

const Nav = (props) => {
  const { t } = useTranslation();
  const { currentDate, userRoleName } = props;
  const dateFormat = "yyyy-MM-dd";
  const today = new Date();
  const formattedDate = dateFns.format(currentDate || today, dateFormat);
  const { date } = useParams();
  const history = useHistory();
  const monthFormat = "yyyy-MM";
  const formattedMonth = dateFns.format(currentDate || today, monthFormat);
  const isAdmin = userRoleName === 'admin';

  return (
    <nav className="nav">
      <ul className="nav-list">
        <li className="nav-item" onClick={() => {history.push(`${ routes.DASHBOARD_DAY_ROUTE }/${ formattedDate }`)}}>
          <NavLink to={ `${ routes.DASHBOARD_DAY_ROUTE }/${ date }` } className="nav-link"
                   activeClassName="is-active">{t('header.day')}</NavLink>
        </li>
        <li className="nav-item">
          <Tooltip content={t('header.tooltip.week')}>
            <NavLink
              onClick={(e) => e.preventDefault()}
              to={ routes.DASHBOARD_WEEK_ROUTE } 
              className="nav-link nav-disabled" 
              activeClassName="is-active"
            >
              {t('header.week')}
            </NavLink>
          </Tooltip>
        </li>
        <li className="nav-item" onClick={() => {history.push(`${ routes.DASHBOARD_MONTH_ROUTE }/${ formattedMonth }`)}}>
          <NavLink to={ `${routes.DASHBOARD_MONTH_ROUTE}/${ date }` } className="nav-link" activeClassName="is-active">{t('header.month')}</NavLink>
        </li>
      </ul>
      <ul className="nav-list nav-controls">
        {/* <li className="nav-item" onClick={() => {history.push( routes.CHART_ROUTE )}}>
          <NavLink to={ `${ routes.CHART_ROUTE }` } className="nav-link" activeClassName="is-active">
            <i className="icon-chart"></i>
          </NavLink>
        </li> */}
        <li className="nav-item" onClick={() => {history.push(`${ routes.PUSH_MESSAGES_ROUTE }/admin`)}}>
          <NavLink to={ `${ routes.PUSH_MESSAGES_ROUTE }` } className="nav-link" activeClassName="is-active">
            <i className="icon-message"></i>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to={ routes.DONORS_LIST_ROUTE } className="nav-link" activeClassName="is-active">
            <i className="icon-donor"></i>
          </NavLink>
        </li>
        <li className="nav-item">
          <Tooltip content={t('header.tooltip.configuration')} disabled={isAdmin}>
            <NavLink
              onClick={(e) => {if (!isAdmin) e.preventDefault() }}
              to={`${routes.CONFIGURATION_ROUTE}/${formattedDate}`} 
              className={`nav-link ${isAdmin || 'nav-disabled'}`}
              activeClassName="is-active"
            >
                <i className="icon-settings"></i>
            </NavLink>
          </Tooltip>
        </li>
      </ul>
    </nav>
  );
};

const mapStateToProps = (state) => ({
  currentDate: state.reservationReducer.currentDate,
  userRoleName: state.authReducer.roleName,
});

export default connect(mapStateToProps)(Nav);
