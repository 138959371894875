import { connect } from "react-redux";
import DonorDetails from "./DonorDetails";
import { updateDonor } from "../../../redux/reservations/actions";
import {
  deleteDonorDetails,
  toggleDonorDetailsModal
} from "../../../redux/users/actions";

const mapDispatchToProps = {
  updateDonor,
  deleteDonorDetails,
  toggleDonorDetailsModal,
};

const mapStateToProps = (state) => ({
  donorDetailsData: state.usersReducer.donorDetailsData,
  isUserGetError: state.usersReducer.isError,
  isUserPending: state.usersReducer.isPending,
  isModalOpen: state.usersReducer.donorDetailsModalIsOpen,
  userRoleName: state.authReducer.roleName,
  bloodbankId: state.authReducer.currentBloodbank
});

export default connect(mapStateToProps, mapDispatchToProps)(DonorDetails);
