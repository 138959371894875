import { connect } from 'react-redux';
import SideBar from './Sidebar';
import {
  getDataForDonorDetails,
  getDataForDonorSettings,
  toggleDonorDetailsModal,
  toggleDonorSettingsModal,
} from '../../redux/users/actions';

const mapDispatchToProps = {
  getDataForDonorDetails,
  getDataForDonorSettings,
  toggleDonorDetailsModal,
  toggleDonorSettingsModal,
}

const mapStateToProps = (state) => ({
  dailyReservations: state.reservationReducer.prevDaysReservations,
  newUsers: state.reservationReducer.newUsers,
  isSidebarFetching: state.reservationReducer.isSidebarFetching,
})

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
