import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { confirmCode } from '../../../redux/auth/actions';

import Input from '../../common/FormFields/Input';
import Button from '../../common/FormFields/Button';

const TwoFactorForm = (props) => {
  const [code, setCode] = useState('');
  const {t} = useTranslation();
  const formClassName = 'form auth-form two-factor';
  const { confirmCode } = props;

  const handleClick = (e) => {
    e.preventDefault();
    confirmCode(code)
  }

  return (
    <form action="submit" className={formClassName}>
      <h1 className="auth-title-2">{t('auth.enterCodeToProceed')}</h1>
      <div className="auth-body">
        <Input
          type="text"
          placeholder={t('auth.enterYourCode')}
          label={t('auth.code')}
          errorMessage={t('auth.invalidCode')}
          onChangeValue={setCode}
          autoFocus={true}
        />
      </div>
      <div className="auth-footer">
        <Button
         btnClassName="btn-primary"
        text={t('auth.confirm')}
        id="sign-in-button"
        onClick={handleClick}
        />
      </div>
    </form>
  );
};

const mapDispatchToProps = {
  confirmCode
}
export default connect(null, mapDispatchToProps)(TwoFactorForm);
