import { connect } from 'react-redux';
import {clearAuthValidationErrors, forgotPassword} from '../../../redux/auth/actions';
import ForgotPassword from './ForgotPassword';

const mapStateToProps = (state) => ({
  forgotPasswordError: state.authReducer.forgotPasswordError,
});

const mapDispatchToProps = {
  forgotPassword,
  clearAuthValidationErrors
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);