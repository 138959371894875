export const COUPLING_HEADINGS = {
    MESSAGE_ID: 'message_id',
    USER_ID: 'user_id',
    CATEGORY: 'category',
  };
  
export const COUPLING_TABLE_SETUP = [
  {
    title: COUPLING_HEADINGS.MESSAGE_ID,
    isSortable: false,
    isHighlighted: true,
    isTextOwerflowHidden: false,
  },
  {
    title: COUPLING_HEADINGS.USER_ID,
    isSortable: false,
    isHighlighted: true,
    isTextOwerflowHidden: false,
  },
  {
    title: COUPLING_HEADINGS.CATEGORY,
    isSortable: false,
    isHighlighted: true,
    isTextOwerflowHidden: false,
  },
];