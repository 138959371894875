import { connect } from 'react-redux';
import {
  getDataForDonorDetails,
  getDataForDonorSettings,
  toggleDonorDetailsModal,
  toggleDonorSettingsModal,
} from '../../redux/users/actions';
import TimeSlot from "./TimeSlot";

const mapDispatchToProps = {
  getDataForDonorDetails,
  getDataForDonorSettings,
  toggleDonorDetailsModal,
  toggleDonorSettingsModal,
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(TimeSlot);