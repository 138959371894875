export const APPOINTMENT_TYPE_DETAILS = [
  {value: 'full_blood', label: 'full_blood'},
  {value: 'apheresis', label: 'apheresis'}
];

export const APPOINTMENT_TYPES_PLACEHOLDER = [
  {
    donorType: "",
    label: "",
    appointmentTypeId: "",
    donorStatus: "",
    duration :0,
    value: '',
  }
];

export const DONOR_STATUS_DETAILS = [
  { value: 'new', label: 'new' },
  { value: 'approved', label: 'approved' },
  { value: 'established', label: 'established' },
];

export const DONOR_STATUS_SHOW_UP = [
  { value: 'new', label: 'new', donorLevel: 'new' },
  { value: 'approved', label: 'approved', donorLevel: 'first' },
  { value: 'established', label: 'established', donorLevel: 'nte' }
];

export const ALL_BLOOD_TYPES = [
  {rhesus: 'minus', bloodType: 'o'},
  {rhesus: 'plus', bloodType: 'o'},
  {rhesus: 'minus', bloodType: 'a'},
  {rhesus: 'plus', bloodType: 'a'},
  {rhesus: 'minus', bloodType: 'b'},
  {rhesus: 'plus', bloodType: 'b'},
  {rhesus: 'minus', bloodType: 'ab'},
  {rhesus: 'plus', bloodType: 'ab'},
];

export const ABO = [
  { value: 'o', label: 'O' },
  { value: 'a', label: 'A' },
  { value: 'b', label: 'B' },
  { value: 'ab', label: 'AB' },
];

export const Rhd = [
  { value: 'minus', label: '-' },
  { value: 'plus', label: '+' },
];

export const BLOOD_TITER = [
  'low', 'high', 'unknown'
];

export const TEMPLATE = [
  { value: 'Plain', label: 'Plain' },
];

export const EXCLUDE_DATES = [
  { id: '1', label: '8 March 2020', value: '8_03_20' },
  { id: '2', label: '17 September 2020', value: '17_09_20' },
  { id: '3', label: '18 September 2020', value: '18_09_20' },
  { id: '4', label: '21 September 2020', value: '21_09_20' },
  { id: '5', label: '17 October 2020', value: '17_10_20' },
  { id: '6', label: '23 November 2020', value: '23_11_20' },
  { id: '7', label: '17 September 2020', value: '17_09_20' },
  { id: '8', label: '30 November 2020', value: '30_11_20' },
  { id: '9', label: '31 December 2020', value: '31_12_20' },
];
export const NONWORKING_HOURS = [
  { id: '1', label: 'From 09:30 to 10:00 on Mon, Tue', value: '9:30_10:00_Mon_Tue' },
];

export const WEEKDAYS = [
  { id: '1', value: '6', label: 'Sun', checked: false },
  { id: '2', value: '0', label: 'Mon', checked: false },
  { id: '3', value: '1', label: 'Tue', checked: false },
  { id: '4', value: '2', label: 'Wed', checked: false },
  { id: '5', value: '3', label: 'Thu', checked: false },
  { id: '6', value: '4', label: 'Fri', checked: false },
  { id: '7', value: '5', label: 'Sat', checked: false },
];
export const WEEKDAYS_LIST = [
  { id: '1', value: '6', label: 'Sunday' },
  { id: '2', value: '0', label: 'Monday' },
  { id: '3', value: '1', label: 'Tuesday' },
  { id: '4', value: '2', label: 'Wednesday' },
  { id: '5', value: '3', label: 'Thursday' },
  { id: '6', value: '4', label: 'Friday' },
  { id: '7', value: '5', label: 'Saturday' },
];

export const WEEKDAYS_RULE = [
  { id: '1', value: '1', label: 'First' },
  { id: '2', value: '2', label: 'Second' },
  { id: '3', value: '3', label: 'Third' },
  { id: '4', value: '4', label: 'Fourth' },
  { id: '5', value: '-1', label: 'Last' },
];

export const ENDTIME_RADIO = {
  DATEPICKER: 'datepicker',
  COUNT: 'count',
  NONE: 'none',
};

export const RECURRENCE_TYPE = {
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
};

export const TABS_DATA = [
  {
    name: RECURRENCE_TYPE.DAILY,
    id: 'daily',
    value: 'daily',
  },
  {
    name: RECURRENCE_TYPE.WEEKLY,
    id: 'weekly',
    value: 'weekly'
  },
  {
    name: RECURRENCE_TYPE.MONTHLY,
    id: 'monthly',
    value: 'monthly',
  },
];
