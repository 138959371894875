import React from 'react';
import PropTypes from 'prop-types';
import { getBloodType, getStatusStyles } from "../../services/helpers";
import { useTranslation } from 'react-i18next';

const Slot = (props) => {
  const { 
    status, 
    name, 
    bloodTypes, 
    type, 
    size, 
    delay, 
    donorStatus, 
    isConfiguration, 
    title,
    reservationStatus,
    bloodTiter,
    donor,
    isReservation
  } = props;
  const { t } = useTranslation();

  const isLowTiter = bloodTiter && bloodTiter.length === 1 && bloodTiter.find(item => item === 'low');

  const slotClassName = isConfiguration
    ? (reservationStatus ? `slot ${  getStatusStyles(reservationStatus) } ${ size }` : `slot ${ donorStatus } ${ size }`)
    : (isReservation ? `slot ${  getStatusStyles(status) } ${ status } ${ size } ${ delay }` : `slot ${ donor } ${ status } ${ size } ${ delay }`)

  const typeTranslation = t(`common.appointmentType.${type}`);
  
  return (
    <div
      className={ slotClassName }
    >
      <div className="status">
        <span className="status-icon" />
      </div>
      <div className="slot-info">
        { title && <div className="slot-name slot-user">{title}</div>}
        <div className="slot-name">{ (!isConfiguration ? name : name.replace(type, typeTranslation)) || getBloodType(bloodTypes) }</div>
        <div className="slot-type">{ !isConfiguration ? typeTranslation : null }</div>
        <div className="slot-bloodtype">{ isConfiguration && donorStatus !== 'new' ? getBloodType(bloodTypes): null }</div>
        <div className={isConfiguration ? "slot-type" : "slot-status"}>
          { donorStatus ? t(`common.donorStatus.${donorStatus}`) : null }
        </div>
      </div>
      { isLowTiter &&
        <div className="titer-info">LT</div>
      }
    </div>
  );
};

Slot.propTypes = {
  status: PropTypes.string,
  name: PropTypes.string,
  bloodTypes: PropTypes.array,
  type: PropTypes.string,
  size: PropTypes.string,
  delay: PropTypes.string,
};

Slot.defaultProps = {
  status: '',
  name: '',
  bloodTypes: [],
  type: '',
  size: '',
  delay: '',
};

export default Slot;
