import { connect } from 'react-redux';
import { login, clearAuthValidationErrors } from '../../../redux/auth/actions';
import LoginForm from './LoginForm';

const mapStateToProps = (state) => ({
  loginErrors: state.authReducer.loginErrors,
});

const mapDispatchToProps = {
  login,
  clearAuthValidationErrors,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);