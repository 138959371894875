import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon'

import logo from '../../../assets/img/logo.svg';

const Logo = (props) => {
  const { 
    additionalClassName, 
    bloodbankName,
    currentDate,
    currentBloodbank,
    resetSchedule
  } = props;
  const { t } = useTranslation();

  const currentDay = DateTime.now().setZone("Europe/Oslo").toFormat("yyyy-MM-dd");

  const handleRefresh = async () => {
    const currentDay = DateTime.now().startOf("day").toJSDate();
    await resetSchedule(Date.parse(currentDay), currentBloodbank);
  };

  return (
    <Link
      to={`/day/${currentDay}`}
      className={`logo ${additionalClassName}`}
      onClick={handleRefresh}
    >
      <img className="logo-img" src={ logo }
           alt="Web-based admin tool for a mobile application where a donor can sign up for a blood donation." />
      <div className="logo-label">{bloodbankName || t('header.unknownBloodbank')}</div>
    </Link>
  )
};

Logo.propTypes = {
  additionalClassName: PropTypes.string,
};

Logo.defaultProps = {
  additionalClassName: '',
};

export default Logo;
