import React from 'react';

import Modal from '../common/Modal';
import Button from '../common/FormFields/Button';
import { useTranslation } from 'react-i18next';

const DiscardSettingsModal = (props) => {
  const {
    isModalOpen, handleSubmit, handleClose
  } = props;
  const { t } = useTranslation();

  return (
    <>
      <Modal isModalOpen={ isModalOpen } handleModalClose={ handleClose } { ...props }>
        <div className="modal-header">
          <h2 className="modal-title">{t('configuration.settings.discardModal.header')}</h2>
          <button className="modal-close" onClick={ handleClose }>
            <span className="icon-close" />
          </button>
        </div>
        <div className="modal-body modal-content">
          <div className="modal-msg">{t('configuration.settings.discardModal.body')}</div>
        </div>
        <div className="modal-footer light">
          <div className="inputs-group">
            <div className="inputs-group-item">
              <Button type="button" text={t('common.cancel')} onClick={ handleClose } />
            </div>
            <div className="inputs-group-item">
              <Button btnClassName="btn-primary" type="button" text={t('configuration.settings.discard')} onClick={ handleSubmit } />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DiscardSettingsModal;
