import React, { useState, useEffect } from 'react';
import Modal from '../common/Modal';
import CustomScrollbar from '../common/CustomScrollbar';
import Textarea from '../common/FormFields/Textarea';
import Button from '../common/FormFields/Button';
import Tags from '../common/Tags';
import Tooltip from '../common/Tooltip';
import DiscardSettingsModal from '../MainModals/DiscardSettingsModal';
import { useTranslation } from 'react-i18next';
import {
  MSG_TITLE_MAX_LENGTH,
  MSG_BODY_MAX_LENGTH,
} from '../../constants/pushMessages';
import {
  bloodTypesToTags,
  filtersToTags,
} from '../../services/helpers';
import { 
  listenAdminMessages,
} from "../../services/firestoreServices";


export const PushMessageForm = (props) => {
    const {
        pushMessagesModalIsOpen,
        togglePushMessagesModal,
        currentBloodbank,
        bloodbankName,
        adminId,
        createAdminMessage,
        usersAmount,
        getLastAdminMessage,
        lastAdminMessageId,
        selectedFilters,
        setSelectedFilters,
        msg = null,
        isSendable = true,
        getFilteredUsers,
        sendErrorLog,
        userRoleName,
    } = props;
    const { t } = useTranslation();

    const [msgTitle, setMsgTitle] = useState('');
    const [msgBody, setMsgBody] = useState('');
    const [bloodbanks, setBoodbanks] = useState([{id: "1", label: bloodbankName, value: currentBloodbank}]);
    const [currentMsgId, setCurrentMsgId] = useState(lastAdminMessageId || 0);

    const [localSelectedFilters, setLocalSelectedFilters] = useState(selectedFilters);
    
    const [selectedBloodTypes, setSelectedBloodTypes] = useState(bloodTypesToTags(localSelectedFilters.donorTypes || []));
    const [selectedBloodTiters, setSelectedBloodTiters] = useState(filtersToTags(localSelectedFilters.bloodTiters, t, 'configuration.settings.titer.'));
    const [selectedDonorStatuses, setSelectedDonorStatuses] = useState(filtersToTags(localSelectedFilters.donorStatuses, t, 'common.donorStatus.'));
    const [selectedAppUserStatuses, setSelectedAppUserStatuses] = useState(filtersToTags(localSelectedFilters.appUserStatuses, t, 'common.appUserStatus.'));
    const [selectedSmsStatuses, setSelectedSmsStatuses] = useState(filtersToTags(localSelectedFilters.smsStatuses, t, 'common.smsStatus.'));

    const [discardModalIsVisible, setDiscardModalIsVisible] = useState(false);
    
    useEffect(() => {
      if (currentBloodbank && pushMessagesModalIsOpen) {
        setBoodbanks([{id: "1", label: bloodbankName, value: currentBloodbank}]);
        if (currentMsgId === 0) {
          getLastAdminMessage();
        }
        if (msg === null) {
          setSelectedBloodTypes(bloodTypesToTags(selectedFilters.donorTypes));
          setSelectedBloodTiters(filtersToTags(selectedFilters.bloodTiters, t, 'configuration.settings.titer.'));
          setSelectedDonorStatuses(filtersToTags(selectedFilters.donorStatuses, t, 'common.donorStatus.'));
          setSelectedAppUserStatuses(filtersToTags(selectedFilters.appUserStatuses, t, 'common.appUserStatus.'));
          setSelectedSmsStatuses(filtersToTags(selectedFilters.smsStatuses, t, 'common.smsStatus.'));
        } else {
          setSelectedBloodTypes(bloodTypesToTags(localSelectedFilters.donorTypes));
          setSelectedBloodTiters(filtersToTags(localSelectedFilters.bloodTiters, t, 'configuration.settings.titer.'));
          setSelectedDonorStatuses(filtersToTags(localSelectedFilters.donorStatuses, t, 'common.donorStatus.'));
          setSelectedAppUserStatuses(filtersToTags(localSelectedFilters.appUserStatuses, t, 'common.appUserStatus.'));
          setSelectedSmsStatuses(filtersToTags(localSelectedFilters.smsStatuses, t, 'common.smsStatus.'));
        }
      }
    }, [currentBloodbank, bloodbankName, pushMessagesModalIsOpen]);

    useEffect(() => {
      if (msg === null) {
        setSelectedFilters({
          donorTypes: selectedBloodTypes.map((item) => item.value),
          bloodTiters: selectedBloodTiters.map((item) => item.value),
          donorStatuses: selectedDonorStatuses.map((item) => item.value),
          appUserStatuses: selectedAppUserStatuses.map((item) => item.value),
          smsStatuses: selectedSmsStatuses.map((item) => item.value),
        });
      } else {
        setLocalSelectedFilters({
          donorTypes: selectedBloodTypes.map((item) => item.value),
          bloodTiters: selectedBloodTiters.map((item) => item.value),
          donorStatuses: selectedDonorStatuses.map((item) => item.value),
          appUserStatuses: selectedAppUserStatuses.map((item) => item.value),
          smsStatuses: selectedSmsStatuses.map((item) => item.value),
        });
      }
    }, [selectedBloodTypes, selectedBloodTiters, selectedDonorStatuses, selectedAppUserStatuses, selectedSmsStatuses]);


    useEffect(() => {
      setLocalSelectedFilters(selectedFilters);
    }, [selectedFilters]);

    useEffect(() => {
      getFilteredUsers({
        bloodbank: currentBloodbank, 
        donorTypes: localSelectedFilters.donorTypes,
        bloodTiters: localSelectedFilters.bloodTiters,
        donorStatuses: localSelectedFilters.donorStatuses,
        appUserStatuses: localSelectedFilters.appUserStatuses,
        smsStatuses: localSelectedFilters.smsStatuses,
      });
    }, [localSelectedFilters]);

    useEffect(() => {
      if (lastAdminMessageId !== null) {
        setCurrentMsgId(lastAdminMessageId);
      }
    }, [lastAdminMessageId]);

    useEffect(() => {
      try {
        if (currentBloodbank) {
          return listenAdminMessages({
            next: () => {
              getLastAdminMessage();
            }
          });
        }
      } catch (err) {
        if (err) sendErrorLog(err.message, err.code);
      }
    }, [currentBloodbank]);

    useEffect(() => {
      if (msg) {
        setMsgTitle(msg.title);
        setMsgBody(msg.body);
        setSelectedBloodTypes(bloodTypesToTags(msg.filters?.donorTypes || []));
        setSelectedBloodTiters(filtersToTags(msg.filters?.bloodTiters || [], t, 'configuration.settings.titer.'));
        setSelectedDonorStatuses(filtersToTags(msg.filters?.donorStatuses || [], t, 'common.donorStatus.'));
        setSelectedAppUserStatuses(filtersToTags(msg.filters?.appUserStatuses || [], t, 'common.appUserStatus.'));
        setSelectedSmsStatuses(filtersToTags(msg.filters?.smsStatuses || [], t, 'common.smsStatus.'));
      } else {
        setMsgTitle('');
        setMsgBody('');
      }
      if (!pushMessagesModalIsOpen && msg && msg.filters) {
        setSelectedBloodTypes(bloodTypesToTags(localSelectedFilters.donorTypes));
        setSelectedBloodTiters(filtersToTags(localSelectedFilters.bloodTiters, t, 'configuration.settings.titer.'));
        setSelectedDonorStatuses(filtersToTags(localSelectedFilters.donorStatuses, t, 'common.donorStatus.'));
        setSelectedAppUserStatuses(filtersToTags(localSelectedFilters.appUserStatuses, t, 'common.appUserStatus.'));
        setSelectedSmsStatuses(filtersToTags(localSelectedFilters.smsStatuses, t, 'common.smsStatus.'));
      }
    }, [pushMessagesModalIsOpen]);


    const handleSend = () => {
      createAdminMessage({
        bloodbanks: bloodbanks.map((bloodbank) =>  bloodbank.value),
        title: msgTitle,
        body: msgBody,
        adminId: adminId,
        donorTypes: localSelectedFilters.donorTypes,
        bloodTiters: localSelectedFilters.bloodTiters,
        donorStatuses: localSelectedFilters.donorStatuses,
        appUserStatuses: localSelectedFilters.appUserStatuses,
        smsStatuses: localSelectedFilters.smsStatuses,
      }
      );
      togglePushMessagesModal();
    };


    const openDiscardModal = () => {
      setDiscardModalIsVisible(true);
    };
  
    const cancelDiscard = () => {
      setDiscardModalIsVisible(false);
    };
  
    const submitDiscard = () => {
      setDiscardModalIsVisible(false);
      handleModalClose();
    };

    const handleModalClose = () => {
        togglePushMessagesModal();
        setMsgTitle('');
        setMsgBody('');
    };

    return (
        <>
        <Modal isModalOpen={ pushMessagesModalIsOpen } additionalClassName={'messages'}
          handleModalClose={ openDiscardModal } { ...props }
        >
          <div className="modal-header">
            <h2 className="modal-title">
              {t('msgs.messages_editor')}
            </h2>
            <button className="modal-close" onClick={ openDiscardModal }>
              <span className="icon-close" />
            </button>
          </div>
  
          <CustomScrollbar className="modal-body modal-flex-wrapper modal-content">
              <section className='modal-section flex-grow-0 d-flex justify-content-between'>
                <div className='form-group-vertical'>
                  <div className='form-group form-text title-italic'>
                    { `${t('msgs.message_id')}:  ${ (isSendable && !msg) ? currentMsgId : msg.id}` }
                  </div>
                  <div className='form-group form-text title-italic'>
                    { isSendable || `${t('msgs.receiver_id')}:  ${ msg && msg.userId || msg.topic}` }
                  </div>
                </div>
                <div className='form-group-vertical'>
                    <Button
                        disabled={!msgTitle || !msgBody || !usersAmount || !isSendable || !(userRoleName === 'admin')}
                        text={`${t('msgs.send')}`}
                        btnClassName="btn-primary additional-icon"                    
                        icon="icon-send"
                        onClick={ handleSend }
                    />
                </div>
              </section>
              <section className='modal-section flex-grow-1 d-flex'>
                <div className='form-group-vertical message-fields'>
                  <div className='form-group'>
                    <Textarea
                      maxLength={MSG_TITLE_MAX_LENGTH}
                      placeholder={t('msgs.title')}
                      value={msgTitle}
                      changeValue={ setMsgTitle }
                    />
                  </div>
                  <div className='form-group'>
                    <Textarea 
                      additionalClass='big-area'
                      maxLength={MSG_BODY_MAX_LENGTH}
                      placeholder={t('msgs.message')}
                      value={msgBody}
                      changeValue={ setMsgBody }
                    />
                  </div>
                </div>
                <div className='form-group-vertical message-fields d-flex flex-column justify-content-between'>
                  <div className='flex-grow-1'>
                      {
                        isSendable &&
                        <>
                          <div className='form-group form-text title-bold'>
                            {t('msgs.filter')}
                          </div>
                          <div className='form-group'>
                              <Tooltip 
                                content={t('msgs.tooltip.bloodbank')} 
                                position="right center"
                                targetOffset='-6px 200px'
                              >
                                <Tags
                                  additionalClass='inline'
                                  data={bloodbanks} 
                                  onDeleteTag={() => {}} 
                                />
                              </Tooltip> 
                            {
                              selectedBloodTypes.length ?
                              <Tooltip 
                                content={t('msgs.tooltip.blood_type')} 
                                position="right center"
                                targetOffset='-6px 200px'
                              >
                                <Tags 
                                  additionalClass="inline" 
                                  data={selectedBloodTypes} 
                                  onDeleteTag={ setSelectedBloodTypes }
                                />
                              </Tooltip> : 
                              <></>
                            }
                            {
                              selectedBloodTiters?.length ?
                              <Tooltip 
                                content={t('msgs.tooltip.titer')} 
                                position="right center"
                                targetOffset='-6px 200px'
                              >
                                <Tags 
                                  additionalClass="inline" 
                                  data={ selectedBloodTiters } 
                                  onDeleteTag={ setSelectedBloodTiters }
                                />
                              </Tooltip> : 
                              <></>                            
                            }
                            {
                              selectedDonorStatuses?.length ?
                              <Tooltip 
                                content={t('msgs.tooltip.donor_status')} 
                                position="right center"
                                targetOffset='-6px 200px'
                              >
                                <Tags 
                                  additionalClass="inline" 
                                  data={ selectedDonorStatuses } 
                                  onDeleteTag={ setSelectedDonorStatuses }
                                />
                              </Tooltip> : 
                              <></>                            
                            }
                            {
                              selectedAppUserStatuses?.length ?
                              <Tooltip 
                                content={t('msgs.tooltip.app_user')} 
                                position="right center"
                                targetOffset='-6px 200px'
                              >
                                <Tags 
                                  additionalClass="inline" 
                                  data={ selectedAppUserStatuses } 
                                  onDeleteTag={ setSelectedAppUserStatuses }
                                />
                              </Tooltip> : 
                              <></>                            
                            }
                            {
                              selectedSmsStatuses?.length ?
                              <Tooltip 
                                content={t('msgs.tooltip.sms')} 
                                position="right center"
                                targetOffset='-6px 200px'
                              >
                                <Tags 
                                  additionalClass="inline" 
                                  data={ selectedSmsStatuses } 
                                  onDeleteTag={ setSelectedSmsStatuses }
                                />
                              </Tooltip> : 
                              <></>                            
                            }
                          </div>
                        </>
                      }
                  </div>

                  <div className='flex-grow-0 form-text title-italic'>
                    { isSendable && `${t('msgs.candidates_amount')}: ${usersAmount}` }
                  </div>
                </div>
              </section>
          </CustomScrollbar>          
        </Modal>
        <DiscardSettingsModal
          isModalOpen={discardModalIsVisible}
          handleSubmit={submitDiscard}
          handleClose={cancelDiscard}
        />
      </>  
    );
}