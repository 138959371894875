import React, { useState, useEffect } from 'react';
import * as dateFns from 'date-fns';
import PropTypes from 'prop-types';
import {
  getStartOfWorkDay,
} from '../../services/helpers';

const NowTime = (props) => {
  const { scroll, getScrollData, showTimeStrip } = props;
  const getNowTime = () => dateFns.getTime(new Date());
  const [time, setTime] = useState(getNowTime());

  const startTime = getStartOfWorkDay();
  const styles = getComputedStyle(document.documentElement);
  const calendarVerticalIndent = parseInt(styles.getPropertyValue('--calendar-vertical-indent'));
  const minuteHeight = parseInt(styles.getPropertyValue('--minute-height'));

  const getNowTimeOffset = () => {
    const pastTime = time - startTime;
    const distance = pastTime * minuteHeight / 60000 + calendarVerticalIndent;
    const formattedDistance = parseInt(distance - scroll);
    getScrollData(formattedDistance);

    return formattedDistance;
  };

  const [position, setPosition] = useState(getNowTimeOffset());
  const updateOffset = () => setPosition(getNowTimeOffset());

  useEffect(() => {
    const newPosition = updateOffset;
    setPosition(newPosition);
  }, [scroll, time]);

  useEffect(() => {
    if(showTimeStrip) {
      const updateTime = () => {
        setTime(getNowTime());
      };
      const updateTimeInterval = setInterval(updateTime, 60000);
      return () => {
        clearInterval(updateTimeInterval);
      }
    }
    setTime(getNowTime());
  }, [showTimeStrip]);

  return (
    showTimeStrip && <div className="nowtime" style={ { top: `${ position }px` || 0 } }>
      <div className="nowtime-line" />
    </div>
  );
};

NowTime.propTypes = {
  showTimeStrip: PropTypes.bool,
};

NowTime.defaultProps = {
  showTimeStrip: true,
};

export default NowTime;
