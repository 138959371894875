import React from 'react';
import SingleResource from './SingleResourceContainer';

const Resources = (props) => {
  const { 
    scroll, 
    resourcesAmount,
  } = props;


  const renderResources = () => {
    const days = [];

    for (let i = 0; i < resourcesAmount; i++) {
      days.push(
        <SingleResource number={i} key={ `resource_index_${ i }` } />        
      );
    }
    return days;
  };

  return (
    <div className="schedule-resources">
      <div className="schedule-resources-inner" style={ { left: `-${scroll}px` } }>
        { renderResources() }
      </div>
    </div>
  );
};

export default Resources;
