import { connect } from 'react-redux';
import {clearAuthValidationErrors, resetPasswordRequest} from '../../../redux/auth/actions';
import RestorePassword from './RestorePassword';

const mapStateToProps = (state) => ({
  forgotPasswordError: state.authReducer.forgotPasswordError,
});

const mapDispatchToProps = {
  resetPasswordRequest,
  clearAuthValidationErrors,
};

export default connect(mapStateToProps, mapDispatchToProps)(RestorePassword);