import React, {useState, useEffect} from 'react';
import Modal from '../../common/Modal';
import Button from '../../common/FormFields/Button';
import CustomSelect from '../../common/FormFields/Select';
import * as dateFns from 'date-fns';
import { useTranslation } from 'react-i18next';

const CancelBooking = (props) => {
  const {
    bloodbankId,
    bloodbankName,
    selectedTimeslot,
    toggleBookingCancelModal,
    bookingCancelModalIsOpen,
    cancelAppointmentBooking,
  } = props;
  const { t } = useTranslation();

  const [currentAppointments, setCurrentAppointments] = useState([]);
  const [chosenAppointment, setChosenAppointment] = useState(currentAppointments[0]);

  useEffect(() => {
    setCurrentAppointments(selectedTimeslot?.appointments
    .filter((item) => item.status === 'confirmed')
    .map((item) => {
      return {
        label: `${dateFns.format(new Date(item.startDate), 'HH:mm')} - ${dateFns.format(new Date(item.endDate), 'HH:mm')}`,
        value: item,
      }
    }) || []);
  }, [selectedTimeslot]);

  useEffect(() => {
    if (currentAppointments.length === 1) {
      setChosenAppointment(currentAppointments[0]);
    }
  }, [currentAppointments]);

  useEffect(() => {
    if (!bookingCancelModalIsOpen) {
      setChosenAppointment(null);
    }
  }, [bookingCancelModalIsOpen]);

  const handleModalClose = () => {
    toggleBookingCancelModal();
  };

  const handleSubmit = () => {
    cancelAppointmentBooking(
      bloodbankId,
      chosenAppointment.value?.reservation?.reservationId, 
      chosenAppointment.value?.reservation?.userId,
      chosenAppointment.value?.reservation?.startDate,
      bloodbankName,
    );
    toggleBookingCancelModal();
  };

  return (
    <>
      <Modal isModalOpen={ bookingCancelModalIsOpen } handleModalClose={ handleModalClose } { ...props }>
        <div className="modal-header">
          <h2 className="modal-title">
            {t('configuration.settings.cancelBookingModal.header')}
          </h2>
          <button className="modal-close" onClick={ handleModalClose }>
            <span className="icon-close" />
          </button>
        </div>
        <div className="modal-body modal-content">
          <div className="form-group modal-msg">
            {t('configuration.settings.cancelBookingModal.body')}
          </div>
          <div className='form-group'>
              <CustomSelect
                label={t('configuration.settings.cancelBookingModal.selectAppointmentTime')}
                data={ currentAppointments }
                value={ chosenAppointment }
                onChange={ setChosenAppointment }
                isSearchable={true}
              />
          </div>
        </div>
        <div className="modal-footer">
          <div className="inputs-group">
            <div className="inputs-group-item">
              <Button type="button" text={t('common.cancel')} onClick={ handleModalClose } />
            </div>
            <div className="inputs-group-item">
              <Button btnClassName="btn-primary" type="button" text={t('common.confirm')} onClick={ handleSubmit } />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CancelBooking;