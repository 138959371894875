import React, {useEffect, useState} from 'react';

import Modal from '../../common/Modal';
import Button from '../../common/FormFields/Button';
import Checkbox from '../../common/FormFields/Checkbox';
import CustomSelect from '../../common/FormFields/Select';
import Tags from '../../common/Tags';
import Datepicker from '../../common/FormFields/Datepicker';
import CustomScrollbar from '../../common/CustomScrollbar';
import DiscardSettingsModal from '../../MainModals/DiscardSettingsModal';
import TimeslotsConflictModal from './TimeslotsConflictModal';
import NoResourcesModal from './NoResourcesModal';
import CancelUpcomingAppointmentsModal from './CancelUpcomingAppointmentsModal';

import {
  ABO,
  Rhd,
  APPOINTMENT_TYPES_PLACEHOLDER,
  ALL_BLOOD_TYPES,
  BLOOD_TITER
} from './constants';

import {
  bloodTypesToTags,
  formatMilliseconds,
  timestampToWorkingTime,
  workingTimeToTimestamp,
  getStartOfWorkDay,
  getEndOfWorkDay,
  getStartOfAllDay,
  filtersToTags,
} from '../../../services/helpers';
import { 
  makeTextRules
} from './helpers'
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';

const TimeslotSettings = (props) => {
  const {
    handleRecurrenceSettingsOpen,
    handleExceptionsSettingsOpen,
    setTimeslotRequest,
    toggleSettings,
    settingsModalIsOpen,
    selectedTimeslot,
    selectedResourceId,
    selectedTime,
    currentDate,
    calendar,
    errors: { startTimeError, endTimeError, bloodTypeError, differenceTimeError, durationTimeError, backendError } = {},
    recurrenceRule,
    ruleDescription,
    deleteRecurrenceRule,
    availableTimeSlots,
    appointmentTypes,
    calendarGet,
    currentBloodbank,
    exceptionDates,
    exceptionRules,
    exceptionHoursOnDates,
    deleteAllExceptions,
    setStartDate,
    setEndDate,
    getAppointmentsAmount,
    appointmentsAmount
  } = props;
  const { t } = useTranslation();

  const appointmentTypesTranslation = (appointmentTypes) => appointmentTypes.map(appointmentType => {
    return { ...appointmentType, value: appointmentType.appointmentTypeId, label: t(`common.appointmentType.${appointmentType.name}`) }
  });

  const titerTranslation = (titers) => titers.map(titer => {
    return { value: titer, label: t(`configuration.settings.titer.${titer}`) }
  });

  const [allTiters, setAllTiters] = useState(titerTranslation(BLOOD_TITER));
  const [selectedTiter, setSelectedTiter] = useState(allTiters[2]);
  const [allBloodTitersIsChecked, setAllBloodTitersIsChecked] = useState(false);
  const [selectedBloodTiters, setSelectedBloodTiters] = useState([]);
  const [resourceId, setResourceId] = useState('');
  const [timeslotId, setTimeslotId] = useState('');
  const [selectedAppointmentType, setSelectedAppointmentType] = useState(APPOINTMENT_TYPES_PLACEHOLDER[0]);
  const [selectedBloodTypes, setSelectedBloodTypes] = useState([]);
  const [selectedRhesus, setSelectedRhesus] = useState(Rhd[0]);
  const [selectedBloodGroup, setSelectedBloodGroup] = useState(ABO[0]);
  const [timeFrom, setTimeFrom] = useState(availableTimeSlots[0]);
  const [timeTo, setTimeTo] = useState(availableTimeSlots[0]);
  const [allDayIsChecked, setAllDayIsChecked] = useState(false);
  const [bloodTypeMessage, setBloodTypeMessage] = useState(bloodTypeError);
  const [discardModalIsVisible, setDiscardModalIsVisible] = useState(false);
  const [conflictsModalIsVisible, setConflictsModalIsVisible] = useState(false);
  const [conflictResourceIndex, setConflictResourceIndex] = useState(0);
  const [noResourcesModalIsVisible, setNoResourcesModalIsVisible] = useState(false);
  const [cancelAppointmentsModalIsVisible, setCancelAppointmentsModalIsVisible] = useState(false);
  const [currentRule, setCurrentRule] = useState(ruleDescription);
  const [datepickerDate, setDatepickerDate] = useState(currentDate);
  const [appointmentTypesData, setAppointmentTypesData] = useState(appointmentTypes.length ? appointmentTypes : APPOINTMENT_TYPES_PLACEHOLDER);
  const [allBloodTypesIsChecked, setAllBloodTypesIsChecked] = useState(false);
  const [currentExceptionDates, setCurrentExceptionDates] = useState(exceptionDates);
  const [currentExceptionRules, setCurrentExceptionRules] = useState(exceptionRules);
  const [currentExceptionHoursOnDates, setCurrentExceptionHoursOnDates] = useState(exceptionHoursOnDates);
  const [isExceptions, setIsExceptions] = useState(exceptionDates.length !== 0 || exceptionRules.length !== 0 || exceptionHoursOnDates.length !== 0);
  const [exceptionalRules, setExceptionalRules] = useState(makeTextRules(exceptionDates, exceptionRules, exceptionHoursOnDates, selectedTimeslot?.startDate));
  const [isTypeSelectDisabled, setIsTypeSelectDisabled] = useState(false);
  const [pendingAppointmentsAmount, setPendingAppointmentsAmount] = useState(0);
  const [reservedAppointmentsAmount, setReservedAppointmentsAmount] = useState(0);
  const [archivedAppointmentsAmount, setArchivedAppointmentsAmount] = useState(0);

  useEffect(() => {
    const startDate = allDayIsChecked
      ? {
          timestamp: getStartOfWorkDay(datepickerDate),
          slot: availableTimeSlots[0],
        }
      : {
          timestamp: workingTimeToTimestamp(timeFrom, datepickerDate),
          slot: timeFrom,
        };
    const endDate = allDayIsChecked
      ? {
          timestamp: getEndOfWorkDay(datepickerDate),
          slot: availableTimeSlots[availableTimeSlots.length - 1],
        }
      : {
          timestamp: workingTimeToTimestamp(timeTo, datepickerDate),
          slot: timeTo,
        };
    setStartDate(startDate);
    setEndDate(endDate);
  }, [timeFrom, timeTo, allDayIsChecked]);

  useEffect(() => {
    if (timeFrom) {
      const timeToInMilliseconds =  workingTimeToTimestamp(timeFrom, datepickerDate) + selectedAppointmentType.duration;
      setTimeTo(timestampToWorkingTime(timeToInMilliseconds));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAppointmentType]);

  useEffect(() => {
    const data = appointmentTypesTranslation(appointmentTypes);
    if (data.length) {
      setAppointmentTypesData(data);
      setSelectedAppointmentType(data[0]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentTypes]);

  i18n.on("languageChanged", () => {
    setAppointmentTypesData(appointmentTypesTranslation(appointmentTypes));
    setAllTiters(titerTranslation(BLOOD_TITER));
  });

  useEffect(() => {
    setSelectedTiter(allTiters[2]);
  }, [allTiters]);

  useEffect(() => {
    setCurrentRule(ruleDescription);
  }, [ruleDescription]);

  useEffect(() => {
    setCurrentExceptionDates(exceptionDates);
    setCurrentExceptionRules(exceptionRules);
    setCurrentExceptionHoursOnDates(exceptionHoursOnDates);    
    setIsExceptions(exceptionDates.length !== 0 || exceptionRules.length !== 0 || exceptionHoursOnDates.length !== 0);

    setExceptionalRules(makeTextRules(exceptionDates, exceptionRules, exceptionHoursOnDates, selectedTimeslot?.startDate));
  }, [exceptionDates, exceptionRules, exceptionHoursOnDates]);

  useEffect(() => {
    if (selectedTimeslot) {
      const {donorType, resourceId, timeslotId, appointmentType, startDate, endDate, bloodTiter} = selectedTimeslot;
      setDatepickerDate(getStartOfAllDay(startDate))
      const startWorkDay = getStartOfWorkDay(getStartOfAllDay(startDate));
      const endWorkDay = getEndOfWorkDay(getStartOfAllDay(startDate));
      setSelectedBloodTypes(bloodTypesToTags(donorType));
      setSelectedAppointmentType(appointmentTypesData.find(item => item.appointmentTypeId === appointmentType.appointmentTypeId) || appointmentTypesData[0]);
      setTimeFrom(timestampToWorkingTime(startDate));
      setTimeTo(timestampToWorkingTime(endDate));
      setTimeslotId(timeslotId);
      setResourceId(resourceId);
      setAllDayIsChecked(Math.abs(startDate - endDate) === (endWorkDay - startWorkDay))
      setAllBloodTypesIsChecked(selectedBloodTypes.length === 8)
      setIsExceptions(exceptionDates.length !== 0 || exceptionRules.length !== 0 || exceptionHoursOnDates.length !== 0);
      setExceptionalRules(makeTextRules(exceptionDates, exceptionRules, exceptionHoursOnDates, selectedTimeslot?.startDate));
      getAppointmentsAmount();
      bloodTiter ? setSelectedBloodTiters(filtersToTags(bloodTiter, t, 'configuration.settings.titer.')) : setSelectedBloodTiters([]);
      setAllBloodTitersIsChecked(selectedBloodTiters.length === 3);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTimeslot, settingsModalIsOpen]);

  
  useEffect(() => {
    if (appointmentsAmount) {
      const pendingAppointments = appointmentsAmount.pending;
      const reservedAppointments = appointmentsAmount.reserved;
      const archivedAppointments = appointmentsAmount.archived;
  
      if (reservedAppointments !== 0 || archivedAppointments !== 0) {
        setIsTypeSelectDisabled(true);
      }
      setPendingAppointmentsAmount(pendingAppointments);
      setReservedAppointmentsAmount(reservedAppointments);
      setArchivedAppointmentsAmount(archivedAppointments);
    }
  }, [appointmentsAmount]);


  useEffect(() => {
    if (selectedResourceId) {
      setDatepickerDate(getStartOfAllDay(currentDate))
      setSelectedAppointmentType(appointmentTypesData[0]);
      const newTimeFrom = availableTimeSlots.find(item => item.value === selectedTime) || availableTimeSlots[0];
      setTimeFrom(newTimeFrom);
      const newTimeToInMilliseconds = workingTimeToTimestamp(newTimeFrom, currentDate) + selectedAppointmentType.duration;
      setTimeTo(timestampToWorkingTime(newTimeToInMilliseconds));
      setResourceId(selectedResourceId);
      setSelectedBloodTypes([]);
      setTimeslotId('');
      setAllDayIsChecked(false);
      setAllBloodTypesIsChecked(false);
      setCurrentExceptionDates([]);
      setCurrentExceptionRules([]);
      setIsExceptions(false);
      setExceptionalRules([]);
      setIsTypeSelectDisabled(false);
      setPendingAppointmentsAmount(0);
      setReservedAppointmentsAmount(0);
      setArchivedAppointmentsAmount(0);

      setSelectedBloodTiters([]);
      setAllBloodTitersIsChecked(false);
      
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedResourceId, settingsModalIsOpen]);

  useEffect(() => {
    setBloodTypeMessage(bloodTypeError);
  }, [bloodTypeError]);

  useEffect(() => {
    if (backendError) {
      if (backendError === 'timeslot_conflict') {
        const resourceIndex = calendar
          .findIndex(resource => resource.resourceId === resourceId);
        setConflictResourceIndex(resourceIndex + 1);
        setConflictsModalIsVisible(true);
        calendarGet(currentDate.getTime(), currentBloodbank, true)
      } else if (backendError === 'resources_limit_reached') {
        setNoResourcesModalIsVisible(true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backendError]);

  useEffect(() => {
    if (selectedBloodTypes.length < 8) {
      setAllBloodTypesIsChecked(false);
    } else {
      setAllBloodTypesIsChecked(true);
    }
  }, [selectedBloodTypes]);

  const handleAllBloodTypesChange = () => {
    if (!allBloodTypesIsChecked) {
      setSelectedBloodTypes(
        ALL_BLOOD_TYPES.map((item) => {return {
          label: `${item.bloodType.toUpperCase()}${item.rhesus === 'minus' ? '-' : '+'}`,
          value: item
        }})
      )
    } else {
      setSelectedBloodTypes([])
    }
  }

  useEffect(() => {
    if (selectedBloodTiters.length < 3) {
      setAllBloodTitersIsChecked(false);
    } else {
      setAllBloodTitersIsChecked(true);
    }
  }, [selectedBloodTiters]);

  const handleAllBloodTitersChange = () => {
    if (!allBloodTitersIsChecked) {
      setSelectedBloodTiters(allTiters);
    } else {
      setSelectedBloodTiters([]);
    }
  }

  const addBloodTiterTag = () => {
    setSelectedBloodTiters([
      ...selectedBloodTiters,
      ...(!selectedBloodTiters.find(item => item.label === selectedTiter.label) ? [selectedTiter] : [])
    ]);
  };

  const openDiscardModal = () => {
    setDiscardModalIsVisible(true);
  };

  const cancelDiscard = () => {
    setDiscardModalIsVisible(false);
  };

  const submitDiscard = () => {
    setDiscardModalIsVisible(false);
    toggleSettings();
  };

  const closeNoResourcesModal = () => {
    setNoResourcesModalIsVisible(false);
  };

  const submitConflictsModal = () => {
    setConflictsModalIsVisible(false);
    const data = getTimeslotRequestBody(true);
    setTimeslotRequest(data, selectedAppointmentType.duration);
  };

  const cancelConflictsModal = () => {
    setConflictsModalIsVisible(false);
  };

  const isNewRegistration = selectedAppointmentType.donorStatus === 'new';

  const addBloodTypeTag = () => {
    setBloodTypeMessage('');
    const newTag = {
      label: `${selectedBloodGroup.label}${selectedRhesus.label}`,
      value: {rhesus: selectedRhesus.value, bloodType: selectedBloodGroup.value}
    };
    setSelectedBloodTypes([
      ...selectedBloodTypes,
      ...(!selectedBloodTypes.find(item => item.label === newTag.label) ? [newTag] : [])
    ]);
  };

  const getTimeslotLimits = () => {
    const startDate = allDayIsChecked
      ? getStartOfWorkDay(datepickerDate)
      : workingTimeToTimestamp(timeFrom, datepickerDate);
    const endDate = allDayIsChecked
      ? getEndOfWorkDay(datepickerDate)
      : workingTimeToTimestamp(timeTo, datepickerDate);
    return {startDate, endDate};
  };

  const getTimeslotRequestBody = (checkForAvailableResources) => {
    const { startDate, endDate } = getTimeslotLimits();
    const bloodTypes = isNewRegistration ? ALL_BLOOD_TYPES : selectedBloodTypes.map(item => item.value);
    const bloodTiter = isNewRegistration ? BLOOD_TITER : selectedBloodTiters.map(item => item.value);
    
    return {
      ...(!checkForAvailableResources && {resourceId}),
      donorType: bloodTypes,
      bloodTiter,
      appointmentTypeId: selectedAppointmentType.appointmentTypeId,
      startDate,
      endDate,
      ...(timeslotId && {timeslotId}),
      ...(recurrenceRule && {rule: recurrenceRule}),
      ...(currentRule && {ruleDescription: currentRule}),
      exceptionDates: currentExceptionDates,
      exceptionRules: currentExceptionRules,
      exceptionHoursOnDates: currentExceptionHoursOnDates,
    };
  };

  const handleSave = () => {
    const data = getTimeslotRequestBody();
    setTimeslotRequest(data, selectedAppointmentType.duration);
  };

  return (
    <>
      <Modal additionalClassName="right" isModalOpen={ settingsModalIsOpen } handleModalClose={ toggleSettings } { ...props }>
        <div className="modal-header">
          <h2 className="modal-title">{t('configuration.settings.header')}</h2>
          <button className="modal-close" onClick={ toggleSettings }>
            <span className="icon-close" />
          </button>
        </div>
        <CustomScrollbar className="modal-body modal-flex-wrapper">
          <div>
            <section className="modal-content">
              <div className="form-group inputs-group">
                <div className="inputs-group-item">{t('common.appointmentType.header')}</div>
                <div className="inputs-group-item">
                  <CustomSelect
                    data={ appointmentTypesData }
                    additionalClassName="xxl"
                    value={ selectedAppointmentType }
                    onChange={ setSelectedAppointmentType }
                    disabled={isTypeSelectDisabled}
                  />
                </div>
              </div>
              <div className="form-group inputs-group">
                <div className="inputs-group-item">{t('common.donorStatus.header')}</div>
                <div className="inputs-group-item bold">{ t(`common.donorStatus.${selectedAppointmentType.donorStatus}`) || '' }</div>
              </div>
              {/*TODO new field*/}
              <div className="form-group inputs-group">
                <div className="inputs-group-item">{t('common.donorType.header')}</div>
                <div className="inputs-group-item bold">{ t(`common.donorType.${selectedAppointmentType.donorType}`) }</div>
              </div>
              <div className="form-group inputs-group">
                <div className="inputs-group-item">{t('configuration.settings.appointmentDuration')}</div>
                <div className="inputs-group-item bold">{ formatMilliseconds(selectedAppointmentType.duration) || 0 }</div>
              </div>
              <div className="form-group">
                <div className="inputs-group">
                  <div className="inputs-group-item">ABO</div>
                  <div className="inputs-group-item">
                    <CustomSelect
                      data={ ABO }
                      value={ selectedBloodGroup }
                      onChange={ setSelectedBloodGroup }
                      placeholder="Select"
                      additionalClassName="xs"
                      isDisabled={isNewRegistration}
                    />
                  </div>
                  <div className="inputs-group-item">Rh(D)</div>
                  <div className="inputs-group-item">
                    <CustomSelect
                      data={ Rhd }
                      value={ selectedRhesus }
                      onChange={ setSelectedRhesus }
                      placeholder="Select"
                      additionalClassName="xs"
                      isDisabled={isNewRegistration}
                    />
                  </div>
                  <div className="inputs-group-item d-flex flex-grow-1 justify-content-end">
                    <button className="btn-flat" onClick={addBloodTypeTag} disabled={isNewRegistration}>+{t('configuration.settings.add')}</button>
                  </div>
                </div>
                {bloodTypeMessage && <div className="form-control has-error">
                  <div className="form-error">{ bloodTypeMessage }</div>
                </div>}
                <div className='inputs-group form-tags'>
                  <Checkbox
                    label={t('configuration.settings.allBloodTypes')}
                    checked={allBloodTypesIsChecked}
                    onChangeValue={setAllBloodTypesIsChecked}
                    disabled={isNewRegistration}
                    checkboxHandler={handleAllBloodTypesChange}
                  />
                </div>
                <div className="form-tags">
                  {
                    selectedBloodTypes.length ?
                      <Tags 
                        additionalClass="inline" 
                        data={selectedBloodTypes} 
                        onDeleteTag={ setSelectedBloodTypes }
                      /> : 
                      <></>
                  }
                </div>
              </div>
              <div className="form-group">
                <div className="inputs-group">
                  <div className="inputs-group-item">{t('configuration.settings.titer.title')}</div>
                  <div className="inputs-group-item">
                    <CustomSelect
                      data={ allTiters }
                      value={ selectedTiter }
                      onChange={ setSelectedTiter }
                      placeholder="Select"
                      additionalClassName="lg"
                      isDisabled={ isNewRegistration }
                    />
                  </div>
                  <div className="inputs-group-item d-flex flex-grow-1 justify-content-end">
                    <button 
                      className="btn-flat"
                      onClick={ addBloodTiterTag } 
                      disabled={ isNewRegistration }
                    >
                      +{t('configuration.settings.add')}
                    </button>
                  </div>
                </div>
                <div className='inputs-group form-tags'>
                  <Checkbox
                    label={t('configuration.settings.titer.allVariants')}
                    checked={ allBloodTitersIsChecked }
                    onChangeValue={ setAllBloodTitersIsChecked }
                    disabled={ isNewRegistration }
                    checkboxHandler={ handleAllBloodTitersChange }
                  />
                </div>
                <div className="form-tags">
                  {
                    selectedBloodTiters.length ?
                      <Tags 
                        additionalClass="inline" 
                        data={ selectedBloodTiters } 
                        onDeleteTag={ setSelectedBloodTiters }
                      /> : 
                      <></>
                  }
                </div>
              </div>
              <div className="form-group inputs-group">
                <div className="inputs-group-item">{t('configuration.settings.startDate')}</div>
                <div className="inputs-group-item">
                  <Datepicker date={datepickerDate} onDateChange={setDatepickerDate} />
                </div>
              </div>
              <div className="form-group inputs-group">
                <div className="inputs-group-item">{t('configuration.settings.from')}</div>
                <div className="inputs-group-item">
                  <CustomSelect
                    value={ timeFrom }
                    onChange={ setTimeFrom }
                    data={ availableTimeSlots }
                    isDisabled={ allDayIsChecked }
                    isSearchable={true}
                    additionalClassName="sm"
                    hasError={ !!startTimeError }
                    // errorMessage={ startTimeError }
                  />
                </div>
                <div className="inputs-group-item">{t('configuration.settings.to')}</div>
                <div className="inputs-group-item">
                  <CustomSelect
                    value={ timeTo }
                    onChange={ setTimeTo }
                    data={ availableTimeSlots }
                    isDisabled={ allDayIsChecked }
                    isSearchable={true}
                    additionalClassName="sm"
                    hasError={ !!endTimeError }
                    // errorMessage={ endTimeError }
                  />
                </div>
                <div className="inputs-group-item">
                  <Checkbox 
                    label={t('configuration.settings.allDay')} 
                    checked={ allDayIsChecked } 
                    onChangeValue={ setAllDayIsChecked } />
                </div>
                <div className="form-validation">{differenceTimeError || durationTimeError}</div>
              </div>
            </section>
            <section className="modal-content light">
              <div className="timeslot-details">
                <div className="timeslot-details-header">
                  <span className="timeslot-details-title">{t('configuration.settings.recurring')}</span>
                  <div className="timeslot-details-controls">
                    <div className="timeslot-details-control">
                      <button className="btn-flat" onClick={ handleRecurrenceSettingsOpen }>
                        {currentRule ? t('configuration.settings.edit') : `+${t('configuration.settings.add')}`}
                      </button>
                    </div>
                    {currentRule &&
                      <div className="timeslot-details-control">
                        <button className="btn-flat" onClick={deleteRecurrenceRule}>{t('configuration.settings.remove')}</button>
                      </div>
                    }
                  </div>
                </div>
                {currentRule && <div className="timeslot-details-summary">{currentRule}</div>}
              </div>
              <div className="timeslot-details">
                <div className="timeslot-details-header">
                  <span className="timeslot-details-title">{t('configuration.settings.exceptions')}</span>
                  <div className="timeslot-details-controls">
                    <div className="timeslot-details-control">
                      <button className="btn-flat" onClick={ handleExceptionsSettingsOpen }>
                        {isExceptions ? t('configuration.settings.edit') : `+${t('configuration.settings.add')}`}
                      </button>
                    </div>
                      {isExceptions &&
                        <div className="timeslot-details-control">
                          <button className="btn-flat" onClick={deleteAllExceptions}>{t('configuration.settings.remove')}</button>
                        </div>
                      }
                  </div>
                </div>
                {
                  !!exceptionalRules.length && exceptionalRules.map((item) => (
                    <div key={item} className="timeslot-details-summary">{item}</div>))
                }
              </div>
            </section>
          </div>
          <div>
            <section className="modal-content light">
              <div className="inputs-group">
                <div className="inputs-group-item timeslot-details-amount">
                  {`${t('configuration.settings.counter.pending')}: ${pendingAppointmentsAmount}`}
                </div>
                <div className="inputs-group-item timeslot-details-amount">
                  {`${t('configuration.settings.counter.reserved')}: ${reservedAppointmentsAmount}`}
                </div>
                <div className="inputs-group-item timeslot-details-amount">
                  {`${t('configuration.settings.counter.archived')}: ${archivedAppointmentsAmount}`}
                </div>
              </div>
            </section>
          </div>
        </CustomScrollbar>

        <div className="modal-footer">
          <div className="inputs-group">
            <div className="inputs-group-item">
              <Button type="button" text={t('common.cancel')} onClick={openDiscardModal} />
            </div>
            <div className="inputs-group-item">
              <Button
                btnClassName="btn-primary"
                type="button"
                text={t('common.save')}
                onClick={ handleSave }
                disabled={(!selectedBloodTypes.length || !selectedBloodTiters.length) && !isNewRegistration}/>
            </div>
          </div>
        </div>
      </Modal>
      <DiscardSettingsModal
        isModalOpen={discardModalIsVisible}
        handleSubmit={submitDiscard}
        handleClose={cancelDiscard}
      />
      <TimeslotsConflictModal
        isModalOpen={conflictsModalIsVisible}
        handleSubmit={submitConflictsModal}
        handleClose={cancelConflictsModal}
        resourceIndex={conflictResourceIndex}
      />
      <NoResourcesModal
        isModalOpen={noResourcesModalIsVisible}
        handleClose={closeNoResourcesModal}
      />
      <CancelUpcomingAppointmentsModal
        isModalOpen={cancelAppointmentsModalIsVisible}
        handleClose={() => setCancelAppointmentsModalIsVisible(false)}
      />
    </>
  );
};

export default TimeslotSettings;
