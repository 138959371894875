export const MESSAGES_HEADINGS = {
    MESSAGE_ID: 'message_id',
    RECEIVER_ID: 'receiver_id',
    DATE: 'date',
    TITLE: 'title',
    MESSAGE: 'message',
    SIGNATURE: 'signature',
    CANDIDATE: 'candidate',
  };
  
export const MESSAGES_SETUP_ADMIN = [
  {
    title: MESSAGES_HEADINGS.MESSAGE_ID,
    isSortable: true,
    isHighlighted: true,
    isTextOwerflowHidden: false,
  },
  {
    title: MESSAGES_HEADINGS.DATE,
    isSortable: true,
    isHighlighted: false,
    isTextOwerflowHidden: false,
  },
  {
    title: MESSAGES_HEADINGS.TITLE,
    isSortable: true,
    isHighlighted: false,
    isTextOwerflowHidden: false,
  },
  {
    title: MESSAGES_HEADINGS.MESSAGE,
    isSortable: true,
    isHighlighted: false,
    isTextOwerflowHidden: true,
  },
  {
    title: MESSAGES_HEADINGS.SIGNATURE,
    isSortable: true,
    isHighlighted: false,
    isTextOwerflowHidden: false,
  },
  {
    title: MESSAGES_HEADINGS.CANDIDATE,
    isSortable: true,
    isHighlighted: false,
    isTextOwerflowHidden: false,
  },
];

export const MESSAGES_SETUP = [
  {
    title: MESSAGES_HEADINGS.MESSAGE_ID,
    isSortable: true,
    isHighlighted: true,
    isTextOwerflowHidden: false,
  },
  {
    title: MESSAGES_HEADINGS.RECEIVER_ID,
    isSortable: true,
    isHighlighted: true,
    isTextOwerflowHidden: false,
  },
  {
    title: MESSAGES_HEADINGS.DATE,
    isSortable: true,
    isHighlighted: false,
    isTextOwerflowHidden: false,
  },
  {
    title: MESSAGES_HEADINGS.TITLE,
    isSortable: true,
    isHighlighted: false,
    isTextOwerflowHidden: false,
  },
  {
    title: MESSAGES_HEADINGS.MESSAGE,
    isSortable: true,
    isHighlighted: false,
    isTextOwerflowHidden: true,
  },
];

export const MSG_TITLE_MAX_LENGTH = 100;
export const MSG_BODY_MAX_LENGTH = 900;

export const ALL_BLOOD_TYPES = [
  {rhesus: 'minus', bloodType: 'o'},
  {rhesus: 'plus', bloodType: 'o'},
  {rhesus: 'minus', bloodType: 'a'},
  {rhesus: 'plus', bloodType: 'a'},
  {rhesus: 'minus', bloodType: 'b'},
  {rhesus: 'plus', bloodType: 'b'},
  {rhesus: 'minus', bloodType: 'ab'},
  {rhesus: 'plus', bloodType: 'ab'},
];

export const ABO = [
  { value: 'o', label: 'O' },
  { value: 'a', label: 'A' },
  { value: 'b', label: 'B' },
  { value: 'ab', label: 'AB' },
];

export const Rhd = [
  { value: 'minus', label: '-' },
  { value: 'plus', label: '+' },
];

export const BLOOD_TITER = [
  'low', 'high', 'unknown'
];

export const DONOR_STATUS = [
  'new', 'approved', 'established'
];

export const APP_USER_STATUS = [
  'yes', 'no'
];

export const SMS_STATUS = [
  'true', 'false'
];