import { handleActions } from 'redux-actions';
import {
  createTimeslotSuccess,
  createTimeslotFailure,
  toggleTimeslotSettings,
  setSelectedTimeslot,
  setSelectedResource,
  clearValidationErrors,
  hideMenus,
  setMenuIsOpen,
  setRule,
  deleteRule,
  toggleDeleteModal,
  setReservationsExists,
  fetchTimeslots,
  setNewExceptionRule,
  setNewExceptionDate,
  setNewExceptionHoursOnDate,
  deleteExceptions,
  toggleAppointmentModal,
  toggleBookingModal,
  toggleBookingCancelModal,
  setAppointmentsAmount
} from './actions';
import {
  getRuleDescription,
  checkRecurrenceRule,
} from "../../services/helpers";

const initialState = {
  fetching: false,
  errors: {
    startTimeError: '',
    endTimeError: '',
    bloodTypeError: '',
    differenceTimeError: '',
    durationTimeError: '',
    backendError: '',
  },
  settingsModalIsOpen: false,
  selectedTimeslot: null,
  selectedResourceId: null,
  selectedTime: null,
  timeslotMenuIsOpened: false,
  recurrenceRule: null,
  ruleDescription: '',
  deleteTimeslotModalIsOpen: false,
  bookedReservations: false,
  isTimeslotsFetching: false,
  isTimeslotSuccessToastShown: false,
  isTimeslotErrorToastShown: false,
  exceptionRules: [],
  exceptionDates: [],
  exceptionHoursOnDates: [],
  deleteAppointmentModalIsOpen: false,
  bookAppointmentModalIsOpen: false,
  bookingCancelModalIsOpen: false,
  appointmentsAmount: null,
};

const timeslotsReducer = handleActions({
  [createTimeslotSuccess]: (state) => ({
    ...state,
    errors: {
      startTimeError: '',
      endTimeError: '',
      bloodTypeError: '',
      differenceTimeError: '',
      durationTimeError: '',
    },
    isTimeslotsFetching: false,
    isTimeslotSuccessToastShown: true,
    isTimeslotErrorToastShown: false,
    recurrenceRule: null,
    ruleDescription: ''
  }),
  [createTimeslotFailure]: (state, action) => ({
    ...state,
    errors: action.payload,
    isTimeslotErrorToastShown: true,
    isTimeslotSuccessToastShown: false,
    isTimeslotsFetching: false
  }),
  [toggleTimeslotSettings]: (state) => ({
    ...state,
    settingsModalIsOpen: !state.settingsModalIsOpen,
    errors: {
      startTimeError: '',
      endTimeError: '',
      bloodTypeError: '',
      differenceTimeError: '',
      durationTimeError: '',
      backendError: '',
    },
  }),
  [setSelectedResource]: (state, action) => ({
    ...state,
    selectedResourceId: action.payload.resourceId,
    selectedTime: action.payload.selectedTime,
    selectedTimeslot: null,
    recurrenceRule: null,
    ruleDescription: '',
    appointmentsAmount: null
  }),
  [setSelectedTimeslot]: (state, action) => ({
    ...state,
    selectedTimeslot: action.payload,
    recurrenceRule: checkRecurrenceRule(action.payload.initialSettings) ? action.payload.initialSettings : null,
    exceptionRules: action.payload.initialExceptionRules || [],
    exceptionDates: action.payload.initialExceptionDates || [],
    exceptionHoursOnDates: action.payload.initialExceptionHoursOnDates || [],
    ruleDescription: action.payload.ruleDescription,
    selectedResourceId: null,
    selectedTime: null,
  }),
  [clearValidationErrors]: (state) => ({
    ...state,
    errors: {
      startTimeError: '',
      endTimeError: '',
      bloodTypeError: '',
      differenceTimeError: '',
      durationTimeError: '',
      backendError: '',
    },
  }),
  [hideMenus]: (state) => ({
    ...state,
    timeslotMenuIsOpened: false,
  }),
  [setMenuIsOpen]: (state) => ({
    ...state,
    timeslotMenuIsOpened: true,
  }),
  [setRule]: (state, action) => ({
    ...state,
    recurrenceRule: action.payload,
    ruleDescription: getRuleDescription(action.payload),
  }),
  [deleteRule]: (state) => ({
    ...state,
    recurrenceRule: null,
    ruleDescription: '',
  }),
  [toggleDeleteModal]: (state) => ({
    ...state,
    deleteTimeslotModalIsOpen: !state.deleteTimeslotModalIsOpen,
  }),
  [setReservationsExists]: (state, action) => ({
    ...state,
    isTimeslotsFetching: false,
    bookedReservations: action.payload.bookedReservations,
  }),
  [fetchTimeslots]: (state) => ({
    ...state,
    isTimeslotsFetching: true
  }),
  [setNewExceptionRule]: (state, action) => ({
    ...state,
    exceptionRules: action.payload,
  }),
  [setNewExceptionDate]: (state, action) => ({
    ...state,
    exceptionDates: action.payload,
  }),
  [setNewExceptionHoursOnDate]: (state, action) => ({
    ...state,
    exceptionHoursOnDates: action.payload,
  }),
  [deleteExceptions]: (state) => ({
    ...state,
    exceptionRules: [],
    exceptionDates: [],
    exceptionHoursOnDates: [],
  }),
  [toggleAppointmentModal]: (state) => ({
    ...state,
    deleteAppointmentModalIsOpen: !state.deleteAppointmentModalIsOpen,
  }),
  [toggleBookingModal]: (state) => ({
    ...state,
    bookAppointmentModalIsOpen: !state.bookAppointmentModalIsOpen,
  }),
  [toggleBookingCancelModal]: (state) => ({
    ...state,
    bookingCancelModalIsOpen: !state.bookingCancelModalIsOpen,
  }),
  [setAppointmentsAmount]: (state, action) => ({
    ...state,
    appointmentsAmount: action.payload,
  }),
}, initialState);

export default timeslotsReducer;
