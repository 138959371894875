export const DONOR_LIST_PAGINATION = {
    pageSize: 10,
    pageSizes: [10, 20],
    allPagesSize: true,
    startPage: 1,
};

export const PUSH_MESSAGES_PAGINATION = {
    pageSize: 10,
    pageSizes: [10, 20, 50],
    allPagesSize: true,
    startPage: 1,
};