import { handleActions } from 'redux-actions';
import {
  getAppointmentTypesRequest,
  getAppointmentTypesSuccess,
  getAppointmentTypesFailure,
} from './actions';

const initialState = {
  fetching: false,
  appointmentTypes: [],
  error: null,
};

const appointmentTypesReducer = handleActions({
  [getAppointmentTypesRequest]: (state) => ({
    ...state,
    error: null,
  }),
  [getAppointmentTypesSuccess]: (state, action) => ({
    ...state,
    appointmentTypes: action.payload,
  }),
  [getAppointmentTypesFailure]: (state, action) => ({
    ...state,
    appointmentTypes: [],
    error: action.payload,
  }),
}, initialState);

export default appointmentTypesReducer;